import React, { FC } from 'react';
import styled from 'styled-components';

import IconAvatar from '../../Avatar';

import CreditCardIcon from '../../Icons/CreditCardIcon';

const StyledAvatar = styled(IconAvatar)`
  background-color: rgba(5, 198, 64, 0.2);
`;

const color = '#2CCC71';

const CreditCardAvatar: React.FC<{}> = () => {
  return (
    <StyledAvatar>
      <CreditCardIcon iconColor={color} />
    </StyledAvatar>
  );
};

export default CreditCardAvatar;
