import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import './App.css';
import { appTheme } from './theme/theme';
import { PrivateRoute } from './shared/Components/PrivateRoute';
import { Role } from './models/role';
import {
  AdminDashboardPage,
  AthleteProfile,
  AthleteProfileFormView,
  BasketballPage,
  HomePage,
  LoginPage,
  NewsFeed,
  NotFoundPage,
  ScoutDashboardPage,
  ScoutProfile,
  UnauthorisedPage,
} from './views';

const App: FC<{}> = () => {
  return (
    <div>
      <StylesProvider injectFirst>
        <ThemeProvider theme={appTheme}>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path="/athlete-profile-form">
              <AthleteProfileFormView />
            </Route>
            <Route path="/athlete-profile/:id">
              <AthleteProfile />
            </Route>
            <Route path="/scout-profile">
              <ScoutProfile />
            </Route>
            <Route path="/basketball">
              <BasketballPage />
            </Route>
            <PrivateRoute
              path="/news-feed"
              roles={[Role.admin, Role.scout, Role.athlete]}
              Component={NewsFeed}
            />
            <PrivateRoute
              path="/dashboard/scout"
              roles={[Role.admin, Role.scout]}
              Component={ScoutDashboardPage}
            />
            <PrivateRoute
              path="/dashboard/admin"
              roles={[Role.admin]}
              Component={AdminDashboardPage}
            />
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/unauthorised">
              <UnauthorisedPage />
            </Route>
            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </ThemeProvider>
      </StylesProvider>
    </div>
  );
};

export default App;
