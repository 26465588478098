import React, { ElementType } from 'react';

import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { useStyles } from './styles';

interface INavigationProps {
  title: string;
  link: string;
  className?: string;
  icon: ElementType;
}

const NavItem: React.FC<INavigationProps> = (props) => {
  const { title, link, icon: Icon, className, ...rest } = props;
  const classes = useStyles();

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        to={link}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.itemTitle}>{title}</span>
      </Button>
    </ListItem>
  );
};

export default NavItem;
