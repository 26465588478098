import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Box, { BoxProps } from '@material-ui/core/Box';

import { ReactComponent as Logo } from '../../../assets/logo.svg';

const LogoComponent: FC<BoxProps> = (props) => {
  const { ...rest } = props;
  return (
    <Box {...rest}>
      <Link to="/">
        <Logo />
      </Link>
    </Box>
  );
};

LogoComponent.propTypes = {
  children: PropTypes.any,
};

export default LogoComponent;
