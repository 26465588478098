import React, { FC, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MenuIcon from '@material-ui/icons/Menu';

import HeaderProfile from '../Header/HeaderProfile';
import LogoComponent from '../Logo';
import Signup from '../Form/SignUp';
import ButtonComponent from '../Button';
import IconButton from '@material-ui/core/IconButton';
import MainMenu from '../MainMenu';
import { Login } from '../Dialog';
import {
  LogoSectionStyled,
  NavBarContainerStyled,
  LinksSectionStyled,
  ButtonsBoxStyled,
} from './styles';

import AuthService from '../../services/auth.service';
import { AuthenticatedState } from '../../store/types/auth';

const NavigationBar: FC<React.PropsWithChildren<{}>> = (props) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<any>(null);

  const { children } = props;

  const history = useHistory();

  const loggedIn = useSelector(
    (state: AuthenticatedState) => state.authentication.loggedIn
  );

  const openMainMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleMainMenuClose = () => {
    setOpenMenu(!openMenu);
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleLoginModalClose = () => {
    setOpenLoginModal(false);
  };

  const handleLoginClick = () => {
    history.push('/login');
  };

  useEffect(() => {
    if (loggedIn) {
      setCurrentUser(AuthService.getCurrentUser());
    } else {
      setCurrentUser(null);
    }
  }, [loggedIn]);

  return (
    <NavBarContainerStyled>
      <IconButton onClick={openMainMenu}>
        <MenuIcon />
      </IconButton>

      <Signup
        isOpen={openModal}
        handleDialogClose={handleModalClose}
        dialogTypeTitle={'Scout'}
      />

      <Login
        isOpen={openLoginModal}
        handleDialogClose={handleLoginModalClose}
      />

      <MainMenu open={openMenu} handleCloseCallBack={handleMainMenuClose} />

      <LogoSectionStyled>
        <LogoComponent />
      </LogoSectionStyled>
      <LinksSectionStyled>
        {currentUser ? (
          <>
            <HeaderProfile />
          </>
        ) : (
          <>
            <ButtonComponent
              onClick={handleLoginClick}
              variant="contained"
              color="primary"
            >
              Log In
            </ButtonComponent>
          </>
        )}
      </LinksSectionStyled>

      {children}
    </NavBarContainerStyled>
  );
};

NavigationBar.propTypes = {
  children: PropTypes.any,
};

export default NavigationBar;
