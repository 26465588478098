import React from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { TextInput, CheckboxInput } from '../Input';
import ButtonComponent from '../../Button';
import LinkComponent from '../../Link';
import { colors, fontWeight } from '../../../../theme/theme';

const titleStyles: React.CSSProperties = {
  fontSize: 24,
  fontWeight: fontWeight.normal,
  textAlign: 'center',
  marginBottom: 30,
};

const fieldSetTextStyles: React.CSSProperties = {
  fontSize: 16,
  fontWeight: 500,
  marginBottom: 10,
};

const CheckBoxLabel = () => (
  <Typography style={{ fontSize: 16 }} variant="body1">
    Accept <LinkComponent href="#">Terms of Use & Privacy Policy</LinkComponent>
  </Typography>
);

const FieldSet = (props: any) => (
  <Grid container style={{ display: 'flex', alignItems: 'center' }}>
    <Grid item xs={3}>
      <Typography style={fieldSetTextStyles}>{props.title}</Typography>
    </Grid>
    <Grid item xs={9}>
      <Divider />
    </Grid>
  </Grid>
);

const formBoxStyled: React.CSSProperties = {
  backgroundColor: colors.backgroundOne,
  paddingLeft: 110,
  paddingTop: 75,
  paddingBottom: 75,
  paddingRight: 110,
  marginBottom: 30,
};

const AthleteProfileForm: React.FC<{}> = () => {
  const [checked, setChecked] = React.useState(false);

  const handleSubmit = () => {
    console.log('I was clicked');
  };

  const handleChange = () => {
    setChecked(!checked);
    console.log('I am checked');
  };

  return (
    <Box style={formBoxStyled}>
      <Typography style={titleStyles}>
        Add your personal details to complete your profile
      </Typography>

      <FieldSet title="Personal Info" />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput
            id="country"
            type="text"
            placeholder="Country"
            fullWidth
            size="medium"
          />
          <TextInput
            id="date-of-birth"
            type="text"
            placeholder="Date of Birth (should match birth certificate)"
            fullWidth
            size="medium"
          />
          <TextInput
            id="weight"
            type="text"
            placeholder="Weight"
            fullWidth
            size="medium"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="city"
            type="text"
            placeholder="City/ Neighborhood"
            fullWidth
            size="medium"
          />
          <TextInput
            id="height"
            type="text"
            placeholder="Height"
            fullWidth
            size="medium"
          />
          <TextInput
            id="wingspan"
            type="text"
            placeholder="Wingspan"
            fullWidth
            size="medium"
          />
        </Grid>
      </Grid>

      <FieldSet title="Professional Info" />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput
            id="sport"
            type="text"
            placeholder="Sport"
            fullWidth
            size="medium"
          />
          <TextInput
            id="school"
            type="text"
            placeholder="School Attending"
            fullWidth
            size="medium"
          />
          <TextInput
            id="guardian"
            type="text"
            placeholder="Adult's Name (Parent/Guardian)"
            fullWidth
            size="medium"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="position-played"
            type="text"
            placeholder="Position Played"
            fullWidth
            size="medium"
          />
          <TextInput
            id="club-team"
            type="text"
            placeholder="Club/Team"
            fullWidth
            size="medium"
          />
          <TextInput
            id="adult-contacts"
            type="text"
            placeholder="Adult contacts (Phone Number)"
            fullWidth
            size="medium"
          />
        </Grid>
      </Grid>

      <FieldSet title="Upload the following Info" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInput
            id="age-verification-document"
            type="file"
            placeholder="Choose a file"
            fullWidth
            size="medium"
            InputLabelProps={{ shrink: true }}
            label="Age Verification Document (Birth Certificate, age verification form, birth notification, baptism card)"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="position-played"
            type="file"
            placeholder="Choose a file"
            fullWidth
            size="medium"
            label="Passport Photo (Headshot)"
          />
        </Grid>
      </Grid>

      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CheckboxInput
          checked={checked}
          onChange={handleChange}
          label={<CheckBoxLabel />}
        />
        <ButtonComponent
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Create Profile
        </ButtonComponent>
      </Box>
    </Box>
  );
};

export default AthleteProfileForm;
