import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IconButton, TablePagination, Toolbar } from '@material-ui/core';

import { Download as DownloadIcon } from 'react-feather';
import AppBar from '@material-ui/core/AppBar';
import { colors } from '../../../../../theme/theme';
import TableComponent from './Table';
import { StyledCard, useStyles } from './styles';
import {
  Loader,
  SearchButton,
  TableButtonGroup,
  FilterButton,
} from '../../../../../shared/Components';

import { IAthleteProfile } from '../../../../../models/athlete';

interface IProps {
  loading: boolean;
  athletes: Array<IAthleteProfile>;
}

const Results: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const { loading, athletes } = props;
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <StyledCard>
        <PerfectScrollbar>
          {/* AppBar Component */}
          <AppBar
            position="static"
            color="default"
            className={classes.appBarStyles}
          >
            <Toolbar>
              <div className={classes.filter}>
                <FilterButton />
              </div>
              <div>
                <SearchButton />
              </div>
              <div>
                <TableButtonGroup />
              </div>
              <IconButton>
                <DownloadIcon color={colors.icon} />
              </IconButton>
            </Toolbar>
          </AppBar>
          {/* End AppBar Component */}
        </PerfectScrollbar>
        {loading ? <Loader /> : <TableComponent athletes={athletes} />}

        <TablePagination
          component="div"
          count={100}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </StyledCard>
    </>
  );
};

Results.propTypes = {
  athletes: PropTypes.array.isRequired,
};

export default Results;
