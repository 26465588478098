import axios from 'axios';

import { API_URL } from '../index';
import authHeader from '../auth/auth.header';

class MediaApiService {
  fetchAllMediaFiles(mediaType: string) {
    return axios.get(API_URL + '/media/all', {
      params: {
        media_type: mediaType,
      },
      headers: { Authorization: `Bearer ${authHeader()}` },
    });
  }

  /**
   * Get media files per page
   * @param mediaType type of media file
   * @param page current page number
   * @returns Promise response
   */
  getAllMediaFilesPerPage(mediaType: string, page: number = 1) {
    return axios.get(API_URL + '/media/all', {
      headers: { Authorization: `Bearer ${authHeader()}` },
      params: {
        media_type: mediaType,
        page: page,
      },
    });
  }

  /**
   * Get media files with a given limit
   * @param mediaType type of media file
   * @param limit query limit
   * @returns Promise response
   */
  getAllMediaFilesPerLimit(mediaType: string, limit: number = 0) {
    return axios.get(API_URL + '/media/all', {
      headers: { Authorization: `Bearer ${authHeader()}` },
      params: {
        media_type: mediaType,
        page: limit,
      },
    });
  }
}

export default new MediaApiService();
