import React from 'react';

import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import MediaUploadForm from '../Form/MediaUploadForm';
import { dialogStyles, dialogTitleStyles, dividerStyles } from './styles';

interface IProps {
  isOpen: boolean;
  handleDialogClose(): void;
  dialogTypeTitle?: string;
}

const UploadMedia: React.FC<IProps> = (props: IProps) => {
  const { isOpen, handleDialogClose } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{ style: dialogStyles }}
      maxWidth={'md'}
      fullWidth={true}
    >
      <DialogTitle
        id="form-dialog-title"
        style={dialogTitleStyles}
        disableTypography={true}
      >
        Upload Video/ Photo
      </DialogTitle>
      <Grid container>
        <Grid item xs={12}>
          <Divider style={dividerStyles} />
        </Grid>
        <DialogContent style={{ paddingLeft: 110, paddingRight: 110 }}>
          <MediaUploadForm />
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default UploadMedia;
