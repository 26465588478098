import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(243, 244, 248, 0.3)',
      },
    },
    customWidth: {
      maxWidth: 50,
      paddingLeft: 23,
    },
  })
);
