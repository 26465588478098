import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import { VerifiedIcon, RatingIcon } from '../../../shared/Components/Icons';
import { AthleteCard } from '../../../models/athlete';
import { fontWeight } from '../../../theme/theme';

interface IAthleteCardProps {
  athlete: AthleteCard;
}

interface IRatingsComponentProps {
  playerRanking: number;
}

const textStyles: React.CSSProperties = {
  fontSize: 13,
  fontWeight: fontWeight.mediumOne,
};

const textContainerStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between ',
  marginBottom: 10,
};

const cardMediaStyles: React.CSSProperties = {
  height: 140,
};

const PlayerCard: React.FC<IAthleteCardProps> = (props) => {
  const {
    name,
    profile_photo,
    age,
    player_ranking,
    height,
    position_played,
    is_verified,
    user_id,
  } = props.athlete;

  const RatingsComponents = (playerRanking: number) => {
    const ratings = [];

    for (let index = 0; index < playerRanking; index++) {
      ratings.push(<RatingIcon />);
    }
    return ratings;
  };

  const handleCardClick = () => {
    console.log('Card got clicked!');
  };

  return (
    <Card style={{ width: 200, borderRadius: 5 }} elevation={3}>
      <Link to={`/athlete-profile/${user_id}`}>
        <CardActionArea onClick={handleCardClick}>
          {!profile_photo ? (
            <Skeleton variant="rect" height={140} />
          ) : (
            <CardMedia
              image={profile_photo}
              title={name}
              style={cardMediaStyles}
            />
          )}

          <CardContent>
            <Box style={textContainerStyles}>
              <Typography style={textStyles} variant="body2">
                {name} | {age}
              </Typography>
              {is_verified && <VerifiedIcon />}
            </Box>
            <Box style={{ display: 'flex', marginBottom: 10 }}>
              <Typography style={textStyles} variant="body2" component="p">
                {position_played} |
              </Typography>
              {RatingsComponents(player_ranking)}
            </Box>
            <Typography style={textStyles} variant="body2" component="p">
              {height}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
};

export default PlayerCard;
