import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import { colors } from '../../../../../theme/theme';
import {
  AllAthletesAvatar,
  CreditCardAvatar,
  ScoutsAvatar,
  DashboardHeader,
  HeroContent,
  ButtonComponent,
} from '../../../../../shared/Components';
import { Users as AthletesIcon } from 'react-feather';

const StyledCard = styled(Card)`
  padding: 30px 30px;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helper: {
      borderLeft: `1px solid #828282`,
      padding: theme.spacing(0, 2),
    },
  })
);

const header: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'end',
};

const title: string = 'Account Summary';

const athletesTitle: string = 'All Athletes';
const athletesCount = 318;

const paymentTitle: string = 'Monthly Subscriptions(KES)';
const paymentDate = '786,900.00';

const messageTitle = 'All Scouts';
const messageCount = 1896;

const AccountSummary: React.FC<{}> = () => {
  const history = useHistory();
  const navigateTo = () => history.push('/admin-dashboard/add-athlete');
  const classes = useStyles();
  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Box style={header}>
            <DashboardHeader>{title}</DashboardHeader>
            <ButtonComponent
              variant="contained"
              startIcon={<AthletesIcon />}
              style={{
                marginTop: 20,
                marginBottom: 20,
                background: colors.primary,
              }}
              onClick={navigateTo}
            >
              Add New Athlete
            </ButtonComponent>
          </Box>
        </Grid>
      </Grid>

      <StyledCard>
        <Grid container>
          <Grid item xs={5}>
            <HeroContent
              title={paymentTitle}
              date={paymentDate}
              icon={CreditCardAvatar}
            />
          </Grid>
          <Grid item xs={4} className={classes.helper}>
            <HeroContent
              title={messageTitle}
              count={messageCount}
              icon={ScoutsAvatar}
            />
          </Grid>
          <Grid item xs={3} className={classes.helper}>
            <HeroContent
              title={athletesTitle}
              count={athletesCount}
              icon={AllAthletesAvatar}
            />
          </Grid>
        </Grid>
      </StyledCard>
    </Box>
  );
};

export default AccountSummary;
