import { Card, Grid } from '@material-ui/core';
import styled from 'styled-components';

export const StyledGrid = styled(Grid)`
  @media (min-width: 960px) {
    :not(:last-of-type) {
      border-right: 1px solid #828282;
    }
    padding: 0 16px;
  }
`;

export const StyledCard = styled(Card)`
  padding: 30px 30px;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
`;

export const MessagesContainer = styled(Card)`
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
`;
