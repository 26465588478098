import React, { useState } from 'react';

import { Tooltip, IconButton, Badge } from '@material-ui/core';
import { Bell as NotificationsIcon } from 'react-feather';

import { colors } from '../../../../theme/theme';

const NotificationButton: React.FC<{}> = () => {
  const [notifications, setNotfications] = useState([]);
  return (
    <Tooltip title="Alerts • No alerts">
      <IconButton>
        <Badge
          badgeContent={notifications.length}
          color="primary"
          variant="dot"
        >
          <NotificationsIcon color={colors.text} />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default NotificationButton;
