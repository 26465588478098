import React, { useState, useEffect } from 'react';
import {
  Paper,
  Box,
  Container,
  Typography,
  TableRow,
  TableBody,
  Table,
  TableContainer,
} from '@material-ui/core';

import {
  AppBarComponent,
  DownloadButton,
  ButtonComponent,
} from '../../../../../shared/Components';

import { colors } from '../../../../../theme/theme';
import { MessageCircle as MessageIcon } from 'react-feather';
import { IScoutAccount } from '../../../../../models/user';
// Styles
import {
  TableCell,
  Grid,
  containerStyles,
  titleStyles,
  tableStyles,
} from '../styles';

const WEB_URL = process.env.REACT_APP_KACHEZWE_WEB_URL;

const PersonalInfo: React.FC<{}> = () => {
  const [scout, setScout] = useState<IScoutAccount>({} as IScoutAccount);

  return (
    <Container maxWidth={false} disableGutters>
      <Box style={containerStyles} component={Paper} mt={3}>
        {/* AppBar Component */}
        <AppBarComponent>
          <Typography style={titleStyles}>Personal Info</Typography>
          <ButtonComponent
            variant="contained"
            startIcon={<MessageIcon />}
            style={{
              background: colors.primary,
            }}
          >
            Message Scout
          </ButtonComponent>
        </AppBarComponent>
        {/* End AppBar Component */}

        <TableContainer>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Table style={tableStyles}>
                <TableBody>
                  <TableRow>
                    <TableCell>Full Name: </TableCell>
                    <TableCell>{`${scout.name}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Email: </TableCell>
                    <TableCell>{`${scout.email}`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Table style={tableStyles}>
                <TableBody>
                  <TableRow>
                    <TableCell>Telephone: </TableCell>
                    <TableCell>{`${scout.telephone}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Gender: </TableCell>
                    <TableCell>{`${scout.gender}`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={6}>
              <Table style={tableStyles}>
                <TableBody>
                  <TableRow>
                    <TableCell>Country: </TableCell>
                    <TableCell>{`${scout.country}`} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>School/Company affliation: </TableCell>
                    <TableCell>{`${scout.work_company}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>School/Company Email:</TableCell>
                    <TableCell>{`${scout.company_email}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>School/Company Phone:</TableCell>
                    <TableCell>{`${scout.company_phone}`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Table style={tableStyles}>
                <TableBody>
                  <TableRow>
                    <TableCell>Date of Birth(should match your ID): </TableCell>
                    <TableCell>{`${scout.date_of_birth}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>City/Neighborhood: </TableCell>
                    <TableCell>
                      {`${scout.city}` || `${scout.neighbourhood}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Role(scout/coach etc):</TableCell>
                    <TableCell>{`${scout.role}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Passport or Driver License Number:</TableCell>
                    <TableCell>
                      {`${scout.passport}` || `${scout.driver_license}`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={6}>
              <DownloadButton>Government National ID.pdf</DownloadButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DownloadButton>
                Proof of School/Company Affiliation.pdf
              </DownloadButton>
            </Grid>
          </Grid>
        </TableContainer>
      </Box>
    </Container>
  );
};
export default PersonalInfo;
