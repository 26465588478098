import React from 'react';

import { Box, ButtonGroup } from '@material-ui/core';
import ButtonComponent from '../Button';

import { colors } from '../../../theme/theme';
import { Grid as GridIcon, List as ListIcon } from 'react-feather';

const buttonGroupStyles: React.CSSProperties = {
  boxShadow: 'none',
  marginRight: 10,
  marginLeft: 10,
  borderRadius: 5,
  height: 35,
};

const buttonStyle: React.CSSProperties = {
  background: 'transparent',
  border: '0.5px solid #e5e5e5',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  padding: '18px 13px',
  height: 35,
};

const leftButton: React.CSSProperties = {
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  padding: '19px 13px',
  height: 35,
};

const TableButtonGroup: React.FC<{}> = () => {
  return (
    <Box>
      <ButtonGroup
        variant="contained"
        aria-label="contained primary button group"
        style={buttonGroupStyles}
      >
        <ButtonComponent color="primary" style={leftButton}>
          <ListIcon />
        </ButtonComponent>
        <ButtonComponent style={buttonStyle}>
          <GridIcon color={colors.link} />
        </ButtonComponent>
      </ButtonGroup>
    </Box>
  );
};

export default TableButtonGroup;
