import React from 'react';
import styled from 'styled-components';

import Box from '@material-ui/core/Box';

export const BoxStyled = styled(Box)`
  padding-top: 110px;
  padding-bottom: 50px;
  padding-left: 180px;
  display: flex;
  flex: 1;
  align-items: baseline;
  @media (max-width: 375px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 3rem;
  }
`;

export const imageStyle: React.CSSProperties = {
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: '100%',
};

export const playerInfoStyle: React.CSSProperties = {
  position: 'absolute',
  left: 20,
  bottom: 20,
};
