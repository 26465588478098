import React, { useState } from 'react';
import { Table, TableBody } from '@material-ui/core';

import { TableCell, TableRow, TableContainer } from '../styles';
import { DashboardHeader } from '../../../../../shared/Components';
import { IScoutAccount } from '../../../../../models/user';

const SubscriptionsTotalsTable: React.FC<{}> = () => {
  const [subscription, setSubscription] = useState<IScoutAccount>(
    {} as IScoutAccount
  );

  return (
    <>
      <DashboardHeader>Subscription Totals</DashboardHeader>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Product: </TableCell>
              <TableCell>{subscription.ordered_product}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total: </TableCell>
              <TableCell>{subscription.total_billing}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Subtotal: </TableCell>
              <TableCell>{subscription.total_billing}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total: </TableCell>
              <TableCell>{subscription.total_billing}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SubscriptionsTotalsTable;
