import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: '46db47a3a21fa34c52c0574d8fbd14ae',
  plugins: [new BugsnagPluginReact()],
});

Bugsnag.notify(new Error('Test error'));

const BugsnagErrorBoundary = Bugsnag?.getPlugin('react')?.createErrorBoundary(
  React
);

export default BugsnagErrorBoundary;
