import React from 'react';
import styled from 'styled-components';

import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import {
  createStyles,
  fade,
  Theme,
  createMuiTheme,
} from '@material-ui/core/styles';
import { colors, fontFamily, fontWeight } from '../../../../../theme/theme';
export const avatar: React.CSSProperties = {
  marginRight: 15,
};

export const buttonBackground: React.CSSProperties = {
  background: 'rgba(5, 198, 64, 0.2)',
};

export const customWidth: React.CSSProperties = {
  maxWidth: 50,
  paddingLeft: 23,
};

export const containerStyles: React.CSSProperties = {
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.07)',
  borderRadius: 5,
};

export const bgWhite: React.CSSProperties = {
  background: '#ffffff',
};

export const StyledButton = styled(Button)`
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const addAthleteStyledButton: React.CSSProperties = {
  marginTop: 10,
  marginBottom: 10,
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(243, 244, 248, 0.3)',
      },
      // height: 40,
    },
    filter: {
      flexGrow: 1,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    appBarStyles: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      borderBottom: '1px solid #F3F4F8',
      paddingTop: 15,
      paddingBottom: 10,
      paddingRight: 20,
      paddingLeft: 20,
    },
    tableButton: {
      color: colors.link,
      fontSize: 13,
      textTransform: 'none',
    },
  })
);

export const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: '5px 8px',
        height: '40px',
        fontFamily: fontFamily.sofiaPro,
        fontSize: 13,
        fontWeight: fontWeight.normal,
        borderBottom: '1px solid #F3F4F8',
      },
    },
  },
});
