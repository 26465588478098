import { colors, fontFamily, fontWeight } from '../../../../theme/theme';
import { withStyles } from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

export const containerStyles: React.CSSProperties = {
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.07)',
  borderRadius: 5,
};

export const buttonStyles: React.CSSProperties = {
  color: colors.text,
  boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 1px',
  background: 'rgb(255, 255, 255) none repeat scroll 0% 0%',
  border: '0.5px solid rgb(229, 229, 229)',
  padding: '18px 30px',
  height: 28,
  position: 'absolute',
  right: 0,
};

export const TableCell = withStyles({
  root: {
    padding: '0 10px 10px 0',
    fontFamily: fontFamily.sofiaPro,
    fontSize: 15,
    fontWeight: fontWeight.normal,
    borderBottom: 'none',
    verticalAlign: 'top',
    lineHeight: 1,
  },
})(MuiTableCell);

export const gridStyles: React.CSSProperties = {
  background: 'rgba(243, 244, 248, 0.3)',
  borderBottom: '1px solid #F3F4F8',
};
export const titleStyles: React.CSSProperties = {
  flexGrow: 1,
};

export const BottomGrid = styled(Grid)`
  padding-top: 10px;
  padding-bottom: 25px;
  background: rgba(243, 244, 248, 0.3);
  border-top: 1px solid #f3f4f8;

  @media (min-width: 960px) {
    padding-left: 100px;
  }
`;

export const StyledImage = styled.img`
  width: 60px;
  height: 55px;
  border-radius: 5px;
`;

export const StyledGrid = styled(Grid)`
  @media (min-width: 960px) {
    :not(:last-of-type) {
      border-right: 1px solid #f3f4f8;
    }
    padding: 0 24px;
    margin: 10px 0;
  }
`;
