import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { colors } from '../../../../theme/theme';
import headerImage from '../../../../assets/main-header-bg.jpg';

export const HeaderContainerStyled = styled(Box)`
  width: 100%;
  height: 700px;
  background: url(${headerImage}) no-repeat;
  background-size: cover;
  @media (max-width: 375px) {
    flex-direction: column;
    height: 410px;
    background-size: cover;
    margin-bottom: 4rem;
  }
`;

export const HeaderTextStyled = styled(Box)`
  padding-top: 10rem;
  padding-left: 5rem;
  padding-right: 21rem;
  @media (max-width: 375px) {
    flex-direction: column;
    padding-top: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const HeaderLinksStyled = styled(Box)`
  padding-left: 5rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 375px) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1rem;
    padding-top: 1rem;
  }
`;

export const LearnMoreLinkStyled = styled(Link)`
  color: ${colors.white};
  &: hover {
    color: ${colors.white};
  }
  font-size: 20px;
  padding-left: 1rem;
  @media (max-width: 375px) {
    padding-left: 0;
  }
`;

export const LearnMoreContainerStyled = styled(Box)`
  @media (max-width: 375px) {
    padding-left: 0;
    margin-top: 1rem;
  }
`;

export const ArrowDownwardIconContainerStyled = styled(Box)`
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
  @media (max-width: 375px) {
    display: none;
  }
`;

export const ContentContainerStyled = styled(Box)`
  background-color: ${colors.secondary};
  height: 907px;
  position: relative;
`;

export const FootballerImageStyle = styled(Box)`
  position: absolute;
  top: 100px;
  right: -30px;
`;

export const DescriptionContentStyled = styled(Typography)`
  padding-right: 5rem;
  padding-top: 2rem;
`;

export const WatchVideosContainerStyled = styled(Box)`
  padding-top: 3rem;
`;

export const WatchVideosLinkStyled = styled(Link)`
  color: ${colors.white};
  &: hover {
    color: ${colors.white};
  }
  font-size: 24px;
  padding-left: 1rem;
`;
