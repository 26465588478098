import React, { FC } from 'react';

import { Grid, Box } from '@material-ui/core';

import { StatsCard, DashboardHeader } from '../../../../../shared/Components';
import { statsCardsColor } from '../../../../../theme/theme';

import { IAthleteProfile } from '../../../../../models/athlete';

const introTitle: string = `My Athletes`;
const athletesTitle = `All Athletes`;
const athleteStat = 318;

const recruitedTitle = `Recruited`;
const recruitedStats = 0;
const undecidedTitle = `Undecided`;
const undecidedStats = 0;
const profilesTitle = `Updated Profiles`;
const profileStats = 0;

interface StatsProps {
  athletes: Array<IAthleteProfile>;
}

const AthletesStatsComponent: React.FC<StatsProps> = (props) => {
  const { athletes } = props;
  return (
    <Box>
      <DashboardHeader>{introTitle}</DashboardHeader>

      <Grid container spacing={1}>
        <Grid item xs={3}>
          <StatsCard
            title={athletesTitle}
            stats={athletes.length}
            backgroundColor={statsCardsColor.athletesStats}
          />
        </Grid>
        <Grid item xs={3}>
          <StatsCard
            title={recruitedTitle}
            stats={recruitedStats}
            backgroundColor={statsCardsColor.recruitedStats}
          />
        </Grid>
        <Grid item xs={3}>
          <StatsCard
            title={undecidedTitle}
            stats={undecidedStats}
            backgroundColor={statsCardsColor.undecidedStats}
          />
        </Grid>
        <Grid item xs={3}>
          <StatsCard
            title={profilesTitle}
            stats={profileStats}
            backgroundColor={statsCardsColor.updatedProfilesStats}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AthletesStatsComponent;
