import {
  AuthActionTypes,
  AuthenticatedUserState,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOG_OUT_USER_SUCCESS,
  LOG_OUT_USER_REQUEST,
  LOG_OUT_USER_FAILURE,
} from '../types/auth';

const defaultState: AuthenticatedUserState = {
  loggedIn: false,
  data: {
    message: '',
    access_token: '',
    refresh_token: '',
    name: '',
    username: '',
    user_id: 0,
    role_name: '',
  },
};

const userStore = localStorage.getItem('user');
const loggedInStore = localStorage.getItem('loggedIn');

const initialState: AuthenticatedUserState =
  userStore && loggedInStore
    ? {
        loggedIn: JSON.parse(loggedInStore),
        data: JSON.parse(userStore),
      }
    : defaultState;

const authentication = (
  state = initialState,
  action: AuthActionTypes
): AuthenticatedUserState | {} => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return {
        ...action.payload,
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...action.payload,
      };
    case LOGIN_USER_FAILURE:
      return {
        loggedIn: false,
      };
    case LOG_OUT_USER_REQUEST:
      return {};
    case LOG_OUT_USER_SUCCESS:
      return {
        loggedIn: false,
      };
    case LOG_OUT_USER_FAILURE:
      return {
        loggedIn: true,
      };
    default:
      return state;
  }
};

export default authentication;
