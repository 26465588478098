import React from 'react';
import { Paper, Box, Container, IconButton } from '@material-ui/core';
import { Download as DownloadIcon } from 'react-feather';

import TableComponent from './Table';

import {
  AppBarComponent,
  FilterButton,
  SearchButton,
} from '../../../../../../shared/Components';
import { IScoutAccount } from '../../../../../../models/user';

interface IProps {
  loading: boolean;
  scouts: Array<IScoutAccount>;
}

const containerStyles: React.CSSProperties = {
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.07)',
  borderRadius: 5,
};

const SubscriptionsListView: React.FC<IProps> = (props) => {
  const { loading, scouts } = props;

  return (
    <Container maxWidth={false} disableGutters>
      <Box style={containerStyles} component={Paper} mt={3}>
        {/* AppBar Component */}
        <AppBarComponent>
          <FilterButton />
          <SearchButton />
          <IconButton>
            <DownloadIcon />
          </IconButton>
        </AppBarComponent>
        {/* End AppBar Component */}
        <TableComponent scouts={scouts} loading={loading} />
        {/* Pagination Component */}
        {/* End pagination component */}
      </Box>
    </Container>
  );
};

export default SubscriptionsListView;
