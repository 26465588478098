import React from 'react';

import styled from 'styled-components';
import Box, { BoxProps } from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import { ReactComponent as FacebookIcon } from '../../../assets/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/twitter.svg';
import { ReactComponent as LinkedInIcon } from '../../../assets/linkedin.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/instagram.svg';

const SocialMediaBoxStyled = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: space-between;
  width: 130px;
`;

const SocialMedia: React.FC<BoxProps> = (props) => {
  const { ...rest } = props;

  return (
    <SocialMediaBoxStyled {...rest}>
      <Link>
        <FacebookIcon />
      </Link>
      <Link>
        <TwitterIcon />
      </Link>
      <Link>
        <LinkedInIcon />
      </Link>
      <Link>
        <InstagramIcon />
      </Link>
    </SocialMediaBoxStyled>
  );
};

export default SocialMedia;
