import React from 'react';

import Typography from '@material-ui/core/Typography';

import { ReactComponent as PlayCircleIcon } from '../../../../assets/play-circle-icon.svg';
import {
  ContentContainerStyled,
  DescriptionContentStyled,
  WatchVideosContainerStyled,
  WatchVideosLinkStyled,
  HeaderTextStyled,
} from './styles';
const headerContent: string = `Database of all the unseen talent on the African Continent!`;
const descriptionContent: string =
  'Add some description paragraph here. The ability to communicate with them, advise themon what they should work on and provide instructions';

const ContentSectionOne: React.FC<{}> = () => {
  return (
    <ContentContainerStyled>
      <HeaderTextStyled>
        <Typography variant="h1" color={'textSecondary'}>
          {headerContent}
        </Typography>
        <DescriptionContentStyled variant="body2" color={'textSecondary'}>
          {descriptionContent}
        </DescriptionContentStyled>

        <WatchVideosContainerStyled>
          <PlayCircleIcon />
          <WatchVideosLinkStyled href="#">
            Watch Our Videos
          </WatchVideosLinkStyled>
        </WatchVideosContainerStyled>
      </HeaderTextStyled>

      {/* <FootballerImageStyle>
        <img src={footballerPlayerImage} />
      </FootballerImageStyle> */}
    </ContentContainerStyled>
  );
};

export default ContentSectionOne;
