import React from 'react';
import Typography from '@material-ui/core/Typography';

import { validationStyling } from './styles';

interface IProps {
  message: string;
}

const ValidationErrorMessage: React.FC<IProps> = (props: IProps) => {
  const { message } = props;
  return (
    <Typography variant="body1" style={validationStyling}>
      {message}
    </Typography>
  );
};

export default ValidationErrorMessage;
