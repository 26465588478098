import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { colors } from '../../../theme/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
      paddingRight: 70,
      paddingLeft: 32,
      overflow: 'hidden',
      background: colors.backgroundOne,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 20,
        paddingLeft: 20,
      },
    },
  })
);

const DashboardLayout: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        {children}
      </div>
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.any,
};

export default DashboardLayout;
