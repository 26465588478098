/**
 * Routes
 */

import { IRoute } from '../../models/route';

export const routes: Array<IRoute> = [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'Basketball',
    link: '/basketball',
  },
];

export const dashboardRoutes: Array<IRoute> = [
  {
    name: 'Dashboard',
    link: '/dashboard',
  },
  {
    name: 'My Athletes',
    link: '/dashboard/my-athletes',
  },
  {
    name: 'Add Athletes',
    link: '/dashboard/add-athlete',
  },
  {
    name: 'Edit Athletes',
    link: '/dashboard/edit-athlete',
  },
];
