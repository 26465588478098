import React, { FC } from 'react';
import styled from 'styled-components';

import IconAvatar from '../../Avatar';

import { ScoutIcon } from '../../Icons';

const StyledAvatar = styled(IconAvatar)`
  background-color: rgba(47, 128, 237, 0.2);
`;
const color = '#2F80ED';
const ScoutsAvatarAdmin: React.FC<{}> = () => {
  return (
    <StyledAvatar>
      <ScoutIcon iconColor={color} />
    </StyledAvatar>
  );
};

export default ScoutsAvatarAdmin;
