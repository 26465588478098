import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import { Eye as ViewIcon } from 'react-feather';

import { CheckboxInput } from '../../../../../../shared/Components/Form/Input';
import {
  TableProfileAvatar,
  RatingsComponent,
  TableWrapper,
  Loader,
} from '../../../../../../shared/Components';
import { customWidth, useStyles } from './styles';

import { IAthleteProfile } from '../../../../../../models/athlete';

const WEB_URL = process.env.REACT_APP_KACHEZWE_WEB_URL;

interface IProps {
  athletes: Array<IAthleteProfile>;
  loading: boolean;
}

const TableComponent: React.FC<IProps> = (props) => {
  const { athletes, loading } = props;
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <TableWrapper>
        <TableHead>
          <TableRow>
            <TableCell padding="default" style={customWidth}>
              <CheckboxInput label="" />
            </TableCell>
            <TableCell>Athlete</TableCell>
            <TableCell>Rating</TableCell>
            <TableCell>Current Rank</TableCell>
            <TableCell>Position</TableCell>
            <TableCell>Country</TableCell>
            <TableCell>Height</TableCell>
            <TableCell>Weight(Kgs)</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>

        {loading ? (
          <Loader />
        ) : (
          <TableBody>
            {athletes.map((athlete, index) => (
              <TableRow key={index} className={classes.row}>
                <TableCell style={customWidth}>
                  <CheckboxInput label="" />
                </TableCell>
                <TableCell>
                  <TableProfileAvatar
                    image={`${WEB_URL}${athlete.profile_photo}`}
                    name={athlete.name}
                    verified={athlete.is_verified}
                  />
                </TableCell>

                <TableCell>
                  <RatingsComponent rating={athlete.player_rating} />
                </TableCell>
                <TableCell>{athlete.player_ranking}</TableCell>
                <TableCell variant="body">{athlete.position_played}</TableCell>
                <TableCell>{athlete.country}</TableCell>
                <TableCell>{athlete.height}</TableCell>
                <TableCell>{athlete.weight}</TableCell>
                <TableCell>
                  <IconButton>
                    <ViewIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </TableWrapper>
    </>
  );
};

export default TableComponent;
