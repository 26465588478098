import axios from 'axios';
import authHeader from './api/auth/auth.header';

import { IAthleteAccount } from '../../models/user';
import { IMedia } from '../../models/media';

const API_URL = process.env.REACT_APP_KACHEZWE_API_SERVICE_URL;

class UserService {
  getUser(userId: number) {
    return axios.get(API_URL + '/user', {
      params: {
        user_id: userId,
      },
    });
  }

  getAthlete(userId: number) {
    return axios.get(API_URL + '/athlete', {
      headers: { Authorization: `Bearer ${authHeader()}` },
      params: {
        user_id: userId,
      },
    });
  }

  getUsers() {
    return axios.get(API_URL + '/user/all-users', {
      headers: { Authorization: `Bearer ${authHeader()}` },
    });
  }

  getAthletes() {
    return axios.get(API_URL + '/athlete/all-athletes', {
      headers: { Authorization: `Bearer ${authHeader()}` },
    });
  }

  updateAthletes(userId: number, data: any) {
    return axios.put(API_URL + '/athlete', data, {
      headers: { Authorization: `Bearer ${authHeader()}` },
      params: {
        user_id: userId,
      },
    });
  }

  registerAthlete(data: Partial<IAthleteAccount>) {
    return axios.post(API_URL + '/athlete/register', data, {
      headers: { Authorization: `Bearer ${authHeader()}` },
    });
  }

  getMediaFiles(userId: number, mediaType: string) {
    return axios.get(API_URL + '/media/athlete/all', {
      headers: { Authorization: `Bearer ${authHeader()}` },
      params: {
        user_id: userId,
        media_type: mediaType,
      },
    });
  }

  addMediaFile(userId: number, data: Partial<IMedia>, file: any) {
    const formData = new FormData();
    formData.append('media_file', file);
    formData.append('media_type', data.media_type);
    formData.append('media_name', data.media_name);

    console.log(formData);
    if (data.media_type === 'video') {
      return axios.post(API_URL + '/media/athlete/video', formData, {
        headers: {
          Authorization: `Bearer ${authHeader()}`,
          'Content-Type': 'multipart/form-data',
        },
        params: { user_id: userId },
      });
    } else if (data.media_type === 'image') {
      return axios.post(API_URL + '/media/athlete/photo', formData, {
        headers: {
          Authorization: `Bearer ${authHeader()}`,
          'Content-Type': 'multipart/form-data',
        },
        params: { user_id: userId },
      });
    } else {
      return axios.post(API_URL + '/media/athlete/profile', formData, {
        headers: {
          Authorization: `Bearer ${authHeader()}`,
          'Content-Type': 'multipart/form-data',
        },
        params: { user_id: userId },
      });
    }
  }
}

export default new UserService();
