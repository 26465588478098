import React from 'react';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import { fontWeight } from '../../../../theme/theme';

import { Power as SuspendIcon } from 'react-feather';
import { UserCheck as ApproveIcon } from 'react-feather';

import {
  approveButton,
  suspendButton,
  headerBottomSectionStyled,
  profileImageStyle,
} from './styles';
import {
  DashboardHeader,
  ButtonComponent,
  LinkComponent,
} from '../../../../shared/Components';

const header: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'end',
};

interface IMainHeaderProps {
  imageUrl: string;
  athleteProfileImageUrl?: string;
  athleteName: string;
  athleteUserId: number;
}

const MainHeader: React.FC<IMainHeaderProps> = (props: IMainHeaderProps) => {
  const { athleteProfileImageUrl, athleteName, athleteUserId } = props;
  const history = useHistory();

  const HeaderContainerStyled = styled(Box)`
    width: 100%;
    height: 500px;
    background: url(${props.imageUrl}) no-repeat;
    background-size: contain;
    position: relative;
  `;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box style={header}>
            <DashboardHeader>
              Scout | <LinkComponent>{athleteName}</LinkComponent>
            </DashboardHeader>
            <Box>
              <ButtonComponent
                variant="contained"
                startIcon={<ApproveIcon />}
                style={approveButton}
              >
                Approve Scout
              </ButtonComponent>
              <ButtonComponent
                variant="contained"
                startIcon={<SuspendIcon />}
                style={suspendButton}
              >
                Suspend Scout
              </ButtonComponent>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <HeaderContainerStyled>
        <Box style={headerBottomSectionStyled}>
          <Box
            style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}
          >
            <img
              src={athleteProfileImageUrl}
              alt={athleteName}
              style={profileImageStyle}
            />
            <Box style={{ marginLeft: 20 }}>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ fontSize: 24, fontWeight: fontWeight.bold }}
              >
                {athleteName}
              </Typography>
            </Box>
          </Box>
        </Box>
      </HeaderContainerStyled>
    </>
  );
};

export default MainHeader;
