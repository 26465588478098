import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import ConversationList from './ConversationItem/ConversationList';
import MessageSearch from './Search/Search';
import SearchResults from './Search/SearchResult';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create('width'),
    paddingRight: 20,
  },
  collapse: { width: 96 },
  hide: { display: 'none' },
}));

const MessageSidebar: React.FC<{}> = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isFocused, setFocused] = useState(false);
  const classes = useStyles();

  // const { activeConversationId } = useSelector((state) => state.chat);

  const activeConversationId = '8864c717-587d-472a-929a-8e5f298024da-0';

  const displayResults = searchQuery && setFocused;

  const handleSearchClickAway = () => {
    setSearchQuery('');
    setFocused(false);
  };
  return (
    <div className={clsx(classes.root)}>
      <Box>
        <MessageSearch onClickAway={handleSearchClickAway} />

        {!displayResults ? (
          <ConversationList activeConversationId={activeConversationId} />
        ) : (
          <SearchResults query={searchQuery} results={searchResults} />
        )}
      </Box>
    </div>
  );
};

export default MessageSidebar;
