import React from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import AthleteDetails from './AthleteDetails';
import PersonalInfo from './PersonalInfo';
import { IAthleteProfile } from '../../../../../models/athlete';

interface IAthleteProps {
  athlete: IAthleteProfile;
  media?: Array<any>;
}

const AthleteContent: React.FC<IAthleteProps> = (props) => {
  const { athlete, media } = props;

  return (
    <Box style={{ marginBottom: 50 }}>
      <Grid container spacing={3}>
        <Grid item lg={4} xs={12}>
          <PersonalInfo athlete={athlete} />
        </Grid>
        <Grid item lg={8} xs={12}>
          <AthleteDetails />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AthleteContent;
