import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { IAthleteProfile } from '../../../../../models/athlete';

interface IProfileListProps {
  athlete: IAthleteProfile;
}

const ProfileList: React.FC<IProfileListProps> = (props: IProfileListProps) => {
  const { athlete } = props;

  return (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <List component="ul">
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`Ranking:`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`Country:`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`Location:`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`Height:`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`Weight:`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`Wingspan:`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`Telephone:`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`Email:`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`Parent's/Guardian's contacts:`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`Parent's/Guardian's name:`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`School:`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`Club/Team:`} />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={6}>
          <List component="ul">
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`${athlete.player_ranking}`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`${athlete.country}`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`${athlete.neighbourhood}`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`${athlete.height}`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`${athlete.weight}`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`${athlete.wing_span}`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`${athlete.telephone}`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`${athlete.email}`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`${athlete.adult_phone_number}`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`${athlete.adult_name}`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`${athlete.attending_school}`} />
            </ListItem>
            <ListItem style={{ paddingLeft: 0 }}>
              <ListItemText primary={`${athlete.club_team}`} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileList;
