import React, { useState } from 'react';
import { IScoutAccount } from '../../../../models/user';
import { ScoutSummary, ScoutListView } from './Content';

const ScoutPage: React.FC<{}> = () => {
  const [scouts, setAthletes] = useState<Array<IScoutAccount>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  return (
    <>
      <ScoutSummary />
      <ScoutListView loading={loading} scouts={scouts} />
    </>
  );
};

export default ScoutPage;
