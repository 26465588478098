import React from 'react';

import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../../../../theme/theme';

interface ICheckboxInputProps extends CheckboxProps {
  label: any;
}

const useStyles = makeStyles({
  icon: {
    backgroundColor: colors.white,
    borderColor: '#C4C4C4',
    borderRadius: 3,
    width: 20,
    height: 20,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  checkedIcon: { width: 20, height: 20 },
});

const CheckboxInput: React.FC<ICheckboxInputProps> = (props) => {
  const { label, ...rest } = props;
  const classes = useStyles();
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...rest}
          color="primary"
          icon={<span className={classes.icon} />}
        />
      }
      label={label}
    />
  );
};

export default CheckboxInput;
