import React from 'react';

import Box from '@material-ui/core/Box';

import EditAthleteProfileComponent from '../../../../shared/Components/Form/EditAthleteProfile';

const EditAthleteComponent: React.FC<{}> = () => {
  return (
    <Box>
      <EditAthleteProfileComponent />
    </Box>
  );
};

export default EditAthleteComponent;
