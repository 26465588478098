import React, { FC } from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../theme/theme';
import Grid from '@material-ui/core/Grid';

const HeaderComponent: FC<React.PropsWithChildren<{}>> = (props) => {
  const { children } = props;

  return (
    <Grid container style={{ backgroundColor: colors.white }}>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

HeaderComponent.propTypes = {
  children: PropTypes.any,
};

export default HeaderComponent;
