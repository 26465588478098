import React from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { StyledGrid, StyledCard, header, buttonStyles } from './styles';

import { Power as SuspendIcon } from 'react-feather';

import {
  ScoutsAvatar,
  OverduePaymentAvatar,
  DashboardHeader,
  HeroContent,
  CreditCardAvatar,
  ButtonComponent,
  LinkComponent,
} from '../../../../shared/Components';

const overduePaymentTitle: string = 'Overdue Payments';
const overduePaymentCount = 0;

const paymentTitle: string = 'Total Contribution To Date';
const paymentDate = 786900;

const allAthletesTitle = 'Months Active';
const allAthletesCount = 1896;

const color = '#FC303B';

interface IHeaderProps {
  scoutName: string;
}

const MainHeader: React.FC<IHeaderProps> = (props) => {
  const { scoutName } = props;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box style={header}>
            <DashboardHeader>
              Subscriptions | <LinkComponent>{scoutName}</LinkComponent>
            </DashboardHeader>
            <ButtonComponent
              variant="contained"
              startIcon={<SuspendIcon />}
              style={buttonStyles}
            >
              Suspend Scout
            </ButtonComponent>
          </Box>
        </Grid>
      </Grid>

      <StyledCard>
        <Grid container>
          <StyledGrid item xs={12} sm={5}>
            <HeroContent
              title={paymentTitle}
              count={paymentDate}
              icon={CreditCardAvatar}
            />
          </StyledGrid>
          <StyledGrid item xs={12} sm={4}>
            <HeroContent
              title={allAthletesTitle}
              count={allAthletesCount}
              icon={ScoutsAvatar}
            />
          </StyledGrid>
          <StyledGrid item xs={12} sm={3}>
            <HeroContent
              title={overduePaymentTitle}
              count={overduePaymentCount}
              icon={OverduePaymentAvatar}
              fontColor={color}
            />
          </StyledGrid>
        </Grid>
      </StyledCard>
    </>
  );
};

export default MainHeader;
