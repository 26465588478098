import React, { PropsWithChildren } from 'react';

import Link, { LinkProps } from '@material-ui/core/Link';
import styled from 'styled-components';

import { colors } from '../../../theme/theme';

const LinkStyled = styled(Link)`
  color: ${colors.link};
  &: hover {
    color: ${colors.link};
  }
`;

const LinkComponent: React.FC<PropsWithChildren<LinkProps>> = (props) => {
  const { children } = props;
  return <LinkStyled>{children}</LinkStyled>;
};

export default LinkComponent;
