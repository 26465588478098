import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Container from '@material-ui/core/Container';

import MainHeader from './Header';
import { ContentComponent } from '../../../../shared/Layout/PublicPage';
import AthleteContent from './AthleteContent';
import UserService from '../../../../shared/services/user.service';
import { IAthleteProfile } from '../../../../models/athlete';

import headerImage from '../../../../assets/player-profile-default-header.png';

const AthleteProfileDashboard: React.FC<{}> = () => {
  const [athlete, setAthlete] = useState<IAthleteProfile>(
    {} as IAthleteProfile
  );

  const [media, setMedia] = useState<any>([]);

  const WEB_URL = process.env.REACT_APP_KACHEZWE_WEB_URL;
  const { id } = useParams();

  // fetch all athlete data
  useEffect(() => {
    UserService.getAthlete(id)
      .then((response) => {
        setAthlete(response.data);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.error(resMessage);
      });

    UserService.getMediaFiles(id, 'video')
      .then((response) => {
        console.log(response.data);
        setMedia(response.data);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.error(resMessage);
      });
  }, [id]);

  return (
    <>
      <ContentComponent>
        <MainHeader
          imageUrl={headerImage}
          athleteProfileImageUrl={`${WEB_URL}${athlete.profile_photo}`}
          athleteAge={athlete.age}
          athleteGender={athlete.gender}
          athleteName={athlete.name}
          athleteRanking={athlete.player_ranking ? athlete.player_ranking : 0}
          athleteRating={athlete.player_rating ? athlete.player_rating : 1}
          athleteUserId={id}
        />
        <AthleteContent athlete={athlete} media={media} />
      </ContentComponent>
    </>
  );
};

export default AthleteProfileDashboard;
