import React from 'react';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import styled from 'styled-components';
import { colors, fontWeight } from '../../../../theme/theme';

export const ContainerStyled = styled(Box)`
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 70px;
  padding-right: 70px;
  max-height: 675px;
  background: rgb(252, 48, 59);
  background: -moz-linear-gradient(
    70deg,
    rgba(252, 48, 59, 1) 0%,
    rgba(159, 86, 214, 1) 100%
  );
  background: -webkit-linear-gradient(
    70deg,
    rgba(252, 48, 59, 1) 0%,
    rgba(159, 86, 214, 1) 100%
  );
  background: linear-gradient(
    70deg,
    rgba(252, 48, 59, 1) 0%,
    rgba(159, 86, 214, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fc303b",endColorstr="#9f56d6",GradientType=1);
  @media (max-width: 375px) {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

export const VideoListContainerStyled = styled.div`
  display: flex;
  overflow: auto;
  width: 100%;
`;

export const FeaturedVideoItemStyled = styled(Box)`
  width: 420px;
  padding: 0 40px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
  flex-shrink: 0;
  @media (max-width: 375px) {
    width: 320px;
    padding-left: 0;
  }
`;

export const LinkStyled = styled(Link)`
  &: hover {
    color: ${colors.white};
  }
`;

export const smallTextStyle: React.CSSProperties = {
  fontSize: 14,
  fontWeight: fontWeight.mediumOne,
};

export const imageStyle: React.CSSProperties = {
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: '100%',
};
