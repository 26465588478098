import React from 'react';

import Typography from '@material-ui/core/Typography';

import '../index.scss';
import { FooterBoxStyled, MenuLinkStyled } from './styles';

const FooterBottom: React.FC<{}> = () => {
  return (
    <FooterBoxStyled className="footer-bottom">
      <Typography variant="body1">
        kachezwe 2020. All Rights Reserved
      </Typography>
      <Typography variant="body1">
        <MenuLinkStyled color="textSecondary">
          Terms & Conditions
        </MenuLinkStyled>
        {' | '}
        <MenuLinkStyled color="textSecondary">Privacy Policy</MenuLinkStyled>
      </Typography>
    </FooterBoxStyled>
  );
};

export default FooterBottom;
