import React from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';

interface IMainHeaderProps {
  imageUrl: string;
}

const headerTitle: string = 'Do The Impossible';

const MainHeader: React.FC<IMainHeaderProps> = (props) => {
  const HeaderContainerStyled = styled(Box)`
    width: 100%;
    height: 700px;
    background: url(${props.imageUrl}) no-repeat;
    background-size: contain;
  `;

  const HeaderTextStyled = styled(Box)`
    padding-top: 10rem;
    padding-left: 5rem;
  `;
  return (
    <>
      <HeaderContainerStyled>
        <HeaderTextStyled>
          <Typography
            variant="h3"
            color={'textSecondary'}
            style={{ fontSize: 180 }}
          >
            {headerTitle}
          </Typography>

          <Typography variant="body2" color={'textSecondary'}>
            Complete your profile and access the unlimited features of Kachezwe
          </Typography>
        </HeaderTextStyled>
      </HeaderContainerStyled>
    </>
  );
};

export default MainHeader;
