import React from 'react';
import { Box, List, ListItem } from '@material-ui/core';

import LogoComponent from '../../../shared/Components/Logo';

import { UpgradeCard, Navigator, NavItem } from '../../../shared/Components';
import { scoutNavListItems } from '../../../shared/data/navItems';

const listStyles: React.CSSProperties = {
  paddingTop: 45,
  paddingLeft: 25,
  marginBottom: 10,
};

const logoIconStyles: React.CSSProperties = {
  marginBottom: 10,
};

const content = (
  <>
    <List style={listStyles}>
      <ListItem style={logoIconStyles}>
        <LogoComponent />
      </ListItem>

      <Box>
        <List>
          {scoutNavListItems.map((item, itemIndex) => (
            <NavItem
              title={item.title}
              key={itemIndex}
              icon={item.icon}
              link={item.link}
            />
          ))}
        </List>
      </Box>
      <UpgradeCard />
    </List>
  </>
);

const ScoutSidebar: React.FC<{}> = () => {
  return <Navigator>{content}</Navigator>;
};

export default ScoutSidebar;
