import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

import { ButtonComponent } from '../../../../shared/Components';
import { colors, fontWeight } from '../../../../theme/theme';

export const ContentStyled = styled(Box)`
  background: ${colors.backgroundThree};
  padding-left: 180px;
  padding-right: 130px;
  padding-top: 140px;
  padding-bottom: 70px;
  @media (max-width: 375px) {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 0;
  }
`;

export const buttonStyle = {
  marginTop: 100,
  '@media (max-width: 375px)': {
    fontSize: '1rem',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
};

export const ButtonStyled = styled(ButtonComponent)`
  margin-top: 100px;
  @media (max-width: 375px) {
    font-size: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

export const ButtonStyles = makeStyles({
  root: {
    marginTop: 100,
    '@media (max-width: 375px)': {
      fontSize: '1rem',
      marginBottom: '1rem',
      marginTop: '1rem',
    },
  },
});

export const imageStyle: React.CSSProperties = {
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: '100%',
};

export const TitleStyled = styled(Typography)`
  font-size: 24px;
  font-weight: ${fontWeight.bold};
  @media (max-width: 375px) {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

export const SliderWrapper = styled('div')`
  .slick-dots {
    position: absolute;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: start;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 8px;
    height: 6px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    transition: width 0.3s ease-in-out;
  }

  .slick__dots {
    height: 6px;
    width: 14px;
    background-color: #d1d1d2;
    border-radius: 4px;
    position: relative;
  }

  .slick-dots li {
    width: 14px;
    margin: 0 3px;
    transition: width 0.3s ease-in-out;
  }

  .slick-dots .slick-active {
    width: 24px;
    transition: width 0.3s ease-in-out;
  }

  .slick-dots .slick-active .slick__dots {
    width: 24px;
    top: 0px;
    overflow: hidden;
    background-color: #05c640;
  }
`;
