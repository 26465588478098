import React, { FC } from 'react';

import { Route, Switch } from 'react-router-dom';

import Home from './views/HomePage';
import AthletesPage from './views/Athletes';
import ScoutPage from './views/ScoutsPage';
import SubscriptionPage from './views/SubscriptionsPage';
import ScoutSubscriptionProfile from './views/SubscriptionProfile';
import AddAthleteComponent from './views/AddAthlete';
import EditAthleteComponent from './views/EditAthlete';
import AthleteProfileDashboard from './views/AthleteProfile';
import NotFoundPage from '../ErrorPages/NotFoundPage';
import ScoutProfileDashboard from './views/ScoutProfile';

interface IProps {
  path: string;
}

const AdminDashboardContent: FC<IProps> = ({ path }: IProps) => {
  return (
    <Switch>
      <Route exact path={path} component={Home} />
      <Route path={`${path}/scouts`} component={ScoutPage} />
      <Route path={`${path}/subscriptions`} component={SubscriptionPage} />
      <Route
        path={`${path}/subscription-profile/:id`}
        component={ScoutSubscriptionProfile}
      />
      <Route path={`${path}/athletes`} component={AthletesPage} />
      <Route path={`${path}/add-athlete`} component={AddAthleteComponent} />
      <Route
        path={`${path}/edit-athlete/:id`}
        component={EditAthleteComponent}
      />
      <Route
        path={`${path}/athlete-profile/:id`}
        component={AthleteProfileDashboard}
      />
      <Route
        path={`${path}/scout-profile/:id`}
        component={ScoutProfileDashboard}
      />
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

export default AdminDashboardContent;
