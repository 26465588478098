import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import { useHistory, useParams } from 'react-router-dom';

import { TextInput } from '../Input';
import ButtonComponent from '../../Button';
import { IAthleteProfile } from '../../../../models/athlete';
import UserService from '../../../services/user.service';
import AlertComponent from '../../Alert';

import {
  User as UserIcon,
  Phone as PhoneIcon,
  Mail as MailIcon,
} from 'react-feather';
import { colors, fontWeight } from '../../../../theme/theme';
import { AxiosError } from 'axios';

const genders = [
  {
    value: '',
    label: 'Select a gender',
  },
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
];

const formBoxStyled: React.CSSProperties = {
  backgroundColor: colors.backgroundOne,
  paddingLeft: 110,
  paddingTop: 13,
  paddingBottom: 75,
  paddingRight: 110,
  marginBottom: 30,
};

const titleStyles: React.CSSProperties = {
  fontSize: 24,
  fontWeight: fontWeight.normal,
  textAlign: 'center',
  marginBottom: 30,
};

const dividerStyles: React.CSSProperties = {
  borderTop: '1px solid #F2F2F2',
  marginTop: 30,
  marginBottom: 30,
};

const fieldSetTextStyles: React.CSSProperties = {
  fontSize: 16,
  fontWeight: 500,
  marginBottom: 10,
};

const FieldSet = (props: any) => (
  <Grid container style={{ display: 'flex', alignItems: 'center' }}>
    <Grid item xs={3}>
      <Typography style={fieldSetTextStyles}>{props.title}</Typography>
    </Grid>
    <Grid item xs={9}>
      <Divider />
    </Grid>
  </Grid>
);

const EditAthleteProfileComponent: React.FC<{}> = () => {
  const history = useHistory();
  const { id } = useParams();
  const [error, setError] = useState<AxiosError | null>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [successAlert, showSuccessAlert] = useState<boolean>(false);
  const [form, setFormState] = useState<Partial<IAthleteProfile>>({
    first_name: '',
    last_name: '',
    telephone: '',
    email: '',
    gender: '',
    date_of_birth: '',
    weight: '',
    height: '',
    wing_span: '',
    sport: '',
    attending_school: '',
    adult_name: '',
    adult_phone_number: '',
    club_team: '',
    position_played: '',
  });

  useEffect(() => {
    UserService.getAthlete(id)
      .then((response) => {
        console.log(response.data);
        setFormState(response.data);
      })
      .catch((error: AxiosError) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.error(resMessage);
        setError(resMessage);
      });
  }, []);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await UserService.updateAthletes(id, form)
      .then((response) => {
        console.log(response.data);
        showSuccessAlert(true);
        setOpenSnackbar(true);
        setTimeout(function () {
          history.push('/dashboard/admin/athletes');
        }, 2000);
      })
      .catch((error: AxiosError) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.error(resMessage);
        setError(resMessage);
      });
  };

  const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box style={formBoxStyled}>
      {error && <AlertComponent severity="error">{error}</AlertComponent>}
      {successAlert && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleAlertClose}
        >
          <AlertComponent onClose={handleAlertClose} severity="success">
            Athlete has been registered successfully. Redirecting now!
          </AlertComponent>
        </Snackbar>
      )}

      <form onSubmit={handleUpdate}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography style={titleStyles}>
              Update Athlete&apos;s Profile
            </Typography>
            <Divider style={dividerStyles} />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              name="first_name"
              id="firstName"
              type="text"
              placeholder="First Name"
              fullWidth
              size="medium"
              icon={<UserIcon color={colors.icon} />}
              value={form.first_name}
              onChange={onChange}
              label="First Name"
            />
            <TextInput
              id="telephoneNo"
              name="telephone"
              type="text"
              placeholder="Telephone Number"
              fullWidth
              size="medium"
              icon={<PhoneIcon color={colors.icon} />}
              value={form.telephone}
              onChange={onChange}
              label="Telephone"
            />
            <TextInput
              name="gender"
              id="gender"
              placeholder="Gender"
              fullWidth
              size="medium"
              select
              SelectProps={{
                native: true,
              }}
              value={form.gender || ''}
              onChange={onChange}
              label="Gender"
            >
              {genders.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              name="last_name"
              id="lastName"
              type="text"
              placeholder="Last Name"
              fullWidth
              size="medium"
              icon={<UserIcon color={colors.icon} />}
              value={form.last_name}
              onChange={onChange}
              label="Last Name"
            />
            <TextInput
              id="email"
              name="email"
              type="text"
              placeholder="Email(optional)"
              fullWidth
              size="medium"
              icon={<MailIcon color={colors.icon} />}
              value={form.email}
              onChange={onChange}
              label="Email"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput
              id="country"
              name="country"
              type="text"
              placeholder="Country"
              fullWidth
              size="medium"
              value={form.country}
              onChange={onChange}
              label="Country"
            />
            <TextInput
              name="date_of_birth"
              id="date-of-birth"
              type="date"
              placeholder="Date of Birth (should match birth certificate)"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              size="medium"
              value={form.date_of_birth}
              onChange={onChange}
              label="Date of Birth"
            />
            <TextInput
              id="weight"
              type="text"
              placeholder="Weight"
              fullWidth
              size="medium"
              value={form.weight}
              onChange={onChange}
              label="Weight"
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              name="neighbourhood"
              id="city"
              type="text"
              placeholder="City/ Neighborhood"
              fullWidth
              size="medium"
              value={form.neighbourhood}
              onChange={onChange}
              label="Neighbourhood"
            />
            <TextInput
              id="height"
              name="height"
              type="text"
              placeholder="Height"
              fullWidth
              size="medium"
              value={form.height}
              onChange={onChange}
              label="Height"
            />
            <TextInput
              id="wingspan"
              type="text"
              placeholder="Wingspan"
              fullWidth
              size="medium"
              value={form.wing_span}
              onChange={onChange}
              name="wing_span"
              label="Wingspan"
            />
          </Grid>
        </Grid>

        <FieldSet title="Professional Info" />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput
              id="sport"
              type="text"
              placeholder="Sport"
              fullWidth
              size="medium"
              value={form.sport}
              onChange={onChange}
              name="sport"
              label="Sport"
            />
            <TextInput
              id="school"
              type="text"
              placeholder="School Attending"
              fullWidth
              size="medium"
              value={form.attending_school}
              onChange={onChange}
              name="attending_school"
              label="School"
            />
            <TextInput
              id="guardian"
              type="text"
              placeholder="Adult's Name (Parent/Guardian)"
              fullWidth
              size="medium"
              value={form.adult_name}
              onChange={onChange}
              name="adult_name"
              label="Guardian"
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              id="position-played"
              type="text"
              placeholder="Position Played"
              fullWidth
              size="medium"
              value={form.position_played}
              onChange={onChange}
              name="position_played"
              label="Position Played"
            />
            <TextInput
              id="club-team"
              type="text"
              placeholder="Club/Team"
              fullWidth
              size="medium"
              value={form.club_team}
              onChange={onChange}
              name="club_team"
              label="Club/Team"
            />
            <TextInput
              id="adult-contacts"
              type="text"
              placeholder="Adult contacts (Phone Number)"
              fullWidth
              size="medium"
              value={form.adult_phone_number}
              onChange={onChange}
              name="adult_phone_number"
              label="Adult contacts (Phone Number)"
            />
          </Grid>
        </Grid>

        {/* <FieldSet title="Upload the following Info" />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              id="age-verification-document"
              type="file"
              placeholder="Choose a file"
              fullWidth
              size="medium"
              InputLabelProps={{ shrink: true }}
              label="Age Verification Document (Birth Certificate, age verification form, birth notification, baptism card)"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="position-played"
              type="file"
              placeholder="Choose a file"
              fullWidth
              size="medium"
              label="Passport Photo (Headshot)"
            />
          </Grid>
        </Grid> */}

        <Grid container spacing={2}>
          <Grid item>
            <ButtonComponent
              variant="contained"
              type="submit"
              style={{
                marginTop: 20,
                marginBottom: 20,
                background: colors.primary,
              }}
            >
              Update
            </ButtonComponent>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default EditAthleteProfileComponent;
