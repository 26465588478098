import { NavigationList } from '../../models/navItem';

import {
  CreditCard as CreditCardIcon,
  Home as HomeIcon,
  Users as AthletesIcon,
  MessageCircle as MessagesIcon,
  UserCheck as ScoutIcon,
  BarChart2 as MetricsIcon,
  Bookmark as NewsIcon,
  Activity as LogsIcon,
  Settings as SettingsIcon,
} from 'react-feather';
import { ReactComponent as LogoIcon } from '../../assets/logo-black.svg';

export const scoutNavListItems: Array<NavigationList> = [
  {
    title: 'Home',
    icon: HomeIcon,
    link: '/dashboard/scout',
  },
  {
    title: 'My Athletes',
    icon: AthletesIcon,
    link: '/dashboard/scout/my-athletes',
  },
  {
    title: 'Messages',
    icon: MessagesIcon,
    link: '/dashboard/scout/messages',
  },
  {
    title: 'Account Details',
    icon: CreditCardIcon,
    link: '/dashboard/scout/account',
  },
  {
    title: 'Settings',
    icon: SettingsIcon,
    link: '/dashboard/scout/settings',
  },
];

export const adminNavListItems: Array<NavigationList> = [
  {
    title: 'Dashboard',
    icon: HomeIcon,
    link: '/dashboard/admin',
  },
  {
    title: 'Athletes',
    icon: AthletesIcon,
    link: '/dashboard/admin/athletes',
  },
  {
    title: 'Scouts',
    icon: ScoutIcon,
    link: '/dashboard/admin/scouts',
  },
  {
    title: 'Subscriptions',
    icon: CreditCardIcon,
    link: '/dashboard/admin/subscriptions',
  },
  {
    title: 'Messages',
    icon: MessagesIcon,
    link: '/dashboard/admin/messages',
  },
  {
    title: 'Metrics',
    icon: MetricsIcon,
    link: '/dashboard/admin/settings',
  },
  {
    title: 'News',
    icon: NewsIcon,
    link: '/dashboard/admin/news',
  },
  {
    title: 'Kachezwe organisation',
    icon: LogoIcon,
    link: '/dashboard/admin/kachezwe-org',
  },
  {
    title: 'Logs',
    icon: LogsIcon,
    link: '/dashboard/admin/logs',
  },
];
