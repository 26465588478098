import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';

import {
  AuthActionTypes,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  AuthenticatedUserState,
} from '../../types/auth';
import { successAlert, errorAlert } from '../alert';

const API_URL = process.env.REACT_APP_KACHEZWE_API_SERVICE_URL;

// ACTION CREATORS

export const loginUserRequest = (
  payload: AuthenticatedUserState
): AuthActionTypes => {
  return {
    type: LOGIN_USER_REQUEST,
    payload,
  };
};

export const loginUserSuccess = (
  payload: AuthenticatedUserState
): AuthActionTypes => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload,
  };
};

export const loginUserFailure = (
  payload: AuthenticatedUserState
): AuthActionTypes => {
  return {
    type: LOGIN_USER_FAILURE,
    payload,
  };
};

// METHODS

export const loginUser = (username: string, password: string) => (
  dispatch: Dispatch
) => {
  axios
    .post(API_URL + '/auth/login', {
      username,
      password,
    })
    .then((response: AxiosResponse) => {
      if (response.data.access_token) {
        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem('loggedIn', JSON.stringify(true));
        dispatch(loginUserRequest({ loggedIn: true }));
        dispatch(loginUserSuccess({ loggedIn: true, data: response.data }));
        dispatch(
          successAlert({
            alertType: 'success',
            alertMessage: 'Log in was successful',
          })
        );
      }
    })
    .catch((error: AxiosError) => {
      console.error(error.message);
      dispatch(loginUserFailure({ loggedIn: false }));
      dispatch(
        errorAlert({
          alertType: 'error',
          alertMessage: 'Unable to log in',
        })
      );
    });
};
