import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { IAthleteProfile } from '../../../../../models/athlete';
import { DashboardHeader } from '../../../../../shared/Components';
import AthleteListView from './AthleteTable';

const title: string = `New Athletes`;
const NewAthletes: React.FC<{}> = () => {
  const [athletes, setAthletes] = useState<Array<IAthleteProfile>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  return (
    <Box style={{ margin: '18px  0' }}>
      <DashboardHeader>{title}</DashboardHeader>
      <AthleteListView loading={loading} athletes={athletes} />
    </Box>
  );
};

export default NewAthletes;
