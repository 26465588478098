import React from 'react';

import Box from '@material-ui/core/Box';
import { Card, CardContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

import ButtonComponent from '../Button';

import { colors } from '../../../theme/theme';
import upgradeImage from '../../../assets/Blockchain.png';

const StyledCard = styled(Card)`
  background: #f7f9fd;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-top: 60%;
  margin-left: 16px;
  width: 200px;
  align-content: center;
`;

const textContent: string = 'Upgrade your account to access more features';
const text: string = 'Get Business Account';
const buttonContent: string = 'Upgrade Now';

const UpgradeCard: React.FC<{}> = () => {
  return (
    <StyledCard>
      <CardContent>
        <Box>
          <Box alignContent="center">
            <img src={upgradeImage} alt="Upgrade Image" />
          </Box>
          <Typography align="center">{textContent}</Typography>
          <Typography align="center" gutterBottom>
            {text}
          </Typography>

          <Box mt={2}>
            <ButtonComponent
              color="primary"
              variant="contained"
              style={{ background: colors.primary }}
            >
              {buttonContent}
            </ButtonComponent>
          </Box>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default UpgradeCard;
