import { createMuiTheme } from '@material-ui/core/styles';

export enum fontWeight {
  normal = 400,
  mediumOne = 500,
  mediumTwo = 600,
  bold = 700,
}

export enum fontFamily {
  // eslint-disable-next-line no-unused-vars
  sofiaPro = 'sofia-pro',
  // eslint-disable-next-line no-unused-vars
  heaters = 'Heaters',
}

export interface IColors {
  primary: string;
  secondary: string;
  text: string;
  backgroundOne: string;
  backgroundTwo: string;
  backgroundThree: string;
  backgroundFour: string;
  white: string;
  link: string;
  icon: string;
}

export const colors: IColors = {
  primary: '#9F56D6',
  secondary: '#FC303B',
  text: '#333333',
  backgroundOne: '#F7F9FD',
  backgroundTwo: '#333333',
  backgroundThree: '#F3F4F8',
  backgroundFour: '#F7F7F7',
  white: '#FFFFFF',
  link: '#2F80ED',
  icon: '#828282',
};

export const statsCardsColor = {
  athletesStats: colors.primary,
  recruitedStats: '#2CCC71',
  undecidedStats: '#F3A100',
  updatedProfilesStats: '#243F95',
};

export const socialIconsColor = {
  iconFalseState: colors.icon,
  iconTrueState: '#2F80ED',
};

export const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: colors.text,
      secondary: colors.white,
    },
  },
  typography: {
    fontFamily: fontFamily.sofiaPro,
    body1: {
      fontSize: 14,
      fontWeight: fontWeight.normal,
    },
    body2: {
      fontSize: 24,
      fontWeight: fontWeight.normal,
      '@media (min-width:375px)': {
        fontSize: '1rem',
      },
    },
    button: {
      fontSize: 16,
    },
    h1: {
      fontSize: 74,
      fontWeight: fontWeight.bold,
      lineHeight: '120.8%',
      '@media (max-width:375px)': {
        fontSize: '2.5rem',
      },
    },
    h2: {
      fontSize: 74,
      fontWeight: fontWeight.bold,
      lineHeight: '120.8%',
    },
    h3: {
      fontSize: 50,
      fontFamily: fontFamily.heaters,
    },
    h4: {
      fontSize: 24,
      fontWeight: fontWeight.mediumTwo,
    },
    h5: {
      fontSize: 20,
      fontWeight: fontWeight.bold,
    },
    h6: {
      fontSize: 14,
      fontWeight: fontWeight.bold,
    },
  },
});
