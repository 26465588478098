import styled from 'styled-components';
import { Card, Grid } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

export const StyledCard = styled(Card)`
  padding: 30px 6px;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
`;

export const header: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'end',
};

export const StyledGrid = styled(Grid)`
  :not(:last-of-type) {
    border-right: 1px solid #828282;
  }
  padding: 0 24px;
  /* margin: 30px 0; */
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(243, 244, 248, 0.3)',
      },
    },
    customWidth: {
      maxWidth: 50,
      paddingLeft: 23,
    },
  })
);
