import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  Badge,
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from '@material-ui/core';
import {
  Trash as DeleteIcon,
  MessageCircle as MessageIcon,
  Edit as EditIcon,
} from 'react-feather';

import { CheckboxInput } from '../../../../../shared/Components/Form/Input';
import {
  // UpdatedButton,
  VerifiedButton,
  RatingsComponent,
} from '../../../../../shared/Components';
import { VerifiedIcon } from '../../../../../shared/Components/Icons';
import { avatar, buttonBackground, customWidth, useStyles } from './styles';
import { colors } from '../../../../../theme/theme';

import { IAthleteProfile } from '../../../../../models/athlete';

const buttonContent: string = `Updated`;
const buttonText: string = `Request verification`;

const WEB_URL = process.env.REACT_APP_KACHEZWE_WEB_URL;

interface IProps {
  athletes: Array<IAthleteProfile>;
}

const TableComponent: React.FC<IProps> = (props) => {
  const { athletes } = props;
  const history = useHistory();
  const classes = useStyles();

  /**
   * Opens editor page for athlete's profile
   * @param athleteUserId number
   */
  const openProfileEditor = (athleteUserId: number): void => {
    if (athleteUserId) history.push(`/dashboard/edit-athlete/${athleteUserId}`);
  };

  return (
    <Box minWidth={1050}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="default" style={customWidth}>
              <CheckboxInput label="" />
            </TableCell>
            <TableCell>
              <Typography variant="h5">Athlete</Typography>
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <Typography variant="h5">Rating</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5">Current Rank</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5">Position</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5">Country</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5">Height</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h5">Action</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {athletes.map((athlete, index) => (
            <TableRow key={index} className={classes.row}>
              <TableCell style={customWidth}>
                <CheckboxInput label="" />
              </TableCell>
              <TableCell>
                <Box display="flex" alignItems="center">
                  <>
                    <Badge
                      style={avatar}
                      overlap="circle"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      badgeContent={athlete.is_verified && <VerifiedIcon />}
                    >
                      <Avatar
                        src={`${WEB_URL}${athlete.profile_photo}`}
                      ></Avatar>
                    </Badge>
                  </>

                  <Link to={`/dashboard/athlete-profile/${athlete.user_id}`}>
                    <Typography>{athlete.name}</Typography>
                  </Link>
                  {/* {athlete.isUpdated && (
                          <UpdatedButton
                            variant="contained"
                            style={buttonBackground}
                          >
                            {buttonContent}
                          </UpdatedButton>
                        )} */}
                </Box>
              </TableCell>
              <TableCell>
                {/* {athlete.contact_is_made && (
                  <UpdatedButton variant="contained" style={buttonBackground}>
                    {buttonContent}
                  </UpdatedButton>
                )} */}
              </TableCell>

              <TableCell>
                <RatingsComponent rating={athlete.player_rating} />
              </TableCell>
              <TableCell>
                <Typography>{athlete.player_ranking}</Typography>
              </TableCell>
              <TableCell variant="body">
                <Typography>{athlete.position_played}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{athlete.country}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{athlete.height}</Typography>
              </TableCell>
              <TableCell align="right">
                <Box alignItems="center">
                  {athlete.is_verified && (
                    <VerifiedButton variant="contained" color="secondary">
                      {buttonText}
                    </VerifiedButton>
                  )}
                  <IconButton
                    onClick={() => openProfileEditor(athlete.user_id!)}
                    aria-label="edit-profile"
                  >
                    <EditIcon color={colors.primary} />
                  </IconButton>
                  <IconButton aria-label="message">
                    <MessageIcon color="#2F80ED" />
                  </IconButton>

                  <IconButton aria-label="delete">
                    <DeleteIcon color="#FC303B" />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default TableComponent;
