import { fontFamily, fontWeight } from './../../../../theme/theme';
import styled from 'styled-components';
import { colors } from '../../../../theme/theme';

import { makeStyles } from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { createStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(243, 244, 248, 0.3)',
      },
    },
    customWidth: {
      maxWidth: 50,
      paddingLeft: 23,
    },
  })
);

export const containerStyles: React.CSSProperties = {
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.07)',
  borderRadius: 5,
};

export const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '10px 0',
    height: '40px',
    fontSize: 15,
    fontWeight: fontWeight.normal,
    fontFamily: fontFamily.sofiaPro,
    verticalAlign: 'initial',

    '&:first-of-type': {
      paddingLeft: 20,
    },
  },
})(MuiTableCell);

export const StyledGrid = styled(Grid)`
  @media (min-width: 960px) {
    :not(:last-of-type) {
      border-right: 1px solid #828282;
    }
    :not(:first-of-type) {
      padding: 0 24px;
    }
  }
`;
export const tableStyles: React.CSSProperties = {
  width: '60%',
};

export const titleStyles: React.CSSProperties = {
  flexGrow: 1,
  fontWeight: fontWeight.mediumTwo,
};

export const StyledCard = styled(Card)`
  padding: 30px 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
`;

export const header: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'end',
};

export const buttonStyles: React.CSSProperties = {
  marginBottom: 20,
  marginTop: 20,
  background: colors.primary,
};
