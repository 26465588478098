import { Box } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { PropsWithChildren } from 'react';
import SimpleBarReact from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { useStyles } from './styles';

interface IScrollBarProps {
  className?: string;
}

const Scrollbar: React.FC<PropsWithChildren<IScrollBarProps>> = (props) => {
  const { className, children, ...rest } = props;
  const classes = useStyles();
  return (
    <>
      <Box className={classes.root}>
        <SimpleBarReact
          timeout={500}
          clickOnTrack={false}
          {...rest}
          className={clsx(classes.scroll, className)}
        >
          {children}
        </SimpleBarReact>
      </Box>
    </>
  );
};

Scrollbar.propTypes = {
  children: PropTypes.any,
};

export default Scrollbar;
