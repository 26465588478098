import { Avatar, Box, Input, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Send as SendIcon } from 'react-feather';
import { ButtonComponent } from '../../../../../../shared/Components';
import { colors } from '../../../../../../theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 56,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
  },
  input: {
    height: 85,
    width: 580,
    background: colors.white,
    border: '1px solid #F2F2F2',
    fontSize: 16,
    borderRadius: 5,
    fontStyle: 'italic',
    padding: '15px 0 0 15px',
    alignItems: 'start',
    margin: '20px 0',
  },
}));

const MessageInput: React.FC<{}> = () => {
  const classes = useStyles();
  const [message, setMessage] = useState('');

  const handleSend = () => {
    if (!message) {
      return '';
    }
    setMessage('');
  };

  return (
    <Box className={classes.root}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar></Avatar>
          <Box style={{ paddingLeft: 15, fontSize: 16 }}>
            <Typography>George Carmello</Typography>
          </Box>
        </Box>
        <Input
          fullWidth
          disableUnderline
          className={classes.input}
          placeholder="Reply to John"
        />
        <ButtonComponent
          variant="contained"
          color="primary"
          startIcon={<SendIcon size="17" />}
          style={{ width: 124, marginBottom: 24 }}
          onClick={handleSend}
        >
          Send
        </ButtonComponent>
      </Box>
    </Box>
  );
};

export default MessageInput;
