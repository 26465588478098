import React from 'react';

import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { colors, fontFamily, fontWeight } from '../../../../../../theme/theme';
export const avatar: React.CSSProperties = {
  marginRight: 15,
};

export const buttonBackground: React.CSSProperties = {
  background: 'rgba(5, 198, 64, 0.2)',
};

export const customWidth: React.CSSProperties = {
  maxWidth: 50,
  paddingLeft: 23,
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(243, 244, 248, 0.3)',
      },
    },
    tableButton: {
      color: colors.link,
      fontSize: 13,
      textTransform: 'none',
    },
  })
);
