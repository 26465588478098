import featuredPlayerImage from '../../assets/featured-player.png';
import { FeaturedPlayer } from '../../models/frontPage';

export const featuredPlayers: Array<FeaturedPlayer> = [
  {
    playerName: 'David Muselu',
    playerSportType: 'Basketball',
    playerImage: featuredPlayerImage,
    playerDescription:
      '“Kachezwe Provides Subscribers a database of all of the unseen talent on the African Continent, the ability to communicate with them, advise them on what they should work on and provide instruction.”',
  },
  {
    playerName: 'David Muselu',
    playerSportType: 'Basketball',
    playerImage: featuredPlayerImage,
    playerDescription:
      '“Kachezwe Provides Subscribers a database of all of the unseen talent on the African Continent, the ability to communicate with them, advise them on what they should work on and provide instruction.”',
  },
  {
    playerName: 'David Muselu',
    playerSportType: 'Basketball',
    playerImage: featuredPlayerImage,
    playerDescription:
      '“Kachezwe Provides Subscribers a database of all of the unseen talent on the African Continent, the ability to communicate with them, advise them on what they should work on and provide instruction.”',
  },
  {
    playerName: 'David Muselu',
    playerSportType: 'Basketball',
    playerImage: featuredPlayerImage,
    playerDescription:
      '“Kachezwe Provides Subscribers a database of all of the unseen talent on the African Continent, the ability to communicate with them, advise them on what they should work on and provide instruction.”',
  },
];
