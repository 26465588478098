import React, { useState, useEffect } from 'react';
import { Button, Box } from '@material-ui/core';

import { colors } from '../../../../theme/theme';

const PaginationButton: React.FC<{}> = (props) => {
  const buttonStyles: React.CSSProperties = {
    color: colors.link,
    textTransform: 'capitalize',
  };
  return (
    <>
      {/* Pagination Component */}
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button size="small" variant="text" style={buttonStyles}>
          See More
        </Button>
      </Box>
      {/* End pagination component */}
    </>
  );
};

export default PaginationButton;
