import React from 'react';

import { IconButton } from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { colors } from '../../../../theme/theme';

const HeaderSearch: React.FC<{}> = () => {
  const handleOnClick = () => {};
  return (
    <IconButton edge="start" aria-label="search" onClick={handleOnClick}>
      <SearchIcon color={colors.text} />
    </IconButton>
  );
};

export default HeaderSearch;
