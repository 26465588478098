import React from 'react';

import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

import { colors, fontWeight } from '../../../../theme/theme';

interface ITextInputProps extends StandardTextFieldProps {
  icon?: any;
}

const textInputStyles: React.CSSProperties = {
  marginBottom: 30,
};

const textInputPropsStyles: React.CSSProperties = {
  border: '1px solid #F2F2F2',
  fontSize: 16,
  borderRadius: 5,
  backgroundColor: colors.white,
  padding: 15,
};
const AdornmentComponent = (props: any) => (
  <InputAdornment position="start">
    <Icon color="primary">{props.icon}</Icon>
  </InputAdornment>
);

const TextInput: React.FC<ITextInputProps> = (props) => {
  const { icon, ...rest } = props;

  return (
    <TextField
      {...rest}
      style={textInputStyles}
      autoComplete="off"
      margin="normal"
      InputProps={{
        style: textInputPropsStyles,
        startAdornment: icon ? <AdornmentComponent icon={icon} /> : '',
        disableUnderline: true,
      }}
      InputLabelProps={{
        shrink: true,
        color: 'primary',
        variant: 'standard',
        style: {
          color: colors.text,
          fontWeight: fontWeight.normal,
          fontSize: 16,
        },
      }}
    />
  );
};

export default TextInput;
