import Container from '@material-ui/core/Container';
import React from 'react';
import { Link } from 'react-router-dom';
import { ContentContainer, ErrorText } from './styles';

const UnauthorisedPage: React.FC<{}> = () => {
  return (
    <Container>
      <ContentContainer>
        <ErrorText variant="h2">403: Access Denied</ErrorText>

        <Link to="/login">Back to login page</Link>
      </ContentContainer>
    </Container>
  );
};

export default UnauthorisedPage;
