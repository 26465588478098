import React, { useEffect, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { LinkComponent } from '../../shared/Components';
import { PlayerCard, VideoComponent } from '../../shared/Components';
import { fontWeight, colors } from '../../theme/theme';
import UserService from '../../shared/services/user.service';

const titleStyles: React.CSSProperties = {
  marginTop: 20,
  marginBottom: 20,
  fontWeight: fontWeight.bold,
};

const Content: React.FC<{}> = () => {
  const [athletes, setAthletes] = useState<any>([]);

  // fetch all athletes
  useEffect(() => {
    UserService.getAthletes()
      .then((response) => {
        setAthletes(response.data);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.error(resMessage);
      });
  }, []);

  console.log(athletes);

  return (
    <Box style={{ marginLeft: 180, marginRight: 180, marginBottom: 50 }}>
      {/* <Typography variant="h4" style={titleStyles}>
        Recently Recruited
      </Typography>

      <Grid container spacing={2}>
        {recentlyRecruitedPlayers.map((player, playerIndex) => (
          <Grid key={playerIndex} item xs={3}>
            <PlayerCard key={playerIndex} athlete={player} />
          </Grid>
        ))}
      </Grid> */}

      <Typography variant="h4" style={titleStyles}>
        Top Athletes
      </Typography>

      <Grid container spacing={2}>
        {athletes.map((athlete: any, index: number) => (
          <Grid key={index} item xs={3}>
            <PlayerCard key={index} athlete={athlete} />
          </Grid>
        ))}
      </Grid>

      {/* <Typography variant="h4" style={titleStyles}>
        Trending Videos
      </Typography>

      <Grid container spacing={2}>
        {trendingVideos.map((video, videoIndex) => (
          <Grid key={videoIndex} item lg={6} xs={12}>
            <VideoComponent
              videoTitle={video.videoTitle}
              videoSource={video.videoSource}
              videoPoster={video.videoPoster}
            />
          </Grid>
        ))}
      </Grid> */}

      <LinkComponent
        style={{
          fontSize: 20,
          fontWeight: fontWeight.normal,
          textAlign: 'right',
        }}
      >
        See More
      </LinkComponent>
      {/* <Typography variant="h4" style={titleStyles}>
        Popular Videos
      </Typography> */}
    </Box>
  );
};

export default Content;
