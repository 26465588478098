import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import { Eye as ViewIcon } from 'react-feather';

import { CheckboxInput } from '../../../../../../shared/Components/Form/Input';
import {
  TableProfileAvatar,
  TableWrapper,
  Loader,
} from '../../../../../../shared/Components';
import { useStyles } from '../styles';

import { IScoutAccount } from '../../../../../../models/user';

const WEB_URL = process.env.REACT_APP_KACHEZWE_WEB_URL;

interface IProps {
  scouts: Array<IScoutAccount>;
  loading: boolean;
}

const TableComponent: React.FC<IProps> = (props) => {
  const { scouts, loading } = props;
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <TableWrapper>
        <TableHead>
          <TableRow>
            <TableCell padding="default" className={classes.customWidth}>
              <CheckboxInput label="" />
            </TableCell>
            <TableCell>Scout</TableCell>
            <TableCell>Country</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>School/Company</TableCell>
            <TableCell>Subscription</TableCell>
            <TableCell>Amount($)</TableCell>
            <TableCell>Payment Status</TableCell>
            <TableCell>Date Paid</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>

        {loading ? (
          <Loader />
        ) : (
          <TableBody>
            {scouts.map((scout, index) => (
              <TableRow key={index} className={classes.row}>
                <TableCell className={classes.customWidth}>
                  <CheckboxInput label="" />
                </TableCell>
                <TableCell>
                  <TableProfileAvatar
                    image={`${WEB_URL}${scout.profile_photo}`}
                    name={scout.name}
                    verified={scout.is_verified}
                  />
                </TableCell>

                <TableCell>{scout.country}</TableCell>
                <TableCell>{scout.role}</TableCell>
                <TableCell>{scout.work_company}</TableCell>
                <TableCell>{scout.subscription}</TableCell>
                <TableCell>{scout.amount}</TableCell>
                <TableCell variant="body">{scout.payment_status}</TableCell>
                <TableCell>
                  {moment(scout.date_paid).format('MM/DD/YYYY')}
                </TableCell>
                <TableCell>
                  <IconButton>
                    <ViewIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </TableWrapper>
    </>
  );
};

export default TableComponent;
