import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { TextInput } from '../Input';
import ButtonComponent from '../../Button';
import LinkComponent from '../../Link';

import { validationStyling } from '../../styles';
import { LoginModel } from '../../../../models/auth';
import { loginUser } from '../../../../shared/store/actions/auth/login';
import { AlertState } from '../../../../shared/store/types/alert';
import { AuthenticatedState } from '../../../../shared/store/types/auth';

const LoginFormComponent: React.FC<{}> = () => {
  const { register, handleSubmit, errors } = useForm<Partial<LoginModel>>();
  const dispatch = useDispatch();
  const history = useHistory();

  const [form, setFormState] = useState<LoginModel>({
    username: '',
    password: '',
  });

  const { loggedIn } = useSelector(
    (state: AuthenticatedState) => state.authentication
  );
  const { alertMessage, alertType } = useSelector(
    (state: AlertState) => state.alert
  );

  const onSubmit = async () => {
    if (!loggedIn) {
      await dispatch(loginUser(form.username, form.password));
    }
  };

  const onChange = (event: any) => {
    setFormState({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  if (loggedIn) {
    history.push('/news-feed');
  }

  return (
    <>
      {alertType === 'error' && <Alert color={alertType}>{alertMessage}</Alert>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} xs={12}>
            <TextInput
              id="username"
              type="text"
              placeholder="Username"
              fullWidth
              size="medium"
              icon="mail"
              name="username"
              onChange={onChange}
              value={form.username}
              autoFocus
              inputRef={register({ required: true })}
            />
            {errors.username && (
              <Typography variant="body1" style={validationStyling}>
                This field is required
              </Typography>
            )}
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <TextInput
              id="password"
              type="password"
              placeholder="Password"
              fullWidth
              size="medium"
              icon="lock"
              name="password"
              onChange={onChange}
              value={form.password}
              inputRef={register({ required: true })}
            />
            {errors.password && (
              <Typography variant="body1" style={validationStyling}>
                This field is required
              </Typography>
            )}
          </Grid>
        </Grid>
        <ButtonComponent
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Sign In
        </ButtonComponent>
      </form>
      <Typography style={{ fontSize: 16 }} variant="body1">
        Don&apos;t have an account?{' '}
        {<LinkComponent href="#">Sign Up Here</LinkComponent>}
      </Typography>
    </>
  );
};

export default LoginFormComponent;
