import React from 'react';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import { colors } from '../../theme/theme';
import LoginFormComponent from '../../shared/Components/Form/Login';
import { LogoComponent } from '../../shared/Components';
import './style.scss';

const LoginPage: React.FC<{}> = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justify="center"
      classes={{ root: 'login-form-container' }}
    >
      <Grid
        item
        lg={6}
        md={6}
        xs={12}
        classes={{ root: 'login-form' }}
        style={{ backgroundColor: colors.backgroundOne }}
      >
        <LogoComponent style={{ textAlign: 'center' }} />
        <Typography variant="h5" align="center">
          Sign in to access your account
        </Typography>
        <LoginFormComponent />
      </Grid>
    </Grid>
  );
};

export default LoginPage;
