import React, { FC } from 'react';
import styled from 'styled-components';

import IconAvatar from '../../Avatar';

import CreditCardIcon from '../../Icons/CreditCardIcon';

const StyledAvatar = styled(IconAvatar)`
  background-color: rgba(252, 48, 59, 0.2);
`;

const color = '#FC303B';

const OverduePaymentAvatar: React.FC<{}> = () => {
  return (
    <StyledAvatar>
      <CreditCardIcon iconColor={color} />
    </StyledAvatar>
  );
};

export default OverduePaymentAvatar;
