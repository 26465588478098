import React, { FC } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Box, { BoxProps } from '@material-ui/core/Box';

const FooterComponent: FC<React.PropsWithChildren<BoxProps>> = (props) => {
  const { children, ...rest } = props;

  return (
    <Box {...rest}>
      <Grid container>{children}</Grid>
    </Box>
  );
};

FooterComponent.propTypes = {
  children: PropTypes.any,
};

export default FooterComponent;
