import React from 'react';

import { UserCheck } from 'react-feather';

interface IScoutsProps {
  iconColor: string;
}

const ScoutIcon: React.FC<IScoutsProps> = (props) => {
  const { iconColor } = props;
  return <UserCheck style={{ color: iconColor }} />;
};

export default ScoutIcon;
