import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { DashboardLayout } from '../../shared/Layout';

import { Header } from '../../shared/Components';
import AdminSidebar from './Sidebar';
import AdminDashboardContent from './AdminDashboardContent';
import HeaderSearch from '../../shared/Components/Header/HeaderSearch';

const drawerWidth = 290;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    app: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    main: {
      flex: 1,
    },
  })
);

const AdminDashboardPage: React.FC<{}> = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const { path } = useRouteMatch();

  const classes = useStyles();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <DashboardLayout>
      <nav className={classes.drawer}>
        <AdminSidebar />
      </nav>
      <div className={classes.app}>
        <Header search={<HeaderSearch />} onDrawerToggle={handleDrawerToggle} />
        <main className={classes.main}>
          <AdminDashboardContent path={path} />
        </main>
      </div>
    </DashboardLayout>
  );
};

export default AdminDashboardPage;
