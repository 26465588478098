import styled from 'styled-components';

import Box from '@material-ui/core/Box';

export const LogoSectionStyled = styled(Box)`
  margin-left: 20px;
`;

export const NavBarContainerStyled = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 10px;
`;

export const LinksSectionStyled = styled(Box)`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const ButtonsBoxStyled = styled(Box)`
  margin-left: 20px;
  margin-right: 20px;
`;
