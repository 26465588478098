import React from 'react';

import ArrowForward from '@material-ui/icons/ArrowForward';

import { colors } from '../../../../theme/theme';
import { TitleHeader } from '../../../../shared/Components';
import { BoxStyled, LinkStyled } from './style';

const DiscoverTalentLink: React.FC<{}> = () => {
  return (
    <BoxStyled>
      <LinkStyled color="textSecondary">
        <TitleHeader color="textSecondary">Discover Our Talent</TitleHeader>
      </LinkStyled>
      <LinkStyled>
        <ArrowForward
          fontSize="small"
          style={{ color: colors.white, marginLeft: 40 }}
        />
      </LinkStyled>
    </BoxStyled>
  );
};

export default DiscoverTalentLink;
