import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  TableContainer,
  TablePagination,
  Paper,
  Toolbar,
  ButtonGroup,
  IconButton,
} from '@material-ui/core';

import { Download as DownloadIcon } from 'react-feather';
import AppBar from '@material-ui/core/AppBar';
import { colors } from '../../../../../theme/theme';
import TableComponent from './Table';
import { containerStyles, useStyles } from './styles';
import {
  FilterButton,
  Loader,
  SearchButton,
  TableButtonGroup,
} from '../../../../../shared/Components';

import { IAthleteProfile } from '../../../../../models/athlete';

interface IProps {
  loading: boolean;
  athletes: Array<IAthleteProfile>;
}

const AthletesTable: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const { loading, athletes } = props;
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <PerfectScrollbar>
        <TableContainer style={containerStyles} component={Paper}>
          {/* AppBar Component */}
          <AppBar
            position="static"
            color="default"
            className={classes.appBarStyles}
          >
            <Toolbar disableGutters>
              <div className={classes.filter}>
                <FilterButton />
              </div>
              <SearchButton />
              <div>
                <TableButtonGroup />
              </div>
              <IconButton>
                <DownloadIcon color={colors.icon} />
              </IconButton>
            </Toolbar>
          </AppBar>
          {/* End AppBar Component */}

          {loading ? <Loader /> : <TableComponent athletes={athletes} />}

          {/* Pagination Component */}
          {/* <TablePagination
            component="div"
            count={100}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          /> */}
          {/* End pagination component */}
        </TableContainer>
      </PerfectScrollbar>
    </>
  );
};

AthletesTable.propTypes = {
  athletes: PropTypes.array.isRequired,
};

export default AthletesTable;
