import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import Toolbar from '@material-ui/core/Toolbar';

import { appBarStyles, useStyles } from './style';

import HeaderNotification from './HeaderNotification';
import HeaderProfile from './HeaderProfile';
import HeaderSearch from './HeaderSearch';

interface HeaderProps {
  onDrawerToggle: () => void;
  search?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = (props) => {
  const { onDrawerToggle, search } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBar
        className={classes.appBarStyles}
        position="relative"
        elevation={0}
      >
        <Toolbar disableGutters>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            {search}
            <Grid item>
              <HeaderNotification />
            </Grid>
            <HeaderProfile />
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
