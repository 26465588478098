import React, { FC } from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';

import MainHeader from './Header';
import { AthleteProfileForm } from '../../shared/Components/Form/Profile';
import headerImage from '../../assets/athlete-profile-header.png';

import {
  HeaderComponent,
  ContentComponent,
} from '../../shared/Layout/PublicPage';
import { NavigationBar, PublicFooterComponent } from '../../shared/Components';

const AthleteProfileFormView: FC = () => {
  return (
    <>
      <Container>
        <HeaderComponent>
          <NavigationBar></NavigationBar>
        </HeaderComponent>
        <ContentComponent>
          <MainHeader imageUrl={headerImage} />
          <AthleteProfileForm />
        </ContentComponent>
        <PublicFooterComponent />
      </Container>
    </>
  );
};

AthleteProfileForm.propTypes = {
  children: PropTypes.any,
};

export default AthleteProfileFormView;
