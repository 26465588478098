import React, { useState, useEffect } from 'react';
import { SubscriptionSummary, SubscriptionListView } from './Content';
import { IScoutAccount } from '../../../../models/user';

const SubscriptionPage: React.FC<{}> = () => {
  const [scouts, setScouts] = useState<Array<IScoutAccount>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    //
  });

  return (
    <>
      <SubscriptionSummary />
      <SubscriptionListView loading={loading} scouts={scouts} />
    </>
  );
};

export default SubscriptionPage;
