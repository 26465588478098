import React, { FC } from 'react';

import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';

import { colors } from '../../../theme/theme';

interface IFooterProps {
  menuItems: Array<string>;
}

const MenuLinkStyled = styled(Link)`
  &: hover {
    color: ${colors.white};
  }
`;

const FooterMenu: React.FC<IFooterProps> = (props) => {
  const { menuItems } = props;
  return (
    <List>
      {menuItems.map((menuItem, index) => (
        <ListItem key={index} className="footer-list-item">
          <ListItemText>
            <MenuLinkStyled color="textSecondary">{menuItem}</MenuLinkStyled>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default FooterMenu;
