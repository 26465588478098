import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const titleStyled: React.CSSProperties = {
  borderBottom: '1px solid #C4C4C4',
  marginBottom: 10,
  paddingBottom: 20,
};

const AthleteDetails: React.FC<{}> = () => {
  return (
    <Box>
      <Typography variant="h5" style={titleStyled}>
        Images/Videos
      </Typography>
    </Box>
  );
};

export default AthleteDetails;
