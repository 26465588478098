import React, { PropsWithChildren } from 'react';

import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { fontWeight, fontFamily } from '../../../theme/theme';

const titleStyles: React.CSSProperties = {
  marginBottom: 20,
  fontWeight: fontWeight.normal,
  fontFamily: fontFamily.sofiaPro,
  fontSize: 16,
};
const DashboardHeader: React.FC<PropsWithChildren<TypographyProps>> = (
  props
) => {
  const { children, ...rest } = props;

  return (
    <Typography style={titleStyles} {...rest} variant="h5">
      {children}
    </Typography>
  );
};

export default DashboardHeader;
