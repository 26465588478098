import { colors, fontWeight } from '../../../../theme/theme';
import { withStyles, Table } from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableContainer from '@material-ui/core/TableContainer';

import styled from 'styled-components';
import Link from '@material-ui/core/Link';

export const TableCell = withStyles({
  root: {
    padding: '5px 30px',
    height: '40px',
    borderBottom: '1px solid #F3F4F8',
    verticalAlign: 'center',
    '&:first-child': {
      width: 220,
      fontWeight: fontWeight.mediumTwo,
      fontSize: 13,
    },
    '&:last-child': {
      fontSize: 16,
      fontWeight: fontWeight.normal,
    },
  },
})(MuiTableCell);

export const TableRow = withStyles({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: 'rgba(243, 244, 248, 0.3)',
    },
  },
})(MuiTableRow);

export const TableContainer = withStyles({
  root: {
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.07)',
    borderRadius: 5,
    backgroundColor: colors.white,
    marginBottom: 20,
    '&:last-child': {
      marginBottom: 30,
    },
  },
})(MuiTableContainer);

export const CustomTable = styled(Table)`
  box-shadow: '0px 2px 6px rgba(0, 0, 0, 0.07)';
  background-color: ${colors.white};
  border-radius: 5;
`;

export const StyledLink = styled(Link)`
  color: ${colors.secondary};
  cursor: pointer;
`;

export const listItemStyle: React.CSSProperties = {
  flexDirection: 'column',
  alignItems: 'start',
  paddingLeft: '30px',
};
