import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from '../ErrorPages/NotFoundPage';
import DetailsPage from './views/AccountDetails';
import AddAthleteComponent from './views/AddAthlete';
import AthleteProfileDashboard from './views/AthleteProfile';
import AthletesPage from './views/Athletes';
import EditAthleteComponent from './views/EditAthlete';
import Home from './views/HomePage/index';
import MessageView from './views/MessageView';
import SettingsPage from './views/SettingsPage';

interface IProps {
  path: string;
}

const DashboardContent: FC<IProps> = ({ path }: IProps) => {
  return (
    <div>
      <Switch>
        <Route exact path={path} component={Home} />
        <Route path={`${path}/my-athletes`} component={AthletesPage} />
        <Route path={`${path}/add-athlete`} component={AddAthleteComponent} />
        <Route
          path={`${path}/edit-athlete/:id`}
          component={EditAthleteComponent}
        />
        <Route
          path={`${path}/athlete-profile/:id`}
          component={AthleteProfileDashboard}
        />
        <Route path={`${path}/settings`} component={SettingsPage} />
        <Route path={`${path}/messages`} component={MessageView} />
        <Route path={`${path}/account`} component={DetailsPage} />
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </div>
  );
};

export default DashboardContent;
