import React, { FC } from 'react';
import PropTypes from 'prop-types';

import { ButtonProps } from '@material-ui/core/Button';

import ButtonComponent from '../Button';
import { colors, fontWeight } from '../../../theme/theme';

import { ReactComponent as DownloadIcon } from '../../../assets/downloadIcon.svg';

const buttonStyles: React.CSSProperties = {
  background: 'rgba(30, 135, 240, 0.2)',
  width: '80%',
  height: 45,
  paddingRight: 3,
  paddingLeft: 20,
  justifyContent: 'space-between',
  border: '1px solid #2F80ED',
  borderStyle: 'dashed',
  marginTop: 20,
  fontWeight: fontWeight.mediumOne,
  color: colors.link,
  fontSize: 16,
  lineHeight: 1,
};

const DownloadButton: FC<React.PropsWithChildren<ButtonProps>> = (props) => {
  const { children, ...rest } = props;
  return (
    <ButtonComponent
      {...rest}
      style={buttonStyles}
      variant="contained"
      endIcon={<DownloadIcon />}
    >
      {children}
    </ButtonComponent>
  );
};

DownloadButton.propTypes = {
  children: PropTypes.any,
};

export default DownloadButton;
