import React, { FC } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import FooterMenu from './FooterMenu';
import FooterBottom from './FooterBottom';
import SocialMedia from '../SocialMedia';
import { FooterComponentStyled } from './styles';
import './index.scss';

const accountMenu = ['Log in', 'My Account'];
const companyMenu = [
  'About',
  'Careers',
  'Rankings',
  'Events',
  'Blog',
  'Contact Us',
];
const kachezweMenu = [
  'Basketball',
  'Football',
  'Basketball Girls',
  'Football Girls',
];
const contactItems = [
  '+254 716909815',
  'Nairobi',
  'Fedha Plaza',
  'Westlands, Nairobi',
];

const PublicFooterComponent: FC<{}> = () => {
  return (
    <FooterComponentStyled>
      <Grid item xs={12}>
        <Typography className="footer-logo" variant="h3" color="textSecondary">
          KACHEZWE
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} xs={6}>
        <Typography variant="body1" color="textSecondary">
          info@kachezwe.com
        </Typography>
        <SocialMedia className="footer-social-media-icons" />
        <List>
          {contactItems.map((contactItem, index) => (
            <ListItem key={index} className="footer-list-item">
              <ListItemText>{contactItem}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item lg={3} md={3} xs={6}>
        <Typography
          variant="h4"
          color="textSecondary"
          className="footer-menu-title"
        >
          Kachezwe
        </Typography>
        <FooterMenu menuItems={kachezweMenu} />
      </Grid>
      <Grid item lg={3} md={3} xs={6}>
        <Typography
          variant="h4"
          color="textSecondary"
          className="footer-menu-title"
        >
          Company
        </Typography>
        <FooterMenu menuItems={companyMenu} />
      </Grid>
      <Grid item lg={3} md={3} xs={6}>
        <Typography
          variant="h4"
          color="textSecondary"
          className="footer-menu-title"
        >
          Account
        </Typography>
        <FooterMenu menuItems={accountMenu} />
      </Grid>

      <Grid item xs={12}>
        <FooterBottom />
      </Grid>
    </FooterComponentStyled>
  );
};

export default PublicFooterComponent;
