import React, { FC } from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';

import {
  HeaderComponent,
  ContentComponent,
} from '../../shared/Layout/PublicPage';
import { NavigationBar, PublicFooterComponent } from '../../shared/Components';
import {
  MainHeader,
  ContentSectionOne,
  DiscoverTalentLink,
  BeDiscoveredSection,
  FeaturedVideos,
  FeaturedPlayers,
} from './Content';

const HomePage: FC = () => {
  return (
    <>
      <Container maxWidth={false} disableGutters={true}>
        <HeaderComponent>
          <NavigationBar></NavigationBar>
        </HeaderComponent>
        <ContentComponent>
          <MainHeader />
          <ContentSectionOne />
          <BeDiscoveredSection />
          <FeaturedVideos />
          <FeaturedPlayers />
          <DiscoverTalentLink />
        </ContentComponent>
        <PublicFooterComponent />
      </Container>
    </>
  );
};

HomePage.propTypes = {
  children: PropTypes.any,
};

export default HomePage;
