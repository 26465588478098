import { colors, fontFamily, fontWeight } from './../../../../theme/theme';
import { CSSProperties } from 'react';

import { makeStyles } from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiGrid from '@material-ui/core/Grid';
import { createStyles, withStyles } from '@material-ui/core/styles';

export const headerBottomSectionStyled: CSSProperties = {
  bottom: 50,
  position: 'absolute',
  width: '100%',
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const profileImageStyle: CSSProperties = {
  maxWidth: '100%',
  maxHeight: '100%',
  borderRadius: '50%',
  width: 100,
  height: 100,
};

export const useStyles = makeStyles(() =>
  createStyles({
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(243, 244, 248, 0.3)',
      },
    },
    customWidth: {
      maxWidth: 50,
      paddingLeft: 23,
    },
  })
);

export const containerStyles: React.CSSProperties = {
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.07)',
  borderRadius: 5,
};

export const chipStyles: React.CSSProperties = {
  backgroundColor: 'rgba(252,48,59,0.2)',
  borderRadius: 5,
  color: colors.secondary,
};

export const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '5px 0',
    height: '35px',
    fontSize: 15,
    fontWeight: fontWeight.normal,
    fontFamily: fontFamily.sofiaPro,
    verticalAlign: 'initial',
  },
})(MuiTableCell);

export const Grid = withStyles({
  container: {
    borderBottom: '1px solid #F3F4F8',
    background: 'rgba(243, 244, 248, 0.3)',

    '&:nth-child(1)': {
      background: 'none',
      paddingTop: 20,
    },
    '&:nth-child(3)': {
      paddingBottom: 30,
    },
  },

  item: {
    '&:not(:last-of-type)': {
      borderRight: '1px solid #F3F4F8',
      paddingLeft: 20,
      paddingBottom: 20,
    },
    '&:last-of-type': {
      paddingLeft: 20,
    },
  },
})(MuiGrid);

export const tableStyles: React.CSSProperties = {
  width: '60%',
};

export const titleStyles: React.CSSProperties = {
  flexGrow: 1,
  fontWeight: fontWeight.mediumTwo,
};

export const approveButton: React.CSSProperties = {
  marginTop: 20,
  marginBottom: 20,
  marginRight: 20,
  background: '#2F80ED',
};

export const suspendButton: React.CSSProperties = {
  marginTop: 20,
  marginBottom: 20,
  background: colors.primary,
};
