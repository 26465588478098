import React, { useState } from 'react';
import { Table, TableBody } from '@material-ui/core';

import { TableCell, TableRow, StyledLink, TableContainer } from '../styles';
import {
  LinkComponent,
  DashboardHeader,
} from '../../../../../shared/Components';
import { IScoutAccount } from '../../../../../models/user';

const DetailsTable: React.FC<{}> = () => {
  const [account, setAccount] = useState<IScoutAccount>({} as IScoutAccount);

  return (
    <>
      <DashboardHeader>Account Details</DashboardHeader>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Status: </TableCell>
              <TableCell>{account.account_status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Start date: </TableCell>
              <TableCell>{account.account_creation_date}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last order date: </TableCell>
              <TableCell>{account.last_order_date}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Next payment date: </TableCell>
              <TableCell>{account.next_payment_date}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Payment: </TableCell>
              <TableCell>{account.payment_details}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Actions</TableCell>
              <TableCell>
                <LinkComponent>Change Payment</LinkComponent> |{' '}
                <StyledLink>Cancel Subscription</StyledLink>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DetailsTable;
