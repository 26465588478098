import { IAlert } from '../../../../models/alert';
import { ALERT_CLEAR, ALERT_SUCCESS, ALERT_FAILURE } from '../../types/alert';

export const successAlert = (payload: IAlert) => {
  return { type: ALERT_SUCCESS, payload };
};

export const errorAlert = (payload: IAlert) => {
  return { type: ALERT_FAILURE, payload };
};

export const clearAlert = () => {
  return { type: ALERT_CLEAR };
};
