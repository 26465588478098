import React from 'react';
import { Box, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { ButtonComponent } from '../../../../../shared/Components';
import AthletesTable from './AthletesTable';
import { IAthleteProfile } from '../../../../../models/athlete';
import { addAthleteStyledButton } from './styles';
interface IProps {
  athletes: Array<IAthleteProfile>;
  loading: boolean;
}

const AthleteListView: React.FC<IProps> = (props) => {
  const { athletes, loading } = props;
  const history = useHistory();

  /**
   * Opens page for registering athlete
   */
  const openRegisterAthletePage = (): void => {
    history.push(`/dashboard/admin/add-athlete`);
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Box mt={3}>
        <ButtonComponent
          variant="contained"
          color="primary"
          style={addAthleteStyledButton}
          onClick={openRegisterAthletePage}
        >
          Add Athlete
        </ButtonComponent>
        <AthletesTable athletes={athletes} loading={loading} />
      </Box>
    </Container>
  );
};

export default AthleteListView;
