import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { colors } from '../../../../../theme/theme';

import { UserCheck as ScoutIcon } from 'react-feather';

import {
  ScoutsAvatar,
  OverduePaymentAvatar,
  DashboardHeader,
  HeroContent,
  CreditCardAvatar,
  ButtonComponent,
} from '../../../../../shared/Components';

import { header, StyledCard, StyledGrid } from './styles';

const title: string = 'Subscriptions';

const overduePaymentTitle: string = 'Overdue Payments';
const overduePaymentCount = 8873;

const paymentTitle: string = 'Monthly Subscriptions(KES)';
const paymentDate = 786900;

const allAthletesTitle = 'All Athletes';
const allAthletesCount = 1896;

const color = '#FC303B';

const SubscriptionSummary: React.FC<{}> = () => {
  const history = useHistory();
  const navigateTo = () => history.push('/admin-dashboard/add-athlete');
  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Box style={header}>
            <DashboardHeader>{title}</DashboardHeader>
            <ButtonComponent
              variant="contained"
              startIcon={<ScoutIcon />}
              style={{
                marginTop: 20,
                marginBottom: 20,
                background: colors.primary,
              }}
              onClick={navigateTo}
            >
              Invite Scout
            </ButtonComponent>
          </Box>
        </Grid>
      </Grid>

      <StyledCard>
        <Grid container>
          <StyledGrid item xs={5}>
            <HeroContent
              title={paymentTitle}
              count={paymentDate}
              icon={CreditCardAvatar}
            />
          </StyledGrid>
          <StyledGrid item xs={4}>
            <HeroContent
              title={allAthletesTitle}
              count={allAthletesCount}
              icon={ScoutsAvatar}
            />
          </StyledGrid>
          <StyledGrid item xs={3}>
            <HeroContent
              title={overduePaymentTitle}
              count={overduePaymentCount}
              icon={OverduePaymentAvatar}
              fontColor={color}
            />
          </StyledGrid>
        </Grid>
      </StyledCard>
    </Box>
  );
};

export default SubscriptionSummary;
