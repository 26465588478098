import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';

import UserService from '../../../../../shared/services/user.service';

import { LinkComponent } from '../../../../../shared/Components/index';
import { colors } from '../../../../../theme/theme';
import { IAthleteProfile } from '../../../../../models/athlete';
import ProfileList from './ProfileList';

const titleStyled: React.CSSProperties = {
  borderBottom: '1px solid #C4C4C4',
  marginBottom: 10,
  paddingBottom: 20,
};

interface IPersonalInfoProps {
  athlete: IAthleteProfile;
}

const PersonalInfo: React.FC<IPersonalInfoProps> = (props) => {
  const { athlete } = props;
  return (
    <Box>
      <Typography variant="h5" style={titleStyled}>
        Personal Info
      </Typography>
      {/* <Typography variant="body1">
        You are 1 of 555,000 high school basketball players. You want to play in
        college, but separating yourself from the pack and navigating your
        college recruitment can be extremely difficult.
      </Typography> */}
      <ProfileList athlete={athlete} />

      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <ShareOutlinedIcon
          fontSize="small"
          style={{ color: colors.link, marginRight: 10 }}
        />
        <LinkComponent style={{ fontSize: 16 }}>Share Profile</LinkComponent>
      </Box>
    </Box>
  );
};

export default PersonalInfo;
