import React, { useEffect, useState, useRef, MutableRefObject } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { colors, fontWeight } from '../../../../theme/theme';
import {
  ContainerStyled,
  LinkStyled,
  smallTextStyle,
  imageStyle,
  VideoListContainerStyled,
  FeaturedVideoItemStyled,
} from './styles';

import { featuredVideos } from '../../../../shared/data/featureVideoMock';
import { FeaturedVideoModel } from '../../../../models/frontPage';

import './style.scss';

interface IVideoItemProps {
  video: FeaturedVideoModel;
}

interface IArrowProps {
  className: string;
  text: string;
}

const FeaturedVideoItem = (props: IVideoItemProps) => {
  const { video } = props;
  return (
    <FeaturedVideoItemStyled>
      <img
        src={video.videoThumbnail}
        alt={video.videoTitle}
        style={imageStyle}
      />
      <Typography color="textSecondary" style={{ fontSize: 20 }}>
        {video.videoTitle}
      </Typography>

      <Box style={{ display: 'flex', marginTop: 20, alignItems: 'center' }}>
        <PlayCircleOutlineIcon
          fontSize="small"
          style={{ color: colors.white, marginRight: 5 }}
        />

        <Typography color="textSecondary" style={smallTextStyle}>
          {video.videoLength} mins
        </Typography>
      </Box>
    </FeaturedVideoItemStyled>
  );
};

const FeaturedVideoList = (videos: Array<FeaturedVideoModel>) =>
  videos.map((video, index) => {
    return <FeaturedVideoItem video={video} key={index} />;
  });

const ArrowLeft = (
  <ArrowBackIcon fontSize="small" style={{ color: colors.white }} />
);
const ArrowRight = (
  <ArrowForwardIcon fontSize="small" style={{ color: colors.white }} />
);

const FeaturedVideos: React.FC<{}> = () => {
  const [videoItems, setVideoItems] = useState<JSX.Element[]>([]);
  const navRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    setVideoItems(FeaturedVideoList(featuredVideos));
  }, []);

  const handlePrevNav = () =>
    navRef ? (navRef.current.scrollLeft -= 200) : null;

  const handleNextNav = () =>
    navRef ? (navRef.current.scrollLeft += 200) : null;

  return (
    <ContainerStyled>
      <Typography
        color="textSecondary"
        style={{ fontSize: 20, fontWeight: fontWeight.bold }}
      >
        Featured Videos
      </Typography>

      <Box style={{ position: 'absolute', right: 60 }}>
        <ArrowBackIcon
          onClick={() => handlePrevNav()}
          fontSize="small"
          style={{ color: colors.white, marginRight: 20 }}
        />
        <ArrowForwardIcon
          onClick={() => handleNextNav()}
          fontSize="small"
          style={{ color: colors.white }}
        />
      </Box>

      <Box
        style={{
          display: 'flex',
          marginTop: 10,
          alignItems: 'center',
          marginBottom: 40,
        }}
      >
        <LinkStyled>
          <Typography color="textSecondary" style={smallTextStyle}>
            View All Videos
          </Typography>
        </LinkStyled>
        <NavigateNextIcon fontSize="small" style={{ color: colors.white }} />
      </Box>

      {/* <ScrollMenu
        data={videoItems}
        arrowLeft={ArrowLeft}
        arrowRight={ArrowRight}
        dragging={true}
        hideArrows={true}
        hideSingleArrow={true}
        wheel={false}
        translate={0}
        transition={0.3}
      /> */}
      <VideoListContainerStyled ref={navRef}>
        {videoItems}
      </VideoListContainerStyled>
    </ContainerStyled>
  );
};

export default FeaturedVideos;
