import { CSSProperties } from 'react';

export const headerBottomSectionStyled: CSSProperties = {
  bottom: 50,
  position: 'absolute',
  width: '100%',
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const profileImageStyle: CSSProperties = {
  maxWidth: '100%',
  maxHeight: '100%',
  borderRadius: '50%',
  width: 100,
  height: 100,
};
