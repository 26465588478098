import React, { FC } from 'react';

import { Card, CardContent, Typography } from '@material-ui/core';
import { fontWeight, colors } from '../../../theme/theme';

interface IAthleteStatsProps {
  stats: number;
  title: string;
  backgroundColor?: string;
}

const cardContentStyles: React.CSSProperties = {
  color: colors.white,
};
const countStyles: React.CSSProperties = {
  fontSize: 54,
  fontWeight: fontWeight.mediumTwo,
};
const titleStyles: React.CSSProperties = {
  fontSize: 20,
  fontWeight: fontWeight.normal,
};

const cardStyles: React.CSSProperties = {
  height: 130,
  borderRadius: 5,
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.07)',
};
const StatsCard: React.FC<IAthleteStatsProps> = (props) => {
  const { stats, title, backgroundColor } = props;
  return (
    <Card style={{ backgroundColor: backgroundColor }} elevation={3}>
      <CardContent style={cardContentStyles}>
        <Typography style={countStyles}>{stats}</Typography>
        <Typography style={titleStyles}>{title}</Typography>
      </CardContent>
    </Card>
  );
};

export default StatsCard;
