import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Avatar, Box, Chip } from '@material-ui/core';
import { VerifiedIcon } from '../../Components/Icons';
const avatar: React.CSSProperties = {
  marginRight: 15,
};

const chipStyles: React.CSSProperties = {
  borderRadius: 5,
  background: '#05C640',
  padding: '16px 16px',
  color: 'white',
  border: 0,
};

interface IProfileAvatarProps {
  image: string;
  name: string;
  verified: boolean;
}

const TableProfileAvatar: React.FC<IProfileAvatarProps> = (props) => {
  const { image, name, verified } = props;
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <>
            <Badge
              style={avatar}
              overlap="circle"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={verified && <VerifiedIcon />}
            >
              <Avatar src={image}></Avatar>
            </Badge>
          </>

          <>{name}</>
        </Box>

        {verified && (
          <Chip
            label="Updated"
            disabled
            variant="outlined"
            style={chipStyles}
          />
        )}
      </Box>
    </>
  );
};

export default TableProfileAvatar;
