import React, { useState } from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { colors, socialIconsColor } from '../../theme/theme';
import { VideoComponent } from '../../shared/Components';
import { StyledNewsFeedCard, useStyles } from './styles';

interface INewsCardProps {
  mediaTitle: string;
  mediaDescription?: string;
  mediaPath: string;
  athleteName: string;
  profileImage?: string;
}

const NewsCard: React.FC<INewsCardProps> = (props) => {
  const { mediaTitle, athleteName, mediaPath } = props;
  const [like, setLike] = useState<boolean>(false);
  const [likeCount, setLikeCount] = useState<number>(0);
  const classes = useStyles();

  const handleLike = () => {
    setLike(!like);
    like ? setLikeCount(likeCount - 1) : setLikeCount(likeCount + 1);
  };
  return (
    <StyledNewsFeedCard>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}></Avatar>
        }
        title={athleteName}
        subheader="September 14, 2016"
        subheaderTypographyProps={{
          style: { color: '#828282' },
          variant: 'body1',
        }}
      />
      <CardContent>
        <VideoComponent videoSource={mediaPath} />
        <Typography variant="body2" color="textPrimary" component="p">
          {mediaTitle}
        </Typography>
      </CardContent>
      <CardActions>
        <IconButton onClick={handleLike} aria-label="like" size="small">
          <ThumbUpAltIcon
            style={{
              color: like
                ? socialIconsColor.iconTrueState
                : socialIconsColor.iconFalseState,
            }}
          />
        </IconButton>
        <Typography
          style={{
            color: like
              ? socialIconsColor.iconTrueState
              : socialIconsColor.iconFalseState,
          }}
        >
          {likeCount}
        </Typography>
        <VisibilityIcon fontSize="small" style={{ color: colors.icon }} />
        <Typography style={{ color: colors.icon }}>0</Typography>
      </CardActions>
    </StyledNewsFeedCard>
  );
};

// NewsCard.displayName = 'NewsCard';

export default NewsCard;
