import React, { useEffect, useState } from 'react';
import { AccountSummary, AthletesListView } from './Content';

import { IAthleteProfile } from '../../../../models/athlete';

const Home: React.FC<{}> = () => {
  const [athletes, setAthletes] = useState<Array<IAthleteProfile>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  return (
    <>
      <AccountSummary />
      <AthletesListView loading={loading} athletes={athletes} />
    </>
  );
};

export default Home;
