import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import {
  VerifiedScoutsAvatar,
  UnverifiedScoutsAvatar,
  ScoutsAvatarAdmin,
  DashboardHeader,
  HeroContent,
  ButtonComponent,
} from '../../../../../shared/Components';
import { colors } from '../../../../../theme/theme';

import { UserCheck as ScoutIcon } from 'react-feather';

const StyledCard = styled(Card)`
  padding: 30px 30px;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
`;

const StyledAvatar = styled(Avatar)`
  height: 56px;
  width: 56px;
  margin-right: 7px;
  margin-bottom: 16px;
`;

const header: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'end',
};

const athleteAvatar: CSSProperties = {
  background: 'rgba(47, 128, 237, 0.2)',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helper: {
      borderLeft: `1px solid #828282`,
      padding: theme.spacing(0, 2),
    },
  })
);

const title: string = 'Scouts';

const AllScoutsTitle: string = 'All Scouts';
const AllScoutsCount = 8873;

const verifiedScoutsTitle = 'Verified Scouts';
const verifiedScoutsCount = 8873;

const UnerifiedScoutsTitle = 'Unverified Scouts';
const UnverifiedScoutsCount = 8873;

const ScoutSummary: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const navigateTo = () => history.push('/admin-dashboard/add-athlete');
  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Box style={header}>
            <DashboardHeader>{title}</DashboardHeader>
            <ButtonComponent
              variant="contained"
              startIcon={<ScoutIcon />}
              style={{
                marginTop: 20,
                marginBottom: 20,
                background: colors.primary,
              }}
              onClick={navigateTo}
            >
              Invite Scout
            </ButtonComponent>
          </Box>
        </Grid>
      </Grid>

      <StyledCard>
        <Grid container>
          <Grid item xs={5}>
            <HeroContent
              title={AllScoutsTitle}
              count={AllScoutsCount}
              icon={ScoutsAvatarAdmin}
            />
          </Grid>
          <Grid item xs={4} className={classes.helper}>
            <HeroContent
              title={UnerifiedScoutsTitle}
              count={UnverifiedScoutsCount}
              icon={UnverifiedScoutsAvatar}
            />
          </Grid>
          <Grid item xs={3} className={classes.helper}>
            <HeroContent
              title={verifiedScoutsTitle}
              count={verifiedScoutsCount}
              icon={VerifiedScoutsAvatar}
            />
          </Grid>
        </Grid>
      </StyledCard>
    </Box>
  );
};

export default ScoutSummary;
