import { ICarouselProps } from '../../models/carousels';

const description = `Kachezwe Provides Athletes with a platform to promote their development, 
skill set in order to showcase to interested parties and connect with other African players to support one another in a community format.`;

export const data: Array<ICarouselProps> = [
  {
    title: 'Kachezwe For Athletes',
    description: description,
  },
  {
    title: 'Kachezwe For Athletes',
    description: description,
  },
  {
    title: 'Kachezwe For Athletes',
    description: description,
  },
];
