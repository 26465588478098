import { CardContent, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { IChatMessage } from '../../../../../models/chat';
import {
  DashboardHeader,
  LinkComponent,
} from '../../../../../shared/Components';
import { MessagesContainer } from '../styles';
import {
  BodyText,
  BodyTextContainer,
  ContentContainer,
  StyledContainer,
} from './style';

interface IRecentMessageProps {
  recentMessage: Partial<IChatMessage>;
}

const title: string = `Recent Messages`;

const MessageItem = (props: IRecentMessageProps) => {
  const { recentMessage } = props;

  return (
    <StyledContainer>
      <ContentContainer>
        <Typography>{recentMessage.senderId}</Typography>
      </ContentContainer>
      <BodyTextContainer>
        <BodyText variant="body2">{recentMessage.body}</BodyText>
      </BodyTextContainer>
      <ContentContainer>
        <LinkComponent>See more</LinkComponent>
      </ContentContainer>
    </StyledContainer>
  );
};

const RecentMessages: React.FC<{}> = () => {
  return (
    <Grid container>
      <Grid item>
        <DashboardHeader>{title}</DashboardHeader>
        <MessagesContainer>
          <CardContent>{/* <MessageItem /> */}</CardContent>
        </MessagesContainer>
      </Grid>
    </Grid>
  );
};

export default RecentMessages;
