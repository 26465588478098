import { IMediaResponse } from '../../../models/media';

export const FETCH_ALL_MEDIA_REQUEST = 'FETCH_ALL_MEDIA_REQUEST';
export const FETCH_ALL_MEDIA_SUCCESS = 'FETCH_ALL_MEDIA_SUCCESS';
export const FETCH_ALL_MEDIA_FAILURE = 'FETCH_ALL_MEDIA_FAILURE';

export interface IMedia {
  isLoading: boolean;
  error?: any;
  data?: Array<IMediaResponse>;
}

export interface MediaState {
  media: IMedia;
}

interface FetchAllMediaRequestAction {
  type: typeof FETCH_ALL_MEDIA_REQUEST;
  payload: MediaState;
}

interface FetchAllMediaSuccessAction {
  type: typeof FETCH_ALL_MEDIA_SUCCESS;
  payload: MediaState;
}

interface FetchAllMediaFailAction {
  type: typeof FETCH_ALL_MEDIA_FAILURE;
  payload: MediaState;
}

export type MediaActionTypes =
  | FetchAllMediaRequestAction
  | FetchAllMediaSuccessAction
  | FetchAllMediaFailAction;
