import React from 'react';
import { Paper, Box, Container, Typography } from '@material-ui/core';

import TableComponent from './Table';

import {
  AppBarComponent,
  SearchButton,
} from '../../../../../shared/Components';
import { IAthleteProfile } from '../../../../../models/athlete';

import { containerStyles, titleStyles } from '../styles';

interface IProps {
  loading: boolean;
  athletes: Array<IAthleteProfile>;
}

const AthletesListView: React.FC<{}> = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <Box style={containerStyles} component={Paper} mt={3}>
        {/* AppBar Component */}
        <AppBarComponent>
          <Typography style={titleStyles}>Bookmarked Athletes</Typography>
          <SearchButton />
        </AppBarComponent>

        <TableComponent />
      </Box>
    </Container>
  );
};

export default AthletesListView;
