import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { IAuthenticatedUser } from '../../models/auth';
import AuthService from '../services/auth.service';

interface PrivateRouteProps extends RouteProps {
  roles: string[];
  Component: React.FunctionComponent;
}

export const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({
  Component,
  roles,
  ...rest
}: PrivateRouteProps) => (
  <Route
    {...rest}
    render={(props: RouteProps) => {
      const currentUser: IAuthenticatedUser = AuthService.getCurrentUser();

      if (Object.keys(currentUser).length === 0) {
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }

      if (roles && roles.indexOf(currentUser.role_name) === -1) {
        return <Redirect to={{ pathname: '/' }} />;
      }

      return <Component {...props} />;
    }}
  />
);
