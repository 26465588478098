import {
  MediaActionTypes,
  MediaState,
  IMedia,
  FETCH_ALL_MEDIA_FAILURE,
  FETCH_ALL_MEDIA_REQUEST,
  FETCH_ALL_MEDIA_SUCCESS,
} from '../types/media';

const defaultState: IMedia = {
  isLoading: false,
  data: [],
  error: null,
};

const media = (
  state = defaultState,
  action: MediaActionTypes
): MediaState | {} => {
  switch (action.type) {
    case FETCH_ALL_MEDIA_REQUEST:
      return {
        ...action.payload,
      };
    case FETCH_ALL_MEDIA_FAILURE:
      return {
        ...action.payload,
      };
    case FETCH_ALL_MEDIA_SUCCESS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export default media;
