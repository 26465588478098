import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import styled from 'styled-components';

import { colors } from '../../../../theme/theme';

export const BoxStyled = styled(Box)`
  background: ${colors.secondary};
  padding-top: 110px;
  padding-bottom: 110px;
  padding-left: 180px;
  display: flex;
  flex: 1;
  align-items: baseline;
  @media (max-width: 375px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

export const LinkStyled = styled(Link)`
  &: hover {
    color: ${colors.white};
  }
`;
