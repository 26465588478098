/* eslint-disable react/display-name */
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Slider from 'react-slick';
import { SliderWrapper, TitleStyled } from './style';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ICarouselProps } from '../../../../models/carousels';

interface ItemProps {
  item: ICarouselProps;
}

interface IContentProps {
  carousels: Array<ICarouselProps>;
}

const CarouselItem = (props: ItemProps) => {
  const { item } = props;

  return (
    <>
      <TitleStyled>{item.title}</TitleStyled>
      <Typography variant="body1" style={{ fontSize: 24, margin: ' 30px 0' }}>
        {item.description}
      </Typography>
    </>
  );
};

const ContentCarousel: React.FC<IContentProps> = (props) => {
  const { carousels } = props;

  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 550,
    arrows: false,
    adaptiveHeight: false,
    customPaging: () => <Box className="slick__dots"></Box>,
  };

  return (
    <SliderWrapper>
      <Slider {...settings}>
        {carousels.map((item, index) => (
          <CarouselItem key={index} item={item} />
        ))}
      </Slider>
    </SliderWrapper>
  );
};

export default ContentCarousel;
