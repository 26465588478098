import React from 'react';
import { Link } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import { routes } from '../../routes';
import { colors } from '../../../theme/theme';
import SocialMedia from '../SocialMedia';
import './index.scss';

interface IMainMenuProps {
  open: boolean;
  handleCloseCallBack(): void;
}

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.secondary,
  },
});

const MenuBoxStyled = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 30px;
`;

const SocialMediaBoxStyle = styled(Box)`
  display: flex;
  justify-content: center;
`;

const LinkStyled = styled(Link)`
  &: hover {
    color: ${colors.white};
    transform: translateX(50px);
    transition: transform 0.5s linear;
  }
`;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MainMenu: React.FC<IMainMenuProps> = (props) => {
  const { open, handleCloseCallBack } = props;
  const classes = useStyles();

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleCloseCallBack}
        TransitionComponent={Transition}
        PaperProps={{ className: classes.root }}
      >
        <MenuBoxStyled>
          <Box>
            <List>
              {routes.map((route, index) => (
                <ListItem button={false} key={index}>
                  <LinkStyled
                    to={route.link}
                    style={{ color: colors.secondary }}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'h1',
                        color: 'textSecondary',
                      }}
                    >
                      {route.name}
                    </ListItemText>
                  </LinkStyled>
                </ListItem>
              ))}
            </List>
            <SocialMedia className="menu-social-media-icons" />
          </Box>
          <IconButton
            edge="end"
            color="primary"
            onClick={handleCloseCallBack}
            aria-label="close"
            disableRipple={true}
            className="main-menu-close-icon"
          >
            <CloseIcon style={{ color: colors.white }} fontSize="large" />
          </IconButton>
        </MenuBoxStyled>
      </Dialog>
    </>
  );
};

export default MainMenu;
