import React, { useState } from 'react';
import { Table, TableBody } from '@material-ui/core';

import { TableCell, TableRow, TableContainer } from '../styles';
import { IScoutAccount } from '../../../../../models/user';

import {
  LinkComponent,
  DashboardHeader,
} from '../../../../../shared/Components';

const RelatedOrdersTable: React.FC<{}> = () => {
  const [order, setOrder] = useState<IScoutAccount>({} as IScoutAccount);

  return (
    <>
      <DashboardHeader>Related Orders</DashboardHeader>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Order Number: </TableCell>
              <TableCell>{order.order_id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Date: </TableCell>
              <TableCell>{order.date_paid}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Status: </TableCell>
              <TableCell>{order.payment_status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total: </TableCell>
              <TableCell>{order.total_billing}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Action</TableCell>
              <TableCell>
                <LinkComponent>View</LinkComponent>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RelatedOrdersTable;
