import React, { PropsWithChildren } from 'react';
import PropTypes from 'prop-types';
import { AppBar, ToolbarProps, Toolbar } from '@material-ui/core';

const appBarStyles: React.CSSProperties = {
  backgroundColor: 'transparent',
  boxShadow: 'none',
  borderBottom: '1px solid #F3F4F8',
  paddingTop: 15,
  paddingBottom: 10,
  paddingRight: 20,
  paddingLeft: 20,
};

const AppBarComponent: React.FC<PropsWithChildren<ToolbarProps>> = (props) => {
  const { children } = props;

  return (
    <>
      <AppBar position="static" color="default" style={appBarStyles}>
        <Toolbar disableGutters>{children}</Toolbar>
      </AppBar>
      {/* End AppBar Component */}
    </>
  );
};

AppBarComponent.propTypes = {
  children: PropTypes.any,
};

export default AppBarComponent;
