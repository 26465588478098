import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
  },
  scroll: {
    maxHeight: '100%',
    '& .simplebar-scrollbar': {
      '&:before': {
        backgroundColor: '#c2c2c2',
      },
      '&.simplebar-visible:before': {
        opacity: 1,
      },
    },
    '& .simplebar-vertical': {
      width: 10,
    },
    '& .simplebar-horizontal .simplebar-scrollbar': {
      height: 3,
    },
    '& .simplebar-mask': {
      zIndex: 'inherit',
    },
    '& .simplebar-content-wrapper::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));
