import React, { FC } from 'react';
import styled from 'styled-components';

import IconAvatar from '../../Avatar';

import MessageIcon from '../../Icons/MessageIcon';

const StyledAvatar = styled(IconAvatar)`
  background-color: rgba(243, 161, 0, 0.2);
`;

const MessageAvatar: React.FC<{}> = () => {
  return (
    <StyledAvatar>
      <MessageIcon />
    </StyledAvatar>
  );
};

export default MessageAvatar;
