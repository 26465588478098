import React, { FC } from 'react';
import { IScoutAccount } from '../../../../models/user';
import DetailsTable from './AccountDetailsTable';
import BillingAddress from './BillingAddress';
import RelatedOrdersTable from './RelatedOrdersTable';
import SubscriptionsTotalsTable from './SubscriptionsTotalsTable';


const AccountDetails: FC<{}> = () => {
  return (
    <>
      <DetailsTable/>
      <SubscriptionsTotalsTable />
      <RelatedOrdersTable />
      <BillingAddress />
    </>
  );
};

export default AccountDetails;
