import React, { PropsWithChildren } from 'react';

import Avatar from '@material-ui/core/Avatar';

const avatarStyle: React.CSSProperties = {
  height: 56,
  width: 56,
  marginRight: 7,
  marginBottom: 16,
};
const IconAvatar: React.FC<{}> = (props) => {
  const { children, ...rest } = props;

  return (
    <Avatar {...rest} style={avatarStyle}>
      {children}
    </Avatar>
  );
};

export default IconAvatar;
