import React, { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Bugsnag from '@bugsnag/js';
import InfiniteScroll from 'react-infinite-scroll-component';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { IMediaResponse } from '../../models/media';
import { MediaState } from '../../shared/store/types/media';
import { AlertState } from '../../shared/store/types/alert';
import { fetchAllMediaPerPage } from '../../shared/store/actions/media';
import MediaApiService from '../../shared/services/api/media';
// import { useVisibility } from '../../shared/hooks';

import { colors } from '../../theme/theme';
import {
  HeaderComponent,
  ContentComponent,
} from '../../shared/Layout/PublicPage';
import { NavigationBar, AlertComponent, Loader } from '../../shared/Components';
import NewsCard from './NewsCard';
import { AxiosError } from 'axios';

const NewsFeed: FC = () => {
  // const dispatch = useDispatch();

  let [pageNumber, setPageNumber] = useState(1);
  let [mediaData, setMediaData] = useState<IMediaResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);

  // const { data, isLoading, error } = useSelector(
  //   (state: MediaState) => state.media,
  //   shallowEqual
  // );
  // const { alertType } = useSelector((state: AlertState) => state.alert);

  // const lastVideo = useVisibility(
  //   (visible) => {
  //     if (visible) {
  //       dispatch(fetchAllMediaPerPage('video', pageNumber));
  //       if (data) {
  //         setPageNumber(pageNumber + 1);
  //       }
  //     }
  //   },
  //   [pageNumber, dispatch]
  // );

  // Fetch more data when scrolling to the next set of items
  const fetchMore = () => {
    setPageNumber(pageNumber + 1);
    MediaApiService.getAllMediaFilesPerPage('video', pageNumber)
      .then((response) => {
        if (response.data.length > 0) {
          setMediaData([...mediaData, ...response.data]);
        } else {
          setHasMoreData(false);
        }
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 404) {
          Bugsnag.notify(error.response?.data);
          setHasMoreData(false);
        }
      });
  };

  // Initial data fetch
  useEffect(() => {
    try {
      // dispatch(fetchAllMediaPerPage('video', pageNumber));
      MediaApiService.getAllMediaFilesPerPage('video', pageNumber)
        .then((response) => {
          setIsLoading(false);
          setMediaData(response.data);
          setPageNumber(pageNumber + 1);
        })
        .catch((error: AxiosError) => {
          setError(true);
          Bugsnag.notify(error.response?.data);
        });
    } catch (error) {
      Bugsnag.notify(error);
    }
  }, []);

  console.log(mediaData);

  return (
    <>
      <Container
        style={{ backgroundColor: colors.backgroundFour }}
        maxWidth={false}
        disableGutters={true}
      >
        <HeaderComponent>
          <NavigationBar></NavigationBar>
        </HeaderComponent>
        <ContentComponent>
          {isLoading ? (
            <Loader />
          ) : (
            <Grid container style={{ marginBottom: 10, marginTop: 10 }}>
              {/* {error && alertType === 'error' && (
                <AlertComponent severity={alertType}>
                  Unable to fetch news feed
                </AlertComponent>
              )} */}
              {error && (
                <AlertComponent severity="error">
                  Unable to fetch news feed
                </AlertComponent>
              )}

              <Grid item lg={3} md={3} xs={12}></Grid>
              <Grid item lg={6} md={6} xs={12}>
                <InfiniteScroll
                  dataLength={mediaData.length}
                  loader={<h4>Loading...</h4>}
                  next={fetchMore}
                  hasMore={hasMoreData}
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                >
                  {mediaData.map((media) => {
                    return (
                      <NewsCard
                        key={media.media_id}
                        mediaTitle={media.media_name}
                        mediaPath={media.media_path}
                        athleteName={media.media_owner}
                        profileImage=""
                      />
                    );
                  })}
                </InfiniteScroll>
              </Grid>
              <Grid item lg={3} md={3} xs={12}></Grid>
            </Grid>
          )}
        </ContentComponent>
      </Container>
    </>
  );
};

NewsFeed.propTypes = {
  children: PropTypes.any,
};

export default NewsFeed;
