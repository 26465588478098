import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { PlayerCard, VideoComponent } from '../../../../../shared/Components';

const titleStyled: React.CSSProperties = {
  borderBottom: '1px solid #C4C4C4',
  marginBottom: 10,
  paddingBottom: 20,
};

interface IProps {
  media: any;
}

const AthleteDetails: React.FC<IProps> = (props) => {
  const { media } = props;
  const WEB_URL = process.env.REACT_APP_KACHEZWE_WEB_URL;
  return (
    <Box>
      <Typography variant="h5" style={titleStyled}>
        Images/Videos
      </Typography>

      <Grid container spacing={2}>
        {media.map((mediaObject: any, videoIndex: number) => (
          <Grid key={videoIndex} item lg={6} xs={12}>
            <VideoComponent
              videoTitle={mediaObject.media_name}
              videoSource={`${WEB_URL}${mediaObject.media_file}`}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AthleteDetails;
