import React from 'react';

import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import { colors, fontWeight } from '../../../theme/theme';
import LoginFormComponent from '../Form/Login';

interface IProps {
  isOpen: boolean;
  handleDialogClose(): void;
  dialogTypeTitle?: string;
}

const dialogStyles: React.CSSProperties = {
  backgroundColor: '#F7F9FD',
  borderRadius: 5,
  paddingBottom: 30,
  paddingTop: 40,
};

const dialogTitleStyles: React.CSSProperties = {
  fontSize: 24,
  fontWeight: fontWeight.normal,
  textAlign: 'center',
};

const dividerStyles: React.CSSProperties = {
  borderTop: '1px solid #F2F2F2',
  marginRight: 110,
  marginLeft: 110,
  marginTop: 30,
  marginBottom: 40,
};

const Login: React.FC<IProps> = (props) => {
  const { isOpen, handleDialogClose } = props;

  const DialogTypeTitle = () => (
    <span style={{ color: colors.primary, fontWeight: fontWeight.mediumTwo }}>
      {props.dialogTypeTitle}
    </span>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{ style: dialogStyles }}
      maxWidth={'md'}
      fullWidth={true}
    >
      <DialogTitle
        id="form-dialog-title"
        style={dialogTitleStyles}
        disableTypography={true}
      >
        Access Your Account
      </DialogTitle>
      <Grid container>
        <Grid item xs={12}>
          <Divider style={dividerStyles} />
        </Grid>
        <DialogContent style={{ paddingLeft: 110, paddingRight: 110 }}>
          <LoginFormComponent />
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default Login;
