import { CSSProperties } from 'react';
import { fontWeight } from '../../../theme/theme';

export const dialogStyles: CSSProperties = {
  backgroundColor: '#F7F9FD',
  borderRadius: 5,
  paddingBottom: 30,
  paddingTop: 40,
};

export const dialogTitleStyles: CSSProperties = {
  fontSize: 24,
  fontWeight: fontWeight.normal,
  textAlign: 'center',
};

export const dividerStyles: CSSProperties = {
  borderTop: '1px solid #F2F2F2',
  marginRight: 110,
  marginLeft: 110,
  marginTop: 30,
  marginBottom: 40,
};
