import React, { FC, ElementType } from 'react';

import { Typography, Box } from '@material-ui/core';
import { fontFamily, fontWeight } from '../../../theme/theme';
import styled from 'styled-components';

interface IHeroContentProps {
  count?: number;
  date?: string;
  title: string;
  fontColor?: string;
  icon: ElementType;
}

const StyledCountText = styled(Typography)`
  font-size: 30px;
`;

const titleStyles: React.CSSProperties = {
  fontSize: 16,
  fontWeight: fontWeight.mediumOne,
  fontFamily: fontFamily.sofiaPro,
};

const HeroContent: React.FC<IHeroContentProps> = (props) => {
  const { icon: Icon, count, date, title, fontColor } = props;
  return (
    <>
      <Box display="flex">
        <Icon />
        <StyledCountText style={{ color: fontColor }}>
          {count || date}
        </StyledCountText>
      </Box>
      <Typography style={titleStyles}>{title}</Typography>
    </>
  );
};

export default HeroContent;
