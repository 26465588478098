import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';

import { IAuthenticatedUser } from '../../../../models/auth';
import { logoutUser } from '../../../../shared/store/actions/auth/logout';
import AuthService from '../../../services/auth.service';
import { AuthenticatedState } from '../../../../shared/store/types/auth';
import { ListItemText } from '@material-ui/core';
import { styledTitle, useStyles } from '../style';

const HeaderProfile: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [currentUser, setCurrentUser] = useState<IAuthenticatedUser | null>(
    null
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const loggedIn = useSelector(
    (state: AuthenticatedState) => state.authentication.loggedIn
  );
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    if (loggedIn) {
      await setCurrentUser(null);
      await dispatch(logoutUser());
      await history.push('/');
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (loggedIn) {
      setCurrentUser(AuthService.getCurrentUser());
    }
  }, [loggedIn]);

  return (
    <>
      <Grid item>
        {currentUser && (
          <Typography variant="h6" style={{ marginRight: 30 }}>
            {currentUser.name}
          </Typography>
        )}
      </Grid>
      <Grid item>
        <IconButton
          className={classes.iconButtonAvatar}
          aria-label="true"
          aria-controls="menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          {currentUser && (
            <Avatar src={currentUser.name} alt={currentUser.name} />
          )}
        </IconButton>
        <Menu
          id="menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          elevation={1}
          keepMounted
          onClose={handleClose}
          TransitionComponent={Fade}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          className={classes.menuStyle}
        >
          <MenuItem>
            <ListItemText
              primary={
                currentUser && (
                  <>
                    <Typography variant="h6">{currentUser.name}</Typography>
                    <Typography style={styledTitle}>
                      {currentUser.role_name}
                    </Typography>
                  </>
                )
              }
            />
          </MenuItem>
          <Divider />
          <MenuItem component={Link} to="/dashboard/admin">
            <ListItemText primary="Go to Dashboard" />
          </MenuItem>
          <MenuItem component={Link} to="/">
            <ListItemText primary="Find Athletes" />
          </MenuItem>
          <MenuItem component={Link} to="/">
            <ListItemText primary="Profile" />
          </MenuItem>
          <MenuItem onClick={handleLogout} component={Link} to="/">
            <ListItemText primary="Sign Out" />
          </MenuItem>
        </Menu>
      </Grid>
    </>
  );
};

export default HeaderProfile;
