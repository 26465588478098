import { Card, Container } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { DashboardHeader } from '../../../../shared/Components';
import MessageWindow from './MessagesWindow';
import MessageSidebar from './Sidebar';

const StyledCard = styled(Card)`
  height: 90vh;
  display: flex;
  background: none;
  background: transparent;
  box-shadow: none;
  margin-bottom: 40px;
`;

const MessageView: React.FC<{}> = () => {
  return (
    <Container disableGutters>
      <DashboardHeader>Messages</DashboardHeader>
      <StyledCard>
        <MessageSidebar />
        <MessageWindow />
      </StyledCard>
    </Container>
  );
};

export default MessageView;
