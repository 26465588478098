import Container from '@material-ui/core/Container';
import React from 'react';
import { useHistory } from 'react-router';
import { ButtonComponent } from '../../shared/Components';
import { buttonMargin, ContentContainer, ErrorText } from './styles';

const NotFoundPage: React.FC<{}> = () => {
  const history = useHistory();

  const clickToPrevious = () => {
    history.goBack();
  };
  return (
    <Container>
      <ContentContainer>
        <ErrorText variant="h2">404: Page Not Found</ErrorText>

        <ButtonComponent
          style={buttonMargin}
          color="primary"
          onClick={clickToPrevious}
          variant="contained"
        >
          Previous Page
        </ButtonComponent>
      </ContentContainer>
    </Container>
  );
};

export default NotFoundPage;
