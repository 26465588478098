import React from 'react';

import { CreditCard } from 'react-feather';

interface ICreditCardProps {
  iconColor: string;
}

const CreditCardIcon: React.FC<ICreditCardProps> = (props) => {
  const { iconColor } = props;

  return <CreditCard style={{ color: iconColor }} />;
};

export default CreditCardIcon;
