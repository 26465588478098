import React, { FC } from 'react';
import AccountSummary from './AccountSummary';
import NewAthletes from './NewAthletes';
import RecentMessages from './RecentMessages';

const Home: FC<{}> = () => {
  return (
    <>
      <AccountSummary />
      <NewAthletes />
      <RecentMessages />
    </>
  );
};

export default Home;
