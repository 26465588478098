import React, { FC } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const ContentComponent: FC<React.PropsWithChildren<{}>> = (props) => {
  const { children } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

ContentComponent.propTypes = {
  children: PropTypes.any,
};

export default ContentComponent;
