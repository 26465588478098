import { Box, Container, Divider, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import HeaderDetail from './Header/HeaderDetail';
import MessageInput from './Message/MessageInput';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.07)',
    borderRadius: 5,
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    overflow: 'hidden',
    padding: '15px 30px 15px 30px',
  },
}));

const MessageWindow: React.FC<{}> = () => {
  const classes = useStyles();
  return (
    <Container component={Paper} className={classes.root} disableGutters>
      <div className={classes.root}>
        <HeaderDetail />
        <Divider />

        <Box className={classes.main}>{/* <MessageItem /> */}</Box>
        <Box
          style={{
            backgroundColor: '#F2F2F2',
            paddingLeft: 30,
            paddingTop: 16,
          }}
        >
          <MessageInput />
        </Box>
      </div>
    </Container>
  );
};

export default MessageWindow;
