import featuredVideo from '../../assets/featured-video-1.png';
import { FeaturedVideoModel } from '../../models/frontPage';

export const featuredVideos: Array<FeaturedVideoModel> = [
  {
    videoTitle: 'Kachezwe provides athletes with a platform to promote.',
    videoLength: '2.34',
    videoThumbnail: featuredVideo,
  },
  {
    videoTitle: 'Kachezwe provides athletes with a platform to promote.',
    videoLength: '2.34',
    videoThumbnail: featuredVideo,
  },
  {
    videoTitle: 'Kachezwe provides athletes with a platform to promote.',
    videoLength: '2.34',
    videoThumbnail: featuredVideo,
  },
  {
    videoTitle: 'Kachezwe provides athletes with a platform to promote.',
    videoLength: '2.34',
    videoThumbnail: featuredVideo,
  },
  {
    videoTitle: 'Kachezwe provides athletes with a platform to promote.',
    videoLength: '2.34',
    videoThumbnail: featuredVideo,
  },
  {
    videoTitle: 'Kachezwe provides athletes with a platform to promote.',
    videoLength: '2.34',
    videoThumbnail: featuredVideo,
  },
  {
    videoTitle: 'Kachezwe provides athletes with a platform to promote.',
    videoLength: '2.34',
    videoThumbnail: featuredVideo,
  },
  {
    videoTitle: 'Kachezwe provides athletes with a platform to promote.',
    videoLength: '2.34',
    videoThumbnail: featuredVideo,
  },
];
