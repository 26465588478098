import styled from 'styled-components';
import { Link } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { colors } from '../../../../theme/theme';
import { FooterComponent } from '../../../Layout/PublicPage';

export const FooterComponentStyled = styled(FooterComponent)`
  background-color: ${colors.backgroundTwo};
  color: white;
  padding: 80px 60px;
  @media (max-width: 375px) {
    padding: 1rem 1rem;
  }
`;

export const FooterBoxStyled = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: space-between;
  border-top: 1px solid ${colors.white};
  padding-top: 30px;
  margin-top: 20px;
  @media (max-width: 375px) {
    padding: 1rem 1rem;
    flex-direction: column;
  }
`;

export const MenuLinkStyled = styled(Link)`
  &: hover {
    color: ${colors.white};
  }
`;
