import { Box, Container, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/deleteIcon.svg';
import { ReactComponent as FlagIcon } from '../../../../../../assets/flagIcon.svg';
import { ReactComponent as ShareIcon } from '../../../../../../assets/shareIcon.svg';
import { LinkComponent } from '../../../../../../shared/Components';
import { fontWeight } from '../../../../../../theme/theme';
import { StyledProfileAvatar, useStyles } from './styles';

const SenderTitle = styled(Typography)`
  font-size: 16px;
  font-weight: ${fontWeight.mediumOne};
`;

const StyledTitle = styled(Typography)`
  font-size: 16px;
  color: #828282;
  line-height: 24.75px;
`;

const HeaderDetail: React.FC<{}> = () => {
  const classes = useStyles();
  return (
    <Container className={classes.root} disableGutters>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Box style={{ position: 'relative' }}>
          <StyledProfileAvatar></StyledProfileAvatar>
        </Box>
        <Box>
          <SenderTitle>John Krasiniki</SenderTitle>
          <StyledTitle>
            Scout at <LinkComponent>Tusk Camp</LinkComponent>
          </StyledTitle>
        </Box>
      </Box>
      <Box style={{ flexGrow: 1 }} />
      <IconButton>
        <FlagIcon />
      </IconButton>
      <IconButton>
        <ShareIcon />
      </IconButton>
      <IconButton>
        <DeleteIcon />
      </IconButton>
    </Container>
  );
};

export default HeaderDetail;
