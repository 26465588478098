import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { formatDistanceToNowStrict, sub } from 'date-fns';
import React from 'react';
import { IChatConversation } from '../../../../../../models/chat';
import { colors } from '../../../../../../theme/theme';

interface IConversationProps {
  isSelected: boolean;
  conversation?: IChatConversation;
  // onSelectConversation(): void;
  // conversationSidebarOpen: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 15px',
    height: 85,
    background: colors.white,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.07)',
    borderRadius: 5,
    color: colors.text,
    transition: theme.transitions.create('all'),
    margin: '15px 0',
    fontSize: 13,
  },
  listItemSelected: {
    backgroundColor: colors.link,
    color: colors.white,
  },
  recentActivity: {
    fontSize: 13,
    whiteSpace: 'nowrap',
    marginBottom: 6,
  },
  container: {
    marginLeft: 2,
    height: 44,
    display: 'flex',
    alignItems: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
  },
}));

const displayName: string = 'John Krasinski';
const displayText: string =
  'Lorem, ipsum dolor sit amet consectetur adipisicing elit.';

const createdAt = sub(new Date(), { hours: 324 });
const unreadCount: number = 9;

const ConversationItem: React.FC<IConversationProps> = (props) => {
  const { isSelected, conversation } = props;
  const classes = useStyles();
  const isUnread = unreadCount > 0;
  return (
    <>
      <ListItem
        button
        disableGutters
        className={clsx(classes.root, {
          [classes.listItemSelected]: isSelected,
        })}
      >
        <ListItemAvatar>
          <Avatar alt={displayName} />
        </ListItemAvatar>
        <>
          <ListItemText
            primary={displayName}
            secondary={displayText}
            secondaryTypographyProps={{
              noWrap: true,
              color: 'textPrimary',
              variant: 'subtitle2',
            }}
          />
          <Box className={classes.container}>
            <Box className={classes.recentActivity}>
              {formatDistanceToNowStrict(new Date(createdAt), {
                addSuffix: true,
              })}
            </Box>
            {isUnread && <Typography>{unreadCount}</Typography>}
          </Box>
        </>
      </ListItem>
    </>
  );
};

export default ConversationItem;
