import React from 'react';
import styled from 'styled-components';

import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import { createStyles, fade, Theme } from '@material-ui/core/styles';

export const avatar: React.CSSProperties = {
  marginRight: 15,
};

export const buttonBackground: React.CSSProperties = {
  background: 'rgba(5, 198, 64, 0.2)',
};

export const customWidth: React.CSSProperties = {
  maxWidth: 50,
  paddingLeft: 35,
};

export const StyledCard = styled(Card)`
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
`;

export const bgWhite: React.CSSProperties = {
  background: '#ffffff',
};

export const StyledButton = styled(Button)`
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const addAthleteStyledButton: React.CSSProperties = {
  marginTop: 10,
  marginBottom: 10,
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(243, 244, 248, 0.3)',
      },
    },
    filter: {
      flexGrow: 1,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    appBarStyles: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      borderBottom: '1px solid #F3F4F8',
      padding: 10,
    },
  })
);
