import React, { useState } from 'react';

import Box from '@material-ui/core/Box';

import { AccountBillingInfo } from '../../../../shared/Components';

import { IScoutAccount } from '../../../../models/user';
import PaymentHistory from './SubscriptionProfileTable';
import MainHeader from './Header';

const ScoutSubscriptionProfile: React.FC<{}> = () => {
  const [scout, setScout] = useState<IScoutAccount>({} as IScoutAccount);
  return (
    <Box>
      <MainHeader scoutName={scout.name} />
      <PaymentHistory />
      <AccountBillingInfo scout={scout} />
    </Box>
  );
};

export default ScoutSubscriptionProfile;
