import React, { FC } from 'react';
import styled from 'styled-components';

import IconAvatar from '../../Avatar';

import AllAthletesIcon from '../../Icons/AllAthletesIcon';

const StyledAvatar = styled(IconAvatar)`
  background-color: rgba(47, 128, 237, 0.2);
`;

const AllAthletesAvatar: React.FC<{}> = () => {
  return (
    <StyledAvatar>
      <AllAthletesIcon />
    </StyledAvatar>
  );
};

export default AllAthletesAvatar;
