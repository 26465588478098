import { List } from '@material-ui/core';
import React from 'react';
import ConversationItem from './ConversationItem';

interface IConversationListProps {
  activeConversationId: string;
}

const conversationId = '8864c717-587d-472a-929a-8e5f298024da-0';

const ConversationList: React.FC<IConversationListProps> = (props) => {
  const { activeConversationId } = props;
  return (
    <List>
      <ConversationItem isSelected={activeConversationId === conversationId} />
    </List>
  );
};

export default ConversationList;
