import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { colors } from '../../../../theme/theme';

import footballerPlayerImage from '../../../../assets/football-player.png';

export const HeaderTextStyled = styled(Box)`
  padding-top: 10rem;
  padding-left: 5rem;
  padding-right: 21rem;
  @media (max-width: 375px) {
    flex-direction: column;
    padding-top: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const ContentContainerStyled = styled(Box)`
  background-color: ${colors.secondary};
  height: 700px;
  position: relative;
  background-image: url(${footballerPlayerImage});
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 375px) {
    height: 611px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const FootballerImageStyle = styled(Box)`
  position: absolute;
  top: 100px;
  right: -30px;
`;

export const DescriptionContentStyled = styled(Typography)`
  padding-right: 5rem;
  padding-top: 2rem;
`;

export const WatchVideosContainerStyled = styled(Box)`
  padding-top: 3rem;
  display: flex;
  align-items: center;
`;

export const WatchVideosLinkStyled = styled(Link)`
  color: ${colors.white};
  &: hover {
    color: ${colors.white};
  }
  font-size: 24px;
  padding-left: 1rem;
  @media (max-width: 375px) {
    font-size: 1rem;
  }
`;
