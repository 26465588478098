import React from 'react';
import { Paper, Box, Container, Typography } from '@material-ui/core';

import TableComponent from './Table';

import SearchButton from '../../../../../shared/Components/SearchButton';
import { AppBarComponent } from '../../../../../shared/Components';

const containerStyles: React.CSSProperties = {
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.07)',
  borderRadius: 5,
};

const titleStyles: React.CSSProperties = {
  flexGrow: 1,
};

const PaymentHistory: React.FC<{}> = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <Box style={containerStyles} component={Paper} mt={3}>
        {/* AppBar Component */}
        <AppBarComponent>
          <Typography style={titleStyles}>Payment History</Typography>
          <SearchButton />
        </AppBarComponent>
        {/* End AppBar Component */}
        <TableComponent />
        {/* Pagination Component */}
        {/* End pagination component */}
      </Box>
    </Container>
  );
};

export default PaymentHistory;
