import { colors, fontWeight } from '../../../theme/theme';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

export const listItemStyle: React.CSSProperties = {
  fontSize: 4,
  fontWeight: fontWeight.bold,
};

export const styledTitle: React.CSSProperties = {
  fontSize: 15,
  fontWeight: fontWeight.normal,
  color: colors.icon,
};

export const listItemText: React.CSSProperties = {
  fontSize: 13,
  fontWeight: fontWeight.normal,
};

export const appBarStyles: React.CSSProperties = {};

const lightColor = 'blue';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBarStyles: {
      backgroundColor: colors.backgroundOne,
      color: colors.backgroundTwo,
      paddingTop: 45,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 10,
        paddingTop: 15,
      },
    },
    menuButton: {
      marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
      padding: 6,
    },
    button: {
      borderColor: lightColor,
    },
    menuStyle: {
      marginLeft: '-90px',
      borderRadius: 5,
      [theme.breakpoints.down('sm')]: {
        marginLeft: '-10px',
      },
    },
  })
);
