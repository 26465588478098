import React, { useState } from 'react';

// import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { data } from '../../../../shared/data/carouselItems';

import { ButtonComponent, SignUp } from '../../../../shared/Components';
import discoverAthlete from '../../../../assets/discover-athletes.png';
import { colors } from '../../../../theme/theme';

import { ContentStyled, imageStyle, TitleStyled } from './style';
import './style.scss';
import ContentCarousel from './CarouselContent';

const title: string = `Kachezwe For Athletes`;
const discoveredContent: string = `
Kachezwe Provides Athletes with a platform to promote their development, 
skill set in order to showcase to interested parties and connect with other African players to support one another in a community format.
`;

const CAROUSELS = data.map((item) => {
  return {
    title: item.title,
    description: item.description,
  };
});

const BeDiscoveredSection: React.FC<{}> = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };
  return (
    <Box>
      <SignUp
        isOpen={openModal}
        handleDialogClose={handleModalClose}
        dialogTypeTitle={'Athlete'}
      />
      <Grid container>
        <Grid
          item
          md={6}
          lg={6}
          sm={6}
          xs={12}
          style={{ backgroundColor: colors.backgroundThree }}
        >
          <ContentStyled>
            {/* <TitleStyled>{title}</TitleStyled>
            <Typography variant="body1" style={{ fontSize: 24, marginTop: 30 }}>
              {discoveredContent}
            </Typography> */}
            <ContentCarousel carousels={CAROUSELS} />

            <ButtonComponent
              variant="contained"
              color="primary"
              onClick={handleModalOpen}
              classes={{ root: 'discover-button ' }}
            >
              Be Discovered
            </ButtonComponent>
          </ContentStyled>
        </Grid>
        <Grid item md={6} lg={6} sm={6} xs={12}>
          <img
            src={discoverAthlete}
            alt="Discover Athlete"
            style={imageStyle}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BeDiscoveredSection;
