import { IAuthenticatedUser } from '../../../models/auth';

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOG_OUT_USER_REQUEST = 'LOG_OUT_USER_REQUEST';
export const LOG_OUT_USER_SUCCESS = 'LOG_OUT_USER_SUCCESS';
export const LOG_OUT_USER_FAILURE = 'LOG_OUT_USER_FAILURE';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export interface AuthenticatedUserState {
  loggedIn: boolean;
  data?: IAuthenticatedUser;
}

export interface AuthenticatedState {
  authentication: AuthenticatedUserState;
}

interface LoginUserRequestAction {
  type: typeof LOGIN_USER_REQUEST;
  payload: AuthenticatedUserState;
}

interface LoginUserSuccessAction {
  type: typeof LOGIN_USER_SUCCESS;
  payload: AuthenticatedUserState;
}

interface LoginUserFailureAction {
  type: typeof LOGIN_USER_FAILURE;
  payload: AuthenticatedUserState;
}

interface LogoutUserRequestAction {
  type: typeof LOG_OUT_USER_REQUEST;
}

interface LogoutUserSuccessAction {
  type: typeof LOG_OUT_USER_SUCCESS;
  payload: AuthenticatedUserState;
}

interface LogoutUserFailureAction {
  type: typeof LOG_OUT_USER_FAILURE;
  payload: AuthenticatedUserState;
}

export type AuthActionTypes =
  | LoginUserRequestAction
  | LoginUserSuccessAction
  | LoginUserFailureAction
  | LogoutUserFailureAction
  | LogoutUserRequestAction
  | LogoutUserSuccessAction;
