import { Box, Table, TableContainer, TableProps } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { PropsWithChildren } from 'react';
import { fontFamily, fontWeight } from '../../../theme/theme';
import Scrollbar from '../Scrollbar';

/*Exploring on better ways to override default MUI tablecell and add it 
at the top of the tablecomponent */
const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: '5px 8px',
        height: '40px',
        fontFamily: fontFamily.sofiaPro,
        fontSize: 13,
        fontWeight: fontWeight.normal,
        borderBottom: '1px solid #F3F4F8',
      },
    },
  },
});

const TableWrapper: React.FC<PropsWithChildren<TableProps>> = (props) => {
  const { children } = props;
  return (
    <>
      <Scrollbar>
        <ThemeProvider theme={theme}>
          <Box style={{ minWidth: 900 }}>
            <TableContainer>
              <Table>{children}</Table>
            </TableContainer>
          </Box>
        </ThemeProvider>
      </Scrollbar>
    </>
  );
};

TableWrapper.propTypes = {
  children: PropTypes.any,
};

export default TableWrapper;
