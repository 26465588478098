import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import { colors, fontWeight } from '../../../../theme/theme';
import { TitleHeader } from '../../../../shared/Components';
import { featuredPlayers } from '../../../../shared/data/featuredPlayersMock';
import { BoxStyled, imageStyle, playerInfoStyle } from './style';

const FeaturedPlayers: React.FC<{}> = () => {
  return (
    <>
      <BoxStyled>
        <TitleHeader style={{ color: colors.text }}>
          Featured Players
        </TitleHeader>
      </BoxStyled>

      <Box>
        <Grid container spacing={0}>
          {featuredPlayers.map((player, index) => (
            <Grid item key={index} xs={3}>
              <Box style={{ position: 'relative' }}>
                <img
                  src={player.playerImage}
                  alt={player.playerName}
                  style={imageStyle}
                />
                <Box style={playerInfoStyle}>
                  <Typography
                    color="textSecondary"
                    style={{ fontSize: 16, fontWeight: fontWeight.mediumTwo }}
                  >
                    {player.playerName}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    style={{ fontSize: 14, fontWeight: 400 }}
                  >
                    {player.playerSportType}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default FeaturedPlayers;
