import React from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { ButtonComponent } from '../../shared/Components';
import { RatingIcon } from '../../shared/Components/Icons';
import { fontWeight, fontFamily } from '../../theme/theme';

interface IMainHeaderProps {
  imageUrl: string;
  athleteProfileImageUrl?: string;
  athleteName: string;
  athleteAge: number;
  athleteGender: string;
  athleteRating: number;
  athleteRanking: number;
}

const headerTitle: string = 'Get the best basketball rankings and videos';

const headerBottomSectionStyled: React.CSSProperties = {
  bottom: 50,
  position: 'absolute',
  width: '100%',
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
};

const profileImageStyle: React.CSSProperties = {
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: '100%',
  borderRadius: '50%',
};

const MainHeader: React.FC<IMainHeaderProps> = (props) => {
  const history = useHistory();
  const {
    athleteProfileImageUrl,
    athleteName,
    athleteAge,
    athleteGender,
    athleteRanking,
    athleteRating,
  } = props;

  const navigateTo = () => history.push('/');

  const HeaderContainerStyled = styled(Box)`
    width: 100%;
    height: 500px;
    background: url(${props.imageUrl}) no-repeat;
    background-size: contain;
    position: relative;
  `;

  const RatingsComponents = (playerRanking: number) => {
    const ratings = [];
    for (let index = 0; index < playerRanking; index++) {
      ratings.push(<RatingIcon />);
    }
    return ratings;
  };

  return (
    <HeaderContainerStyled>
      <Box
        style={{ position: 'absolute', right: 30, top: 10, textAlign: 'right' }}
      >
        <Box style={{ position: 'relative' }}>
          <Typography
            color="textSecondary"
            variant="h4"
            style={{ position: 'absolute', top: 20, right: 50 }}
          >
            #
          </Typography>
          <Typography
            style={{ fontWeight: fontWeight.bold, fontSize: 84 }}
            color="textSecondary"
            variant="h4"
          >
            {athleteRanking}
          </Typography>
        </Box>
        <Typography color="textSecondary" variant="h6">
          National Ranking
        </Typography>
      </Box>
      <Box style={headerBottomSectionStyled}>
        <Box style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
          <img
            src={athleteProfileImageUrl}
            alt={athleteName}
            style={profileImageStyle}
          />
          <Box style={{ marginLeft: 20 }}>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ fontSize: 24, fontWeight: fontWeight.bold }}
            >
              {athleteName} | {athleteGender} | {athleteAge}
            </Typography>
            {RatingsComponents(athleteRating)}
          </Box>
        </Box>
        <Box style={{ marginRight: 30 }}>
          <ButtonComponent
            style={{ marginRight: 20, marginLeft: 20 }}
            variant="contained"
            color="primary"
          >
            Upload Videos
          </ButtonComponent>
          <ButtonComponent variant="contained" color="primary">
            Edit Profile
          </ButtonComponent>
          <ButtonComponent
            style={{ marginLeft: 20 }}
            variant="contained"
            color="primary"
            onClick={navigateTo}
          >
            Follow Athlete
          </ButtonComponent>
        </Box>
      </Box>
    </HeaderContainerStyled>
  );
};

export default MainHeader;
