import React, { PropsWithChildren } from 'react';

import Typography, { TypographyProps } from '@material-ui/core/Typography';

const TitleHeader: React.FC<PropsWithChildren<TypographyProps>> = (props) => {
  const { children, ...rest } = props;
  return (
    <Typography variant="h1" {...rest}>
      {children}
    </Typography>
  );
};

export default TitleHeader;
