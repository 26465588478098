import React, { FC, PropsWithChildren } from 'react';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';

import Hidden from '@material-ui/core/Hidden';

import { useStyles } from './styles';

const drawerWidth = 290;

const Navigator: FC<React.PropsWithChildren<DrawerProps>> = (props) => {
  const { children, ...rest } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Hidden smUp implementation="js">
        <Drawer
          PaperProps={{ style: { width: drawerWidth } }}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{ paper: classes.paper }}
        >
          {children}
        </Drawer>
      </Hidden>

      <Hidden xsDown implementation="css">
        <Drawer
          PaperProps={{ style: { width: drawerWidth } }}
          classes={{ paper: classes.paper }}
          open
          variant="persistent"
        >
          {children}
        </Drawer>
      </Hidden>
    </>
  );
};

export default Navigator;
