import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Container from '@material-ui/core/Container';

import MainHeader from './Header';
import { ContentComponent } from '../../../../shared/Layout/PublicPage';
import UserService from '../../../../shared/services/user.service';
import { IAthleteProfile } from '../../../../models/athlete';
import { AccountBillingInfo } from '../../../../shared/Components';
import PersonalInfo from './PersonalInfo';
import { IScoutAccount } from '../../../../models/user';

import headerImage from '../../../../assets/player-profile-default-header.png';
import AthleteListView from './BookmarkedAthletes';

const ScoutProfileDashboard: React.FC<{}> = () => {
  const [athlete, setAthlete] = useState<IAthleteProfile>(
    {} as IAthleteProfile
  );
  const [scout, setScout] = useState<IScoutAccount>({} as IScoutAccount);

  const WEB_URL = process.env.REACT_APP_KACHEZWE_WEB_URL;
  const { id } = useParams();

  // fetch all athlete data
  useEffect(() => {
    UserService.getAthlete(id)
      .then((response) => {
        setAthlete(response.data);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.error(resMessage);
      });
  }, [id]);

  return (
    <>
      <Container disableGutters>
        <ContentComponent>
          <MainHeader
            imageUrl={headerImage}
            athleteProfileImageUrl={`${WEB_URL}${athlete.profile_photo}`}
            athleteName={athlete.name}
            athleteUserId={id}
          />
          {/* <AthleteContent athlete={athlete} /> */}
          <PersonalInfo />
          <AthleteListView />
          <AccountBillingInfo scout={scout} />
        </ContentComponent>
      </Container>
    </>
  );
};

export default ScoutProfileDashboard;
