import { Box, Container } from '@material-ui/core';
import React from 'react';
import { IAthleteProfile } from '../../../../../../models/athlete';
import NewAthletesTable from './NewAthletesTable';

interface IProps {
  athletes: Array<IAthleteProfile>;
  loading: boolean;
}

const title: string = 'New Athletes';

const AthleteListView: React.FC<IProps> = (props) => {
  const { athletes, loading } = props;

  return (
    <Container maxWidth={false} disableGutters>
      <Box mt={3}>
        <NewAthletesTable athletes={athletes} loading={loading} />
      </Box>
    </Container>
  );
};

export default AthleteListView;
