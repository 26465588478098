import React, { FC, useState, useEffect } from 'react';
import {
  Paper,
  Box,
  Container,
  TableRow,
  TableBody,
  Table,
  Grid,
  TableProps,
  TableContainer,
} from '@material-ui/core';

import {
  AppBarComponent,
  ButtonComponent,
  DownloadButton,
} from '../../../../shared/Components';

import { IAthleteAccount } from '../../../../models/user';
import {
  containerStyles,
  buttonStyles,
  gridStyles,
  BottomGrid,
  StyledImage,
  StyledGrid,
  TableCell,
} from './styles';

const TableComponent: FC<React.PropsWithChildren<TableProps>> = (props) => {
  const { children, ...rest } = props;
  return (
    <StyledGrid item xs={12} sm={6} {...rest}>
      <Table {...rest}>
        <TableBody>{children}</TableBody>
      </Table>
    </StyledGrid>
  );
};

const WEB_URL = process.env.REACT_APP_KACHEZWE_WEB_URL;

const SettingsPage: React.FC<{}> = () => {
  const [athlete, setAthlete] = useState<IAthleteAccount>(
    {} as IAthleteAccount
  );

  return (
    <Container maxWidth={false} disableGutters>
      <Box style={containerStyles} component={Paper} mt={3}>
        {/* AppBar Component */}
        <AppBarComponent style={{ alignItems: 'end' }}>
          <ButtonComponent style={buttonStyles}>Edit</ButtonComponent>
        </AppBarComponent>
        {/* End AppBar Component */}

        <TableContainer>
          <Grid container style={gridStyles}>
            <TableComponent>
              <TableRow>
                <TableCell style={{ width: 80 }}>
                  <StyledImage
                    alt="Profile Photo"
                    src={athlete.profile_photo}
                  />
                </TableCell>
                <TableCell>
                  <TableRow>
                    <TableCell>Email: </TableCell>
                    <TableCell>{athlete.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>First Name: </TableCell>
                    <TableCell>{athlete.first_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Last Name:</TableCell>
                    <TableCell>{athlete.last_name}</TableCell>
                  </TableRow>
                </TableCell>
              </TableRow>
            </TableComponent>

            <TableComponent style={{ width: '42%' }}>
              <TableRow>
                <TableCell>Telephone: </TableCell>
                <TableCell>{athlete.telephone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Gender: </TableCell>
                <TableCell>{athlete.gender}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Password:</TableCell>
                <TableCell>{athlete.password}</TableCell>
              </TableRow>
            </TableComponent>
          </Grid>

          <Grid container>
            <TableComponent style={{ paddingLeft: 100 }}>
              <TableRow>
                <TableCell>Country: </TableCell>
                <TableCell>{athlete.country}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>School/Company affiliation: </TableCell>
                <TableCell>{athlete.attending_school}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>School/Company Email:</TableCell>
                <TableCell>{athlete.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>School/Company Phone:</TableCell>
                <TableCell>{athlete.telephone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date of Birth(should match your ID):</TableCell>
                <TableCell> {athlete.date_of_birth} </TableCell>
              </TableRow>
            </TableComponent>

            <TableComponent style={{ width: '69%' }}>
              <TableRow>
                <TableCell>City/Neighborhood: </TableCell>
                <TableCell>{athlete.city}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Role(scout, coach etc.): </TableCell>
                <TableCell>{athlete.role_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Passport or Driver License Number:</TableCell>
                <TableCell>{athlete.reach}</TableCell>
              </TableRow>
            </TableComponent>
          </Grid>

          <BottomGrid container>
            <Grid item xs={12} sm={6}>
              <DownloadButton>Government National ID.pdf</DownloadButton>
              <DownloadButton>
                Proof of School/Company Affiliation.pdf
              </DownloadButton>
            </Grid>
          </BottomGrid>
        </TableContainer>
      </Box>
    </Container>
  );
};
export default SettingsPage;
