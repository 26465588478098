import React from 'react';

import Box from '@material-ui/core/Box';

import { RatingIcon } from '../Icons';

interface IProps {
  rating: number;
}

const RatingsComponent: React.FC<IProps> = (props) => {
  const { rating } = props;

  const ratingsComponents = (rating: number) => {
    const ratingsComponentCount = [];
    for (let i = 0; i < rating; i++) {
      ratingsComponentCount.push(<RatingIcon key={i} />);
    }
    return ratingsComponentCount;
  };

  return <>{ratingsComponents(rating)}</>;
};

export default RatingsComponent;
