import React from 'react';

import Container from '@material-ui/core/Container';

import MainHeader from './Header';
import {
  HeaderComponent,
  ContentComponent,
} from '../../shared/Layout/PublicPage';
import { NavigationBar, PublicFooterComponent } from '../../shared/Components';
import Content from './Content';

import headerImage from '../../assets/basketball-header.png';

const BasketballPage: React.FC<{}> = () => {
  return (
    <>
      <Container>
        <HeaderComponent>
          <NavigationBar></NavigationBar>
        </HeaderComponent>
        <ContentComponent>
          <MainHeader imageUrl={headerImage} />
          <Content />
        </ContentComponent>
        <PublicFooterComponent />
      </Container>
    </>
  );
};

export default BasketballPage;
