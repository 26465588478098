import { IAlert } from '../../../models/alert';

export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_FAILURE = 'ALERT_FAILURE';
export const ALERT_CLEAR = 'ALERT_CLEAR';

export interface AlertState {
  alert: IAlert;
}

interface AlertSuccessAction {
  type: typeof ALERT_SUCCESS;
  payload: AlertState;
}

interface AlertFailureAction {
  type: typeof ALERT_FAILURE;
  payload: AlertState;
}

interface AlertClearAction {
  type: typeof ALERT_CLEAR;
}

export type AlertActionTypes =
  | AlertSuccessAction
  | AlertFailureAction
  | AlertClearAction;
