import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { ButtonComponent, SignUp } from '../../../../shared/Components/';

import {
  HeaderContainerStyled,
  HeaderTextStyled,
  HeaderLinksStyled,
  ArrowDownwardIconContainerStyled,
  LearnMoreLinkStyled,
  LearnMoreContainerStyled,
} from './styles';

const headerContent: string = `Finding & Developing Youth Talent In East Africa`;
const buttonContent: string = 'Be Discovered';

const MainHeader: React.FC<{}> = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <SignUp
        isOpen={openModal}
        handleDialogClose={handleModalClose}
        dialogTypeTitle={'Athlete'}
      />
      <HeaderContainerStyled>
        <HeaderTextStyled>
          <Typography variant="h1" color={'textSecondary'}>
            {headerContent}
          </Typography>
        </HeaderTextStyled>
        <HeaderLinksStyled>
          <ButtonComponent
            onClick={handleModalOpen}
            variant="contained"
            color="primary"
          >
            {buttonContent}
          </ButtonComponent>
          <LearnMoreContainerStyled>
            <LearnMoreLinkStyled href="#">Learn More</LearnMoreLinkStyled>
          </LearnMoreContainerStyled>
        </HeaderLinksStyled>
      </HeaderContainerStyled>
      <ArrowDownwardIconContainerStyled>
        <ArrowDownwardIcon fontSize="small" />
      </ArrowDownwardIconContainerStyled>
    </>
  );
};

export default MainHeader;
