import React from 'react';

import { ReactComponent as FilterIcon } from '../../../assets/filter.svg';

import { Box } from '@material-ui/core/';
import ButtonComponent from '../Button';

import { fontWeight, colors } from '../../../theme/theme';

const filter: React.CSSProperties = {
  flexGrow: 1,
};
const buttonStyle: React.CSSProperties = {
  color: colors.text,
  fontWeight: fontWeight.mediumTwo,
  height: 35,
  boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.15)',
  background: colors.white,
  border: '0.5px solid #e5e5e5',
  padding: '18px 30px',
};
const FilterButton: React.FC<{}> = () => {
  return (
    <Box style={filter}>
      <ButtonComponent
        variant="contained"
        startIcon={<FilterIcon />}
        style={buttonStyle}
      >
        Filter
      </ButtonComponent>
    </Box>
  );
};

export default FilterButton;
