import React, { useEffect, useState } from 'react';

import AthleteListView from './AthleteTable';
import AthletesStatsComponent from './AthletesStats';

import UserService from '../../../../shared/services/user.service';
import { IAthleteProfile } from '../../../../models/athlete';

const AthletesPage: React.FC<{}> = () => {
  const [athletes, setAthletes] = useState<Array<IAthleteProfile>>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  // fetch all athletes data
  useEffect(() => {
    UserService.getAthletes()
      .then((response) => {
        setAthletes(response.data);
        setLoading(false);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.error(resMessage);
      });
  }, []);
  return (
    <>
      <AthletesStatsComponent athletes={athletes} />
      <AthleteListView loading={loading} athletes={athletes} />
    </>
  );
};

export default AthletesPage;
