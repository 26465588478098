import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader: React.FC<{}> = () => {
  return (
    <Box m="auto" padding="30px">
      <CircularProgress color="primary" />
    </Box>
  );
};

export default Loader;
