import axios from 'axios';
import { IUser } from '../../models/user';

const API_URL = process.env.REACT_APP_KACHEZWE_API_SERVICE_URL;

class AuthService {
  login(username: string, password: string) {
    return axios
      .post(API_URL + '/auth/login', {
        username,
        password,
      })
      .then((response) => {
        if (response.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.clear();
  }

  registerUser(data: IUser) {
    return axios.post(API_URL + '/auth/register-user', data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  getCurrentUser() {
    const userStore = localStorage.getItem('user') || '{}';
    return JSON.parse(userStore);
  }
}

export default new AuthService();
