import React, { FC } from 'react';
import styled from 'styled-components';

import IconAvatar from '../../Avatar';

import ProfileIcon from '../../Icons/UpdatedProfilesIcon';

const StyledAvatar = styled(IconAvatar)`
  background-color: rgba(36, 63, 149, 0.2);
`;

const ProfileAvatar: React.FC<{}> = () => {
  return (
    <StyledAvatar>
      <ProfileIcon />
    </StyledAvatar>
  );
};

export default ProfileAvatar;
