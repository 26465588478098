import { Box, Hidden, Paper } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { IAthleteProfile } from '../../../../../../models/athlete';
import {
  AppBarComponent,
  FilterButton,
  PaginationButton,
  SearchButton,
} from '../../../../../../shared/Components';
import TableComponent from './Table';

interface IProps {
  loading: boolean;
  athletes: Array<IAthleteProfile>;
}

const StyledContainer = styled(Box)`
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
`;

const NewAthletesTable: React.FC<IProps> = (props) => {
  const { loading, athletes } = props;

  return (
    <StyledContainer component={Paper}>
      {/* AppBar Component */}
      <AppBarComponent>
        <FilterButton />
        <Hidden smDown>
          <SearchButton />
        </Hidden>
      </AppBarComponent>
      {/* End AppBar Component */}
      <TableComponent athletes={athletes} loading={loading} />
      {/* Pagination Component */}
      <PaginationButton />
      {/* End pagination component */}
    </StyledContainer>
  );
};

export default NewAthletesTable;
