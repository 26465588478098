import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const ContentContainer = styled(Box)`
  left: 0;
  text-align: center;
  position: absolute;
  top: 35vh;
  width: 100%;
`;

export const buttonMargin: React.CSSProperties = {
  marginTop: '5vh',
};

export const LinkText = styled(Typography)`
  padding-top: 5rem;
`;

export const ErrorText = styled(Typography)`
  @media (max-width: 375px) {
    font-size: 32px;
  }
`;
