export default function authHeader() {
  const userStore = localStorage.getItem('user') || '{}';
  const user = JSON.parse(userStore);

  if (user && user.access_token) {
    // return { Authorization: 'Bearer ' + user.access_token };
    return user.access_token;
  }
  // else {
  //   return {};
  // }
}
