import React, { useState, useEffect } from 'react';
import {
  Paper,
  Box,
  Grid,
  Container,
  Typography,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  Chip,
  ThemeProvider,
  List,
  ListItem,
} from '@material-ui/core';

import { AppBarComponent, ButtonComponent } from '../..';

import { colors } from '../../../../theme/theme';
import { MessageCircle as MessageIcon } from 'react-feather';
import { IScoutAccount } from '../../../../models/user';
// Styles
import {
  containerStyles,
  titleStyles,
  gridStyles,
  CustomTable,
  chipStyles,
  TableCell,
} from './styles';

interface IScoutProps {
  scout: IScoutAccount;
}

const WEB_URL = process.env.REACT_APP_KACHEZWE_WEB_URL;

const AccountBillingInfo: React.FC<IScoutProps> = (props: IScoutProps) => {
  const {
    name,
    account_status,
    account_creation_date,
    last_order_date,
    payment_overdue,
    payment_details,
    total_billing,
    ordered_product,
    email,
    billing_address,
    next_payment_date,
  } = props.scout;

  return (
    <Container maxWidth={false} disableGutters>
      <Box style={containerStyles} component={Paper} mt={3}>
        {/* AppBar Component */}
        <AppBarComponent>
          <Typography style={titleStyles}>Account & Billing Info</Typography>
          <ButtonComponent
            variant="contained"
            startIcon={<MessageIcon />}
            style={{
              background: colors.primary,
            }}
          >
            Message Scout
          </ButtonComponent>
        </AppBarComponent>
        {/* End AppBar Component */}

        <TableContainer>
          <Grid container style={{ paddingTop: 20 }}>
            <Grid item xs={12} sm={6} style={gridStyles}>
              <CustomTable>
                <TableBody>
                  <TableRow>
                    <TableCell>Status: </TableCell>
                    <TableCell>{`${account_status}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Start date: </TableCell>
                    <TableCell>{`${account_creation_date}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Last order date:</TableCell>
                    <TableCell>{`${last_order_date}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Next payment date:</TableCell>
                    <TableCell>
                      {`${next_payment_date}`}{' '}
                      {payment_overdue && (
                        <Chip label="Overdue" disabled style={chipStyles} />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </CustomTable>
            </Grid>

            <Grid item xs={12} sm={6} style={{ paddingLeft: 20 }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Product: </TableCell>
                    <TableCell>{`${ordered_product}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total: </TableCell>
                    <TableCell>{`${total_billing}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Payment:</TableCell>
                    <TableCell>{`${payment_details}`}</TableCell>
                  </TableRow>
                  <TableRow></TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              background: 'rgba(243, 244, 248, 0.3)',
              paddingBottom: 30,
            }}
          >
            <Grid item xs={12} sm={6} style={gridStyles}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ width: 110 }}>
                      Billing Address:{' '}
                    </TableCell>
                    <TableCell>
                      <List>
                        <ListItem>{`${name}`}</ListItem>
                        <ListItem>{`${billing_address}`}</ListItem>
                        <ListItem>{`${email}`}</ListItem>
                      </List>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </TableContainer>
      </Box>
    </Container>
  );
};
export default AccountBillingInfo;
