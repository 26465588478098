import { CSSProperties } from 'react';
import { colors, fontWeight } from '../../../../theme/theme';

export const bottomContent: React.CSSProperties = {
  marginTop: 45,
  paddingLeft: 120,
  fontStyle: 'italic',
};

export const spanColor: React.CSSProperties = {
  color: colors.secondary,
};

export const mediaStyles: React.CSSProperties = {
  height: 65,
  width: 65,
  marginBottom: 30,
};

export const dividerStyles: React.CSSProperties = {
  width: 50,
  height: 2,
  backgroundColor: colors.primary,
  marginLeft: '45%',
  marginBottom: 20,
};

export const cardStyles: React.CSSProperties = {
  width: 641,
  height: 417,
  borderRadius: 15,
  padding: '50px 70px',
  marginTop: 70,
  border: '1px solid #F2F2F2',
  background: colors.backgroundOne,
  boxShadow: 'none',
  textAlign: 'center',
  marginBottom: 30,
};

export const textOne: React.CSSProperties = {
  fontWeight: fontWeight.normal,
  fontSize: 16,
  marginBottom: 20,
};

export const textTwo: React.CSSProperties = {
  fontSize: 15,
  fontWeight: fontWeight.normal,
  lineHeight: '23px',
};
