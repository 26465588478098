import React, { useState } from 'react';
import { DashboardHeader } from '../../../../../shared/Components';
import { TableContainer, listItemStyle } from '../styles';
import { List, ListItem, ListItemText } from '@material-ui/core';

import { IScoutAccount } from '../../../../../models/user';

const BillingAddress: React.FC<{}> = () => {
  const [scout, setScout] = useState<IScoutAccount>({} as IScoutAccount);
  return (
    <>
      <DashboardHeader>Billing Address</DashboardHeader>
      <TableContainer>
        <List>
          <ListItem disableGutters style={listItemStyle}>
            <ListItemText>{scout.name}</ListItemText>
            <ListItemText>{scout.email}</ListItemText>
            <ListItemText>{scout.billing_address}</ListItemText>
          </ListItem>
        </List>
      </TableContainer>
    </>
  );
};

export default BillingAddress;
