import { Box, Paper } from '@material-ui/core';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IAthleteProfile } from '../../../../../../models/athlete';
import {
  AppBarComponent,
  PaginationButton,
  SearchButton,
} from '../../../../../../shared/Components';
import { containerStyles } from './styles';
import TableComponent from './Table';

interface IProps {
  loading: boolean;
  athletes: Array<IAthleteProfile>;
}

const NewAthletesTable: React.FC<IProps> = (props) => {
  const { loading, athletes } = props;

  return (
    <PerfectScrollbar>
      <Box style={containerStyles} component={Paper}>
        {/* AppBar Component */}
        <AppBarComponent>
          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SearchButton />
          </Box>
        </AppBarComponent>
        {/* End AppBar Component */}
        <TableComponent athletes={athletes} loading={loading} />
        {/* Pagination Component */}
        <PaginationButton />
        {/* End pagination component */}
      </Box>
    </PerfectScrollbar>
  );
};

export default NewAthletesTable;
