import React, { FC } from 'react';
import styled from 'styled-components';

import IconAvatar from '../../Avatar';

import { ScoutIcon } from '../../Icons';

const StyledAvatar = styled(IconAvatar)`
  background-color: rgba(243, 161, 0, 0.2);
`;
const color = '#F3A100';
const ScoutsAvatar: React.FC<{}> = () => {
  return (
    <StyledAvatar>
      <ScoutIcon iconColor={color} />
    </StyledAvatar>
  );
};

export default ScoutsAvatar;
