import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {
  VideoComponent,
  AlertComponent,
  Loader,
} from '../../../../../shared/Components';

import UserService from '../../../../../shared/services/user.service';
import { getHttpErrorMessage } from '../../../../../shared/errors/http';
import { IMediaResponse } from '../../../../../models/media';

const titleStyled: React.CSSProperties = {
  borderBottom: '1px solid #C4C4C4',
  marginBottom: 10,
  paddingBottom: 20,
};

const AthleteDetails: React.FC<{}> = () => {
  const [httpError, setHttpError] = useState<AxiosError | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mediaData, setMediaData] = useState<Array<IMediaResponse>>([]);
  const { id } = useParams();
  // const WEB_URL = process.env.REACT_APP_KACHEZWE_WEB_URL;

  useEffect(() => {
    setIsLoading(true);
    UserService.getMediaFiles(id, 'video')
      .then((response) => {
        console.log(response.data);
        setMediaData(response.data);
        setIsLoading(false);
      })
      .catch((error: AxiosError) => {
        setHttpError(getHttpErrorMessage(error));
      });
  }, []);

  return (
    <Box>
      <Typography variant="h5" style={titleStyled}>
        Images/Videos
      </Typography>

      {httpError && (
        <AlertComponent severity="error">
          Network error: {httpError}
        </AlertComponent>
      )}

      <Grid container spacing={2}>
        {!mediaData && (
          <AlertComponent severity="warning">
            No Videos are available
          </AlertComponent>
        )}

        {isLoading ? (
          <Loader />
        ) : (
          mediaData.map((mediaObject: IMediaResponse) => (
            <Grid key={mediaObject.media_id} item lg={6} xs={12}>
              <VideoComponent
                videoTitle={mediaObject.media_name}
                videoSource={mediaObject.media_path}
              />
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
};

export default AthleteDetails;
