import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { AxiosError, AxiosResponse } from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';

import { TextInput } from '../Input';
import ButtonComponent from '../../Button';
import { ValidationErrorMessage } from '../Validation';
import AlertComponent from '../../Alert';

import { IMedia } from '../../../../models/media';
import UserService from '../../../../shared/services/user.service';
import { getHttpErrorMessage } from '../../../../shared/errors/http';

const mediaTypes = [
  {
    value: '',
    label: 'Select media type',
  },
  {
    value: 'video',
    label: 'Video',
  },
  {
    value: 'photo',
    label: 'Photo',
  },
];

const MediaUploadForm: React.FC<{}> = () => {
  const history = useHistory();
  const { register, handleSubmit, watch, errors } = useForm<Partial<IMedia>>();
  const [httpError, setHttpError] = useState<AxiosError | null>(null);
  const [successAlert, showSuccessAlert] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { id } = useParams();

  const [form, setFormState] = useState<Partial<IMedia>>({
    media_name: '',
    media_type: '',
  });

  const onSubmit = async () => {
    setIsLoading(true);
    await UserService.addMediaFile(id, form, selectedFile)
      .then((response: AxiosResponse) => {
        console.log(response.data);
        showSuccessAlert(true);
        setOpenSnackbar(true);
        setIsLoading(false);
        setTimeout(function () {
          history.push(`/dashboard/admin/athlete-profile/${id}`);
        }, 2000);
      })
      .catch((error: AxiosError) => {
        setHttpError(getHttpErrorMessage(error));
      });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    setSelectedFile(file);
  };

  return (
    <>
      {httpError && (
        <AlertComponent severity="error">
          Network error: {httpError}
        </AlertComponent>
      )}
      {successAlert && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleAlertClose}
        >
          <AlertComponent onClose={handleAlertClose} severity="success">
            Athlete has been registered successfully. Redirecting now!
          </AlertComponent>
        </Snackbar>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              id="media-name"
              type="text"
              placeholder="MediaName"
              label="Media Name"
              fullWidth
              size="medium"
              icon="description"
              name="media_name"
              onChange={onChange}
              value={form.media_name}
              inputRef={register({ required: true })}
            />
            {errors.media_name && (
              <ValidationErrorMessage message="This field is required" />
            )}
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="media-type"
              select
              placeholder="Media Type"
              label="Media Type"
              fullWidth
              size="medium"
              icon="description"
              name="media_type"
              onChange={onChange}
              value={form.media_type}
              SelectProps={{
                native: true,
              }}
              inputRef={register({ required: true })}
            >
              {mediaTypes.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextInput>
            {errors.media_type && (
              <ValidationErrorMessage message="This field is required" />
            )}
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="media-file"
              type="file"
              fullWidth
              size="medium"
              name="media_file"
              onChange={handleFileInput}
            />
            {errors.media_file && (
              <ValidationErrorMessage message="This field is required" />
            )}
          </Grid>
          <ButtonComponent
            variant="contained"
            color="primary"
            type="submit"
            style={{
              marginTop: 20,
              marginBottom: 20,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Upload
            {isLoading && (
              <CircularProgress
                style={{
                  color: '#FFFFFF',
                  marginLeft: 10,
                }}
                size={20}
              />
            )}
          </ButtonComponent>
        </Grid>
      </form>
    </>
  );
};

export default MediaUploadForm;
