import axios, { AxiosError } from 'axios';
import { Dispatch } from 'redux';

import {
  AuthActionTypes,
  AuthenticatedUserState,
  LOG_OUT_USER_SUCCESS,
  LOG_OUT_USER_REQUEST,
  LOG_OUT_USER_FAILURE,
} from '../../types/auth';
import { successAlert, errorAlert } from '../alert';

import authHeader from '../../../services/api/auth/auth.header';

const API_URL = process.env.REACT_APP_KACHEZWE_API_SERVICE_URL;

// ACTION CREATORS

export const logoutUserRequest = (): AuthActionTypes => {
  return {
    type: LOG_OUT_USER_REQUEST,
  };
};

export const logoutUserSuccess = (
  payload: AuthenticatedUserState
): AuthActionTypes => {
  return {
    type: LOG_OUT_USER_SUCCESS,
    payload,
  };
};

export const logoutUserFailure = (
  payload: AuthenticatedUserState
): AuthActionTypes => {
  return {
    type: LOG_OUT_USER_FAILURE,
    payload,
  };
};

// METHODS

export const logoutUser = () => (dispatch: Dispatch) => {
  try {
    axios
      .delete(API_URL + '/auth/logout', {
        headers: { Authorization: `Bearer ${authHeader()}` },
      })
      .then(() => {
        localStorage.clear();
        dispatch(logoutUserRequest());
        dispatch(
          logoutUserSuccess({
            loggedIn: false,
          })
        );
        dispatch(
          successAlert({
            alertType: 'success',
            alertMessage: 'Log out was successful',
          })
        );
      })
      .catch((error: AxiosError) => {
        console.error(error.message);
        dispatch(
          logoutUserFailure({
            loggedIn: true,
          })
        );
        dispatch(
          errorAlert({
            alertType: 'error',
            alertMessage: 'Unable to log out',
          })
        );
      });
  } catch (error) {
    console.error(error.message);
  }
};
