import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const StyledContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const ContentContainer = styled(Box)`
  width: 102px;
`;

export const BodyTextContainer = styled(Typography)`
  flexgrow: 2;
  padding: 0 100px;
  minwidth: 240px;
`;

export const BodyText = styled(Typography)`
  height: 2rem;
`;
