import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';

import { ButtonComponent } from '../../../../shared/Components';
import { RatingIcon } from '../../../../shared/Components/Icons';
import { fontWeight } from '../../../../theme/theme';
import { UploadMedia } from '../../../../shared/Components/Dialog';
import { headerBottomSectionStyled, profileImageStyle } from './styles';

interface IMainHeaderProps {
  imageUrl: string;
  athleteProfileImageUrl?: string;
  athleteName: string;
  athleteAge: number;
  athleteGender: string;
  athleteRating: number;
  athleteRanking: number;
  athleteUserId: number;
}

const MainHeader: React.FC<IMainHeaderProps> = (props: IMainHeaderProps) => {
  const {
    athleteProfileImageUrl,
    athleteName,
    athleteAge,
    athleteGender,
    athleteRanking,
    athleteRating,
    athleteUserId,
  } = props;

  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);

  const history = useHistory();

  const HeaderContainerStyled = styled(Box)`
    width: 100%;
    height: 500px;
    background: url(${props.imageUrl}) no-repeat;
    background-size: contain;
    position: relative;
  `;

  const openProfileEditor = () => {
    history.push(`/dashboard/admin/edit-athlete/${athleteUserId}`);
  };

  const RatingsComponents = (playerRanking: number) => {
    const ratings = [];
    for (let index = 0; index < playerRanking; index++) {
      ratings.push(<RatingIcon />);
    }
    return ratings;
  };

  const handleUploadModalOpen = () => {
    setOpenUploadModal(true);
  };

  const handleUploadModalClose = () => {
    setOpenUploadModal(false);
  };

  return (
    <HeaderContainerStyled>
      <UploadMedia
        isOpen={openUploadModal}
        handleDialogClose={handleUploadModalClose}
      />
      <Box
        style={{ position: 'absolute', right: 30, top: 10, textAlign: 'right' }}
      >
        <Box style={{ position: 'relative' }}>
          <Typography
            color="textSecondary"
            variant="h4"
            style={{ position: 'absolute', top: 20, right: 50 }}
          >
            #
          </Typography>
          <Typography
            style={{ fontWeight: fontWeight.bold, fontSize: 84 }}
            color="textSecondary"
            variant="h4"
          >
            {athleteRanking}
          </Typography>
        </Box>
        <Typography color="textSecondary" variant="h6">
          National Ranking
        </Typography>
      </Box>
      <Box style={headerBottomSectionStyled}>
        <Box style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
          <img
            src={athleteProfileImageUrl}
            alt={athleteName}
            style={profileImageStyle}
          />
          <Box style={{ marginLeft: 20 }}>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ fontSize: 24, fontWeight: fontWeight.bold }}
            >
              {athleteName} | {athleteGender} | {athleteAge}
            </Typography>
            {RatingsComponents(athleteRating)}
          </Box>
        </Box>
        <Box style={{ marginRight: 30 }}>
          <ButtonComponent
            style={{ marginRight: 20, marginLeft: 20 }}
            variant="contained"
            color="primary"
            onClick={handleUploadModalOpen}
          >
            Upload Media
          </ButtonComponent>
          <ButtonComponent
            variant="contained"
            color="primary"
            onClick={openProfileEditor}
          >
            Edit Profile
          </ButtonComponent>
        </Box>
      </Box>
    </HeaderContainerStyled>
  );
};

export default MainHeader;
