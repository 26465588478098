import styled from 'styled-components';

import { colors } from '../../../theme/theme';
import { FooterComponent } from '../../Layout/PublicPage';

export const FooterComponentStyled = styled(FooterComponent)`
  background-color: ${colors.backgroundTwo};
  color: white;
  padding: 80px 60px;
  @media (max-width: 375px) {
    padding: 1rem 1rem;
  }
`;
