import React, { useState, useEffect } from 'react';
import { TableBody, TableHead, TableRow } from '@material-ui/core';

import { TableWrapper, Loader } from '../../../../../shared/Components';
import { useStyles, TableCell } from '../styles';

import { IScoutAccount } from '../../../../../models/user';

interface IProps extends IScoutAccount {
  loading: boolean;
  payment: IScoutAccount;
}

const TableComponent: React.FC<{}> = () => {
  const [payment, setPayment] = useState<IProps>({} as IProps);
  const [loading, setLoading] = useState<boolean>(true);

  const classes = useStyles();

  useEffect(() => {}, []);

  return (
    <>
      <TableWrapper>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Subscription</TableCell>
            <TableCell>Payment</TableCell>
            <TableCell>Order Number</TableCell>
          </TableRow>
        </TableHead>

        {loading ? (
          <Loader />
        ) : (
          <TableBody className={classes.row}>
            <TableCell>{payment.date_paid}</TableCell>
            <TableCell>{payment.amount}</TableCell>
            <TableCell>{payment.subscription}</TableCell>
            <TableCell>{payment.payment_details}</TableCell>
            <TableCell>{payment.order_id}</TableCell>
          </TableBody>
        )}
      </TableWrapper>
    </>
  );
};

export default TableComponent;
