import React from 'react';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import { TextInput } from '../Input';
import ButtonComponent from '../../Button';
import LinkComponent from '../../Link';

const genders = [
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
];

const FormComponent: React.FC<{}> = (props) => {
  const handleSubmit = () => {
    console.log('I was clicked');
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput
            id="first-name"
            type="text"
            placeholder="First Name"
            fullWidth
            size="medium"
            icon="account_circle"
          />
          <TextInput
            id="telephone-number"
            type="text"
            placeholder="Telephone Number"
            fullWidth
            size="medium"
            icon="call"
          />
          <TextInput
            id="password"
            type="password"
            placeholder="Create Password"
            fullWidth
            size="medium"
            icon="lock"
          />
          <TextInput
            id="gender"
            placeholder="Confirm Password"
            fullWidth
            size="medium"
            select
            SelectProps={{
              native: true,
            }}
          >
            {genders.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextInput>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="last-name"
            type="text"
            placeholder="Last Name"
            fullWidth
            size="medium"
            icon="account_circle"
          />
          <TextInput
            id="email"
            type="email"
            placeholder="Email (optional)"
            fullWidth
            size="medium"
            icon="mail"
          />
          <TextInput
            id="confirm-password"
            type="password"
            placeholder="Confirm Password"
            fullWidth
            size="medium"
            icon="lock"
          />
        </Grid>
      </Grid>
      <Typography style={{ fontSize: 16 }} variant="body1">
        Accept{' '}
        <LinkComponent href="#">Terms of Use & Privacy Policy</LinkComponent>
      </Typography>
      <ButtonComponent
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        Sign Up
      </ButtonComponent>
      <Typography style={{ fontSize: 16 }} variant="body1">
        Already have an account?{' '}
        {<LinkComponent href="#">Sign In Here</LinkComponent>}
      </Typography>
    </>
  );
};

export default FormComponent;
