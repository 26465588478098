import React from 'react';
import ReactPlayer from 'react-player/lazy';

interface IVideoProps {
  videoTitle?: string;
  videoSource: string;
  videoPoster?: string;
}

const VideoComponent: React.FC<IVideoProps> = (props: IVideoProps) => {
  const { videoSource } = props;
  return (
    <>
      <ReactPlayer
        url={videoSource}
        muted={false}
        width="100%"
        height="100%"
        playing={false}
        controls={true}
      />
    </>
  );
};

export default VideoComponent;
