import { makeStyles } from '@material-ui/core';
import { Box, Hidden } from '@material-ui/core/';
import InputBase from '@material-ui/core/InputBase';
import { createStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      position: 'relative',
      border: '0.5px solid #E5E5E5',
      borderRadius: 5,
      boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.15)',
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      color: '#828282',
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1.5, 0, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '16ch',
        },
      },
    },
  })
);

const SearchButton: React.FC<{}> = () => {
  const classes = useStyles();

  return (
    <>
      <Hidden smDown>
        <Box className={classes.search}>
          <Box className={classes.searchIcon}>
            <SearchIcon size={21} />
          </Box>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </Box>
      </Hidden>
    </>
  );
};

export default SearchButton;
