import { IAlert } from '../../../models/alert';

import {
  ALERT_CLEAR,
  ALERT_FAILURE,
  ALERT_SUCCESS,
  AlertActionTypes,
  AlertState,
} from '../types/alert';

const defaultState: IAlert = {
  alertType: undefined,
  alertMessage: '',
};

const alert = (
  state = defaultState,
  action: AlertActionTypes
): AlertState | {} => {
  switch (action.type) {
    case ALERT_SUCCESS:
      return { ...action.payload };
    case ALERT_FAILURE:
      return { ...action.payload };
    case ALERT_CLEAR:
      return {};
    default:
      return state;
  }
};

export default alert;
