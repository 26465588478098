import { colors, fontFamily, fontWeight } from '../../../../theme/theme';
import { withStyles, Table } from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import styled from 'styled-components';

export const containerStyles: React.CSSProperties = {
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.07)',
  borderRadius: 5,
};

export const chipStyles: React.CSSProperties = {
  backgroundColor: 'rgba(252,48,59,0.2)',
  borderRadius: 5,
  color: colors.secondary,
};

export const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '5px 0',
    height: '35px',
    fontSize: 15,
    fontWeight: fontWeight.normal,
    verticalAlign: 'initial',
  },
})(MuiTableCell);

export const titleStyles: React.CSSProperties = {
  flexGrow: 1,
};

export const CustomTable = styled(Table)`
  width: 60%;
`;

export const gridStyles: React.CSSProperties = {
  borderRight: '1px solid #F3F4F8',
  paddingLeft: 20,
  paddingBottom: 20,
};
