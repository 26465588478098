import React from 'react';

import Box from '@material-ui/core/Box';

import CreateAthleteForm from './CreateAthleteForm';
import AthleteProfileForm from './AthleteProfileForm';

const AddAthleteComponent: React.FC<{}> = () => {
  return (
    <Box>
      <CreateAthleteForm />
    </Box>
  );
};

export default AddAthleteComponent;
