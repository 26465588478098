import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import Snackbar from '@material-ui/core/Snackbar';

import { useHistory } from 'react-router-dom';

import { TextInput } from '../../../../shared/Components/Form/Input';
import { ButtonComponent, AlertComponent } from '../../../../shared/Components';
import { IAthleteAccount } from '../../../../models/user';
import { getHttpErrorMessage } from '../../../../shared/errors/http';

import {
  User as UserIcon,
  Phone as PhoneIcon,
  Mail as MailIcon,
} from 'react-feather';
import { colors, fontWeight } from '../../../../theme/theme';
import AuthService from '../../../../shared/services/auth.service';
import UserService from '../../../../shared/services/user.service';
import { validationStyling } from './styles';

const genders = [
  {
    value: '',
    label: 'Select a gender',
  },
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
];

const roles = [
  {
    value: '',
    label: 'Select Role',
  },
  {
    value: 1,
    label: 'Admin',
  },
  {
    value: 2,
    label: 'Scout',
  },
  {
    value: 3,
    label: 'Athlete',
  },
];

const formBoxStyled: React.CSSProperties = {
  backgroundColor: colors.backgroundOne,
  paddingLeft: 110,
  paddingTop: 13,
  paddingBottom: 75,
  paddingRight: 110,
  marginBottom: 30,
};

const titleStyles: React.CSSProperties = {
  fontSize: 24,
  fontWeight: fontWeight.normal,
  textAlign: 'center',
  marginBottom: 30,
};

const dividerStyles: React.CSSProperties = {
  borderTop: '1px solid #F2F2F2',
  marginTop: 30,
  marginBottom: 30,
};

const fieldSetTextStyles: React.CSSProperties = {
  fontSize: 16,
  fontWeight: 500,
  marginBottom: 10,
};

const FieldSet = (props: any) => (
  <Grid container style={{ display: 'flex', alignItems: 'center' }}>
    <Grid item xs={3}>
      <Typography style={fieldSetTextStyles}>{props.title}</Typography>
    </Grid>
    <Grid item xs={9}>
      <Divider />
    </Grid>
  </Grid>
);

const CreateAthleteForm: React.FC<{}> = (props) => {
  const history = useHistory();
  const { register, handleSubmit, watch, errors } = useForm<
    Partial<IAthleteAccount>
  >();
  const [httpError, setHttpError] = useState<AxiosError | null>(null);
  const [errorAlert, showErrorAlert] = useState<boolean>(false);
  const [successAlert, showSuccessAlert] = useState<boolean>(false);
  const [form, setFormState] = useState<Partial<IAthleteAccount>>({
    first_name: '',
    last_name: '',
    telephone: '',
    email: '',
    gender: '',
    password: '',
    role_id: 0,
  });
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const onSubmit = async () => {
    console.log(form);
    await UserService.registerAthlete(form)
      .then((response) => {
        console.log(response.data);
        showSuccessAlert(true);
        setOpenSnackbar(true);
        setTimeout(function () {
          history.push('/dashboard/my-athletes');
        }, 2000);
      })
      .catch((error: AxiosError) => {
        // const resMessage =
        //   (error.response &&
        //     error.response.data &&
        //     error.response.data.message) ||
        //   error.message ||
        //   error.toString();
        // console.error(resMessage);
        // getHttpErrorMessage(error);
        setHttpError(getHttpErrorMessage(error));
      });
  };
  return (
    <Box style={formBoxStyled}>
      {httpError && (
        <AlertComponent severity="error">
          Network error: {httpError}
        </AlertComponent>
      )}
      {successAlert && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleAlertClose}
        >
          <AlertComponent onClose={handleAlertClose} severity="success">
            Athlete has been registered successfully. Redirecting now!
          </AlertComponent>
        </Snackbar>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography style={titleStyles}>
              Create athlete's account
            </Typography>
            <Divider style={dividerStyles} />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              id="firstName"
              type="text"
              placeholder="First Name"
              fullWidth
              size="medium"
              icon={<UserIcon color={colors.icon} />}
              label="First Name"
              name="first_name"
              onChange={onChange}
              value={form.first_name}
              inputRef={register({ required: true })}
            />
            {errors.first_name && (
              <Typography variant="body1" style={validationStyling}>
                This field is required
              </Typography>
            )}
            <TextInput
              id="telephoneNo"
              type="text"
              placeholder="Telephone Number"
              fullWidth
              size="medium"
              icon={<PhoneIcon color={colors.icon} />}
              label="Telephone Number"
              name="telephone"
              onChange={onChange}
              value={form.telephone}
              inputRef={register({ required: true })}
            />
            {errors.telephone && (
              <Typography variant="body1" style={validationStyling}>
                This field is required
              </Typography>
            )}
            <TextInput
              id="gender"
              placeholder="Gender"
              fullWidth
              size="medium"
              select
              label="Gender"
              name="gender"
              onChange={onChange}
              value={form.gender}
              SelectProps={{
                native: true,
              }}
            >
              {genders.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextInput>
            <Input
              id="password"
              type="hidden"
              placeholder="Password"
              fullWidth
              name="password"
              onChange={onChange}
              value={(form.password = '1234567891')}
              disableUnderline
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              id="lastName"
              type="text"
              placeholder="Last Name"
              fullWidth
              onChange={onChange}
              size="medium"
              icon={<UserIcon color={colors.icon} />}
              name="last_name"
              label="Last Name"
              value={form.last_name}
              inputRef={register({ required: true })}
            />
            {errors.last_name && (
              <Typography variant="body1" style={validationStyling}>
                This field is required
              </Typography>
            )}
            <TextInput
              id="email"
              type="text"
              placeholder="Email(optional)"
              fullWidth
              size="medium"
              onChange={onChange}
              icon={<MailIcon color={colors.icon} />}
              name="email"
              label="Email"
              value={form.email}
              inputRef={register({ required: true })}
            />
            {errors.email && (
              <Typography variant="body1" style={validationStyling}>
                This field is required
              </Typography>
            )}

            <TextInput
              id="role"
              placeholder="Role"
              fullWidth
              size="medium"
              select
              label="Role"
              name="role_id"
              onChange={onChange}
              value={form.role_id}
              SelectProps={{
                native: true,
              }}
              inputRef={register({ required: true })}
            >
              {roles.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextInput>
            {errors.role_id && (
              <Typography variant="body1" style={validationStyling}>
                This field is required
              </Typography>
            )}
          </Grid>

          <FieldSet title="Personal Info" />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextInput
                id="country"
                type="text"
                placeholder="Country"
                fullWidth
                size="medium"
                name="country"
                onChange={onChange}
                value={form.country}
                inputRef={register({ required: true })}
              />
              {errors.country && (
                <Typography variant="body1" style={validationStyling}>
                  This field is required
                </Typography>
              )}
              <TextInput
                id="date"
                label=""
                type="date"
                placeholder="Date of Birth (should match birth certificate)"
                defaultValue="2017-05-24"
                value={form.date_of_birth}
                onChange={onChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                size="medium"
                name="date_of_birth"
                inputRef={register({ required: true })}
              />
              {errors.date_of_birth && (
                <Typography variant="body1" style={validationStyling}>
                  This field is required
                </Typography>
              )}
              <TextInput
                id="weight"
                type="text"
                placeholder="Weight"
                fullWidth
                size="medium"
                name="weight"
                onChange={onChange}
                value={form.weight}
                inputRef={register({ required: true })}
              />
              {errors.weight && (
                <Typography variant="body1" style={validationStyling}>
                  This field is required
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextInput
                id="city"
                type="text"
                placeholder="City/ Neighborhood"
                fullWidth
                size="medium"
                name="city"
                onChange={onChange}
                value={form.city}
                inputRef={register({ required: true })}
              />
              {errors.city && (
                <Typography variant="body1" style={validationStyling}>
                  This field is required
                </Typography>
              )}
              <TextInput
                id="height"
                type="text"
                placeholder="Height"
                fullWidth
                size="medium"
                name="height"
                onChange={onChange}
                value={form.height}
                inputRef={register({ required: true })}
              />
              {errors.height && (
                <Typography variant="body1" style={validationStyling}>
                  This field is required
                </Typography>
              )}
              <TextInput
                id="wingspan"
                type="text"
                placeholder="Wingspan"
                fullWidth
                size="medium"
                name="wing_span"
                onChange={onChange}
                value={form.wing_span}
              />
            </Grid>
          </Grid>

          <FieldSet title="Professional Info" />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextInput
                id="sport"
                type="text"
                placeholder="Sport"
                fullWidth
                size="medium"
                name="sport"
                onChange={onChange}
                value={form.sport}
              />
              <TextInput
                id="school"
                type="text"
                placeholder="School Attending"
                fullWidth
                size="medium"
                name="attending_school"
                onChange={onChange}
                value={form.attending_school}
              />
              <TextInput
                id="guardian"
                type="text"
                placeholder="Adult's Name (Parent/Guardian)"
                fullWidth
                size="medium"
                name="adult_name"
                onChange={onChange}
                value={form.adult_name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                id="position-played"
                type="text"
                placeholder="Position Played"
                fullWidth
                size="medium"
                name="position_played"
                onChange={onChange}
                value={form.position_played}
              />
              <TextInput
                id="club-team"
                type="text"
                placeholder="Club/Team"
                fullWidth
                size="medium"
                name="club_team"
                onChange={onChange}
                value={form.club_team}
              />
              <TextInput
                id="adult-contacts"
                type="text"
                placeholder="Adult contacts (Phone Number)"
                fullWidth
                size="medium"
                name="adult_phone_number"
                onChange={onChange}
                value={form.adult_phone_number}
              />
            </Grid>
          </Grid>

          {/* <FieldSet title="Upload the following Info" />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                id="age-verification-document"
                type="file"
                placeholder="Choose a file"
                fullWidth
                size="medium"
                InputLabelProps={{ shrink: true }}
                label="Age Verification Document (Birth Certificate, age verification form, birth notification, baptism card)"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="position-played"
                type="file"
                placeholder="Choose a file"
                fullWidth
                size="medium"
                label="Passport Photo (Headshot)"
              />
            </Grid>
          </Grid> */}

          <Grid item>
            <ButtonComponent
              variant="contained"
              type="submit"
              style={{
                marginTop: 20,
                marginBottom: 20,
                background: colors.primary,
              }}
            >
              Create Athlete Account
            </ButtonComponent>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CreateAthleteForm;
