import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import {
  AllAthletesAvatar,
  CreditCardAvatar,
  DashboardHeader,
  HeroContent,
  MessageAvatar,
  ProfileAvatar
} from '../../../../../shared/Components';
import { StyledCard, StyledGrid } from '../styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helper: {
      borderLeft: `1px solid #828282`,
      padding: theme.spacing(0, 2),
    },
  })
);

const title: string = `Your Account Summary`;

const athletesTitle: string = `All Athletes`;
const athletesCount = 318;

const paymentTitle: string = `Next Payment`;
const paymentDate = 'Sep. 23, 2020';

const messageTitle = `New Messages`;
const messageCount = 29;

const profileTitle = `Updated Profiles`;
const profileCount = 6;

const AccountSummary: React.FC<{}> = () => {
  const classes = useStyles();
  return (
    <Box>
      <DashboardHeader>{title}</DashboardHeader>
      <StyledCard>
        <Grid container>
          <StyledGrid item xs={12} sm={3}>
            <HeroContent
              title={athletesTitle}
              count={athletesCount}
              icon={AllAthletesAvatar}
            />
          </StyledGrid>

          <StyledGrid item xs={12} sm={4}>
            <HeroContent
              title={paymentTitle}
              date={paymentDate}
              icon={CreditCardAvatar}
            />
          </StyledGrid>
          <StyledGrid item xs={12} sm={3}>
            <HeroContent
              title={messageTitle}
              count={messageCount}
              icon={MessageAvatar}
            />
          </StyledGrid>
          <StyledGrid item xs={12} sm={2}>
            <HeroContent
              title={profileTitle}
              count={profileCount}
              icon={ProfileAvatar}
            />
          </StyledGrid>
        </Grid>
      </StyledCard>
    </Box>
  );
};

export default AccountSummary;
